import React, { useEffect, useState } from "react";
// import DashboardTable from "./dashboard/DashboardTable";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import AddClient from "../../../generalComponents/client-management/AddClient";
import { download, plusCircle } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import useGetAllClientsManager from "../controllers/getClientsController";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import ViewClient from "../../../generalComponents/client-management/ViewClient"; 
import ExportToCSV from "../../../utils/downloadInCSV";
import DeactivationModal from "../../../generalComponents/client-management/DeactiveClient";
import { DeactivateClientManager } from "../controllers/deactivateController";
import StatusButton from "../../../generalComponents/StatusButton";

const ClientManagement = () => {
  const [showAddClient, setShowAddClient] = useState(false); 
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedClient, setSelectedClient] = useState("");
  const navigate = useNavigate();
  const handleAddClient = () => {
    navigate("");
    setShowAddClient(true);
  };
 const handleShowClientDetails = () => {
    setShowClientDetails(!showClientDetails)
 }
 const handleShowDeactivate = () => {
    setShowDeactivate(!showDeactivate)
 }
  const handleCloseAddClient = () => {
    setShowAddClient(false);
  };

  const { data, isLoading,refetch } = useGetAllClientsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  const {deactivateClient, isLoading:deactivating, isSuccess:deactivated} = DeactivateClientManager({id: selectedClient})

  useEffect(() => {
    if(deactivated){
      refetch()
      handleShowDeactivate()
    }
  }, [deactivated])
  const headers = ["Client Name", "Email",   "Date", "Status","Action"];

  useEffect(() => { 
  }, [selectedClient]);

  const options = [
    "View Client", 
    "Edit Client",
    "Deactivate Client",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <div key={index} className="flex items-center gap-5">
      { el?.photo ? <img src={el?.photo} alt="" className="w-10 h-10 rounded-full" /> : <InitialsAvatar name={el?.name} />}  <p>{el?.name}</p>
      </div>,

      el?.email, 
      formatDate(el?.created_datetime), 
      <StatusButton key={index} status={el?.status} /> ,
    ];
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex items-center justify-between mb-6">
          <h1 className="font-[600] text-[24px] mb-2 w-full">
            Client Management
          </h1>

          <div className="flex items-center gap-[5px]">
            <TransparentBtn text={"Export"} onClick={() => ExportToCSV(data?.data?.results, "clients")} icon={download} />
            <GreenBtn
              onClick={() => navigate(`/client/add`)}
              icon={plusCircle}
              text={"Add Client"}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-start pt-3 px-4">
            <DropdownandSearch
              options={["Option1", "Option"]}
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => { 
                setSelectedClient(val.id)
                if(index === 0){
                  handleShowClientDetails()
                }
                if (index === 1) {  
                  navigate(`/client/edit/${val.id}`);
                }
                if(index === 2){
                  handleShowDeactivate()
                }
              }}
              // Close popup function
            />
          </div>

          {/* Pagination */}
          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* <ClientTable /> */}
        </div>
      </>
       
      {(showClientDetails && selectedClient) && (
        <ViewClient
        onClose={handleShowClientDetails}
        clientId={selectedClient}
      />
      )}
      {showDeactivate && (
        <DeactivationModal
        onClose={handleShowDeactivate} 
        onClick={() => 
          deactivateClient({
          status: "inactive"
          })
        }
        isLoading={deactivating}
        title={"Deactive Client"}
        body={"Are you sure you want to proceed with this action? "}
        actionText={"Deactivate"}
      />
      )}
    </div>
  );
};

export default ClientManagement;
