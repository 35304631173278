// import React from "react";

const TabsManagement = ({ setCurrentView, currentView, options }) => {
  return (
    <div className="flex items-center border-b border-[#E5E7EB] overflow-x-auto mb-6 scrollbar-hide ">
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => setCurrentView(index)}
          className={`cursor-pointer pb-4 font-[600] leading-[14px] text-[12px] pt-3 px-3 relative whitespace-nowrap ${
            currentView === index
              ? "text-[#00AD51]"
              : "text-[#828282] hover:text-gray-700"
          }`}
        >
          {option}
          {currentView === index && (
            <div className="absolute bottom-0 left-0 h-[1px] w-[100%] bg-[#00AD51] mx-auto" />
          )}
        </div>
      ))}
    </div>
  );
};

export default TabsManagement;
