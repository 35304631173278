
export const headers = [
    "Vessel",
    "Vessel Agent",
    "Product",
    "Depature Quantity/MT",
    "ROB/MT",
    "Client",
    "ETA",
    "Laycan Date",
    "Status",
    "Action",
  ];
  export const productTab = [
    "Product",
    "Vessel",
    "Vessel Agent",
    "Vessel Size",
    "Client",
    "Client Contract",
    "Port"
  ]
  export const portTab = [
    "Departing port",
    "Destination port"
  ]
  export const productHeaders = [
    "NAME",
    "DENSITY",
    "DESCRIPTION",
    "CONSUMPTION PER DAY",
    "More", 
  ];
  export const vesselHeaders = [
    "NAME",
    "TYPE",
    "IMO NUMBER",
    "DEAD WEIGHT TONNAGE",
    "VESSEL SIZE",
    "STATUS",  
    "More", 
  ];
  export const vesselAgentHeaders = [
    "NAME",  
    "More", 
  ];
  export const vesselSizeHeaders = [
    "NAME",  
    "QUANTITY",
    "DM GRACE PERIOD",
    "More", 
  ];
  export const clientHeaders = [
    "NAME",  
    "More", 
  ];
  export const clientContractHeaders = [
    "NAME",  
    "DESCRIPTION",  
    "More", 
  ];
  export const portHeaders = [
    "NAME",  
    "LOCATION",
    "COUNTRY",  
    "More", 
  ];

  export const productOptions = [
    "See Details", 
    "Edit ",
    "Deactivate ",
  ];
  export const liftingData= {
    laycanStartDate: "2023-04-01",
    laycanEndDate: "2023-04-15",
    product: "Crude Oil",
    client: "ABC Inc.",
    clientContract: "Contract #123",
    cargoOwner: "XYZ Corp.",
    exportVessel: "Vessel A",
    vesselAgent: "Agent X",
    offLoadingPort: "Port A",
    departureVesselDraft: "12.5 MT",
    remarks: "Lorem ipsum dolor sit amet",
    blQuantityMT: "10,000 MT",
    blQuantityLtrs: "12,000,000 Ltrs",
    expectedTimeDeparture: "2023-04-15 12:00 PM",
    laytime: "72 hours",
    actualTimeDeparture: "2023-04-16 10:00 AM",
  }

  export const productData = {
    name: "Prodname",
    density: "300",
    consumption_per_day: "30",
    quality_name: "sometuing",
    quality_value: "30%",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam reprehenderit porro tempore esse ab. Cum eaque laboriosam, modi voluptate rerum velit quo voluptas aperiam vel dignissimos. Placeat minima rem in." 

  }
  export const vesselData = {
    name: "Vesselname",
    type: "Somen",
    imo_number: "3JFD-43D3EW-2W32",
    deadweight_tonnage: "40",
    vessel_size: "400",
    status: "inactive"
  }
  export const vesselAgentData = {
    name: "Bene"
  }
  export const vesselSizeData = {
    name: "Bene Size",
    quantity: "400",
    dm_grace_period: "30 days"
  }
  export const clientDetailData = {
    name: "Bene"
  }
  export const clientContractData = {
    name: "Bene Contract", 
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam reprehenderit porro tempore esse ab. Cum eaque laboriosam, modi voluptate rerum velit quo voluptas aperiam vel dignissimos. Placeat minima rem in." 

  }
  
  export const departingPortData = {
    name: "Bene",
    location: "lagos",
    country: "Nigeria"
  }
  
  export const destinationPortData = {
    name: "Bene",
    location: "wifef",
    country: "Sounth Africa"
  }