import React, { useState } from "react";
import useGetAllAssetsManager from "../controllers/getAssetGroupsController";
import Loader from "../../../generalComponents/Loader";
import useDebounce from "../../../utils/UseDebounce";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { PlusCircle } from "lucide-react";
import SearchField from "../../../generalComponents/SearchField";
import FilterButton from "../../../generalComponents/FilterButton";
import AssetCard from "../components/AssetCard";
import AddAssetGroup from "../components/AddAssetGroup";
import FilterDrawer from "../components/FilterDrawer";

const AssetGroupsPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const { data, isLoading, refetch } = useGetAllAssetsManager({
    enabled: true,
    page: 1,
    searchQuery: debouncedSearchValue,
    // page: currentPage
  });

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <div className="bg-white rounded-[10px] w-full">
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-[24px] font-[600] leading-[28.8px]">
              Asset Group Management
            </h2>
            <GreenBtn
              onClick={() => setIsModalOpen(true)}
              text="Add Asset Group"
              className="flex items-center rounded-[40px] text-[14px] leading-[20.3px] justify-center gap-[10px] shadow-custom-1 hover:shadow-custom-2"
            >
              <PlusCircle className="w-[20px] h-[20px]" />
              Add Asset Group
            </GreenBtn>
          </div>

          <div className="flex items-center space-x-2 justify-start pt-3 px-4 mb-6">
            <SearchField
              placeholder={"Search here..."}
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
            <FilterButton onClick={() => setIsFilterDrawerOpen(true)} />
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[11px]">
              {data?.data?.results?.map((asset) => (
                <AssetCard key={asset.id} {...asset} />
              ))}
            </div>
          )}
        </div>

        <AddAssetGroup
          isOpen={isModalOpen}
          refetch={refetch}
          onClose={() => setIsModalOpen(false)}
        />

        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          onClose={() => setIsFilterDrawerOpen(false)}
          onUpdateFilters={(newFilter) => console.log(newFilter)}
        />
      </div>
    </div>
  );
};

export default AssetGroupsPage;
