import React, { useState } from "react";
import TabsManagement from "../../../generalComponents/TabsManagement";
import WellProductionSection from "../../wells/components/WellProductionSection";
import FieldProductionSection from "../../fields/components/FieldProductionSection";
import FlowStationProductionSection from "../../flowstation/components/FlowStationProductionSection";
import LactPointProductionSection from "../../lactpoint/components/LactPointProductionSection";
import GasProductionSection from "../../companyGas/components/GasProductionSection";
import TerminalProductionSection from "../../terminals/components/TerminalProductionSection";
import FPSOProductionSection from "../../fpso/components/FPSOProductionSection";
import GasPlantSection from "../../gasplant/components/ListOfGasPlants";
import CompanyOilProductionSection from "../../companyOIl/components/CompanyOilProductionSection";
import GasPlantProductionSection from "../../gasplant/components/GasPlantProductionSection";

const Hydrocarbon = () => {
  const [currentView, setCurrentView] = useState(0);
  const tabOptions = [
    { name: "WELL PRODUCTION", component: WellProductionSection },
    { name: "FIELD PRODUCTION", component: FieldProductionSection },
    { name: "FLOWSTATION PRODUCTION", component: FlowStationProductionSection },
    { name: "LACTPOINT PRODUCTION", component: LactPointProductionSection },
    { name: "GASPLANT PRODUCTION", component: GasPlantProductionSection },
    { name: "COMPANY GAS", component: GasProductionSection },
    { name: "COMPANY OIL", component: CompanyOilProductionSection },
    { name: "TERMINAL", component: TerminalProductionSection },
    { name: "FPSO", component: FPSOProductionSection },
  ];

  // Function to handle previous tab navigation
  const handlePreviousTab = () => {
    setCurrentView((prev) => (prev > 0 ? prev - 1 : tabOptions.length - 1));
  };

  // Function to handle next tab navigation
  const handleNextTab = () => {
    setCurrentView((prev) => (prev < tabOptions.length - 1 ? prev + 1 : 0));
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-[90%]">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="font-[600] text-[24px] mb-2">Hydrocarbon Accounting</h1>
        <div className="flex items-center gap-2">
          {/* Previous Navigation arrow */}
          <button
            onClick={handlePreviousTab}
            className="rounded-full border-[0.67px] border-[#111111] p-2 w-[33px] h-[33px]"
          >
            <svg
              width="15"
              height="6"
              viewBox="0 0 15 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.333 2.33464C14.5171 2.33464 14.6663 2.48387 14.6663 2.66797C14.6663 2.85206 14.5171 3.0013 14.333 3.0013L14.333 2.33464ZM0.0973053 2.90367C-0.0328693 2.77349 -0.0328693 2.56244 0.0973053 2.43227L2.21863 0.310945C2.3488 0.18077 2.55986 0.18077 2.69003 0.310945C2.82021 0.44112 2.82021 0.652175 2.69003 0.78235L0.804412 2.66797L2.69003 4.55359C2.82021 4.68376 2.82021 4.89482 2.69003 5.02499C2.55986 5.15517 2.3488 5.15517 2.21863 5.02499L0.0973053 2.90367ZM14.333 3.0013L0.333008 3.0013L0.333008 2.33463L14.333 2.33464L14.333 3.0013Z"
                fill="#111111"
              />
            </svg>
          </button>

          {/* Next Navigation arrow */}
          <button
            onClick={handleNextTab}
            className="rounded-full border-[0.67px] border-[#111111] p-2 w-[33px] h-[33px]"
          >
            <svg
              width="15"
              height="6"
              viewBox="0 0 15 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.333984 2.33464C0.149889 2.33464 0.000651016 2.48387 0.000651032 2.66797C0.000651048 2.85206 0.149889 3.0013 0.333984 3.0013L0.333984 2.33464ZM14.5697 2.90367C14.6999 2.77349 14.6999 2.56244 14.5697 2.43227L12.4484 0.310945C12.3182 0.18077 12.1071 0.18077 11.977 0.310945C11.8468 0.44112 11.8468 0.652175 11.977 0.78235L13.8626 2.66797L11.977 4.55359C11.8468 4.68376 11.8468 4.89482 11.977 5.02499C12.1071 5.15517 12.3182 5.15517 12.4484 5.02499L14.5697 2.90367ZM0.333984 3.0013L14.334 3.0013L14.334 2.33463L0.333984 2.33464L0.333984 3.0013Z"
                fill="#111111"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Tabs Section */}
      <TabsManagement
        options={tabOptions.map((el) => el.name)}
        currentView={currentView}
        setCurrentView={setCurrentView}
      />

      {React.createElement(tabOptions[currentView].component)}
    </div>
  );
};

export default Hydrocarbon;
