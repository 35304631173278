import useUpdateManager from "../../../constants/controller_templates/put_controller_template";

export const UpdateCompanyOilManager = ({ id }) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/company-oil-productions/${id}/`,
    ["update_company-oil-productions"],
    false,
    true
  );
  const updateCompanyOil = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    updateCompanyOil,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
