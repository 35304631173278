import React, { useState } from "react";
import OilField from "../../fields/view/OilField";
import Hydrocarbon from "../../hydroCarbon/view/Hydrocarbon";
import AssetList from "./AssetList";
import useGetSingleClient from "../../clientManagement/controllers/getClientController";
import { useSearchParams } from "react-router-dom";
import Reservoir from "../../reservoir/view/Reservoir";
import WellsPage from "../../wells/views/WellsPage";
import RigsPage from "../../rigs/view/RigsPage";
import StorageTanks from "../../storage/view/StorageTanks";
import GasPlant from "../../gasplant/view/GasPlant";
import Pipeline from "../../pipeline/view/Pipeline";
import PipelineNetwork from "../../pipelineNetwork/view/PipelineNetwork";
import Terminal from "../../terminals/view/Terminal";
import FPSO from "../../fpso/view/FPSO";
import LactPoint from "../../lactpoint/view/LactPoint";
import AGGStation from "../../aggstation/view/AGGStation";
import LoadingBuoy from "../../loadingBouy/view/LoadingBouy";
import Manifold from "../../manifold/view/Manifold";

const OperatorsMain = ({ currentOperator }) => {
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("id");
  const { data, isLoading } = useGetSingleClient({
    enabled: Boolean(clientId),
    id: clientId,
  });

  const TAB_CONFIG = {
    "Company Production": {
      component: Hydrocarbon,
      label: "Company Production",
    },
    "Oil Field": {
      component: OilField,
      label: "Oil Field",
    },
    Reservoir: {
      component: Reservoir,
      label: "Reservoir",
    },
    Wells: {
      component: WellsPage,
      label: "Wells",
    },
    Rigs: {
      component: RigsPage,
      label: "Rigs",
    },
    "Storage Tanks": {
      component: StorageTanks,
      label: "Storage Tanks",
    },
    "Gas Plant": {
      component: GasPlant,
      label: "Gas Plant",
    },
    Pipeline: {
      component: Pipeline,
      label: "Pipeline",
    },
    "Pipeline Network": {
      component: PipelineNetwork,
      label: "Pipeline Network",
    },
    "Terminal/(FSO)": {
      component: Terminal,
      label: "Terminal/(FSO)",
    },
    FPSO: {
      component: FPSO,
      label: "FPSO",
    },
    "Lact Point": {
      component: LactPoint,
      label: "Lact Point",
    },
    "AGG Station": {
      component: AGGStation,
      label: "AGG Station",
    },
    "Loading Bouy": {
      component: LoadingBuoy,
      label: "Loading Bouy",
    },
    Manifold: {
      component: Manifold,
      label: "Manifold",
    },
  };

  const [selected, setSelected] = useState(Object.keys(TAB_CONFIG)[0]);

  const renderSelectedComponent = () => {
    const Component = TAB_CONFIG[selected]?.component;
    return Component ? <Component selectedTab={selected} /> : null;
  };

  return (
    <div className="bg-[#FFFFFF] rounded-[10px] w-full flex p-2">
      <div className="w-[200px]">
        <AssetList
          selected={selected}
          setSelected={setSelected}
          operatorImage={currentOperator?.photo || null}
        />
      </div>
      <div className="flex-1 w-auto">{renderSelectedComponent()}</div>
    </div>
  );
};

// const OilField = <OilField />;

export default OperatorsMain;
