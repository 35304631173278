import TabComponent from "./TabsComponent";
import Exploration from "../../modules/projectManagement/view/Exploration"; 
import WellOperation from "../../modules/projectManagement/view/WellOperation";

// import ProfileItems from "./ProfileItems";

const ProjectsTab = () => {
  //   const Profile = () => <ProfileItems />;
  // const Analytics = <Analytics />;
  const tabData = [
    // { label: "PROFILE", component: Profile },
    { label: "Exploration", component: Exploration },
    { label: "Well Operation", component: WellOperation },
  ];
  return (
    <div>
      <TabComponent
        tabs={tabData}
        className="cursor-pointer pb-4 font-[600] leading-[14px] text-sm md:text-[12px] pt-3 px-3 relative"
      />
    </div>
  );
};

export default ProjectsTab;
