import Analytics from "./AnalyticsDashboard";

import OilTabsComponent from "./OilTabsComponent";
import BlankTab from "./BlankTab";
import ListOfOilFields from "../../fields/components/ListOfOilFields";

// import ProfileItems from "./ProfileItems";

const OilTab = () => {
  //   const Profile = () => <ProfileItems />;
  // const Analytics = <Analytics />;
  const Articles = () => <BlankTab content="Articles" />;
  const tabData = [
    // { label: "PROFILE", component: Profile },
    { label: "Analytics", component: Analytics },
    { label: "Reservoirs Attached", component: ListOfOilFields },
    { label: "Maintenance Activitiesd", component: Articles },
    { label: "Maintenance Schedule", component: Articles },
    { label: "Hydrocarbon Accounting", component: Articles },
  ];
  return (
    <div>
      <OilTabsComponent
        tabs={tabData}
        className="cursor-pointer py-[10px] font-[600] leading-[14px] text-sm md:text-[12px]  px-[20px] relative rounded-[3px]"
      />
    </div>
  );
};

export default OilTab;
