import React from "react";
import oilIcon from "../../../assets/icons/oilIcon.svg";
import OperatorsCard from "./OperatorsCard";
import AnalyticsContainer from "./AnalyticsContainer";

const cardData = [
  {
    title: "Total Number of Oil Fields",
    value: "2,583",
    unit: "",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: oilIcon,
    bgColor: "bg-green-500",
    textColor: "text-white",
    iconBg: "bg-white",
  },
  {
    title: "Total Capacity",
    value: "2,583",
    unit: "bopd",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: oilIcon,
    bgColor: "bg-white",
    textColor: "text-[#667185]",
    iconBg: "bg-[#E5FFF1]",
  },
];
const Analytics = () => {
  return (
    <>
      <div className="flex flex-col gap-8 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {cardData.map((card, index) => (
            <OperatorsCard
              key={index}
              title={card.title}
              value={card.value}
              unit={card.unit}
              comparison={card.comparison}
              comparisonText={card.comparisonText}
              icon={card.icon}
              bgColor={card.bgColor}
              textColor={card.textColor}
              iconBg={card.iconBg}
            />
          ))}
        </div>
        <div>
          <AnalyticsContainer />
        </div>
      </div>
    </>
  );
};

export default Analytics;
