import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const CreateAssetGroupManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/clients/asset-groups/",
    ["asset-groups"],
    true
  );
  const createAssetGroup = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createAssetGroup,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
