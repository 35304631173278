import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import useGetAssetGroupManager from "../controllers/getAssetGroupController";
import TabComponent from "../../operators/component/TabsComponent";
import Production from "./Production";
import Facilities from "./Facilities";
import Project from "./Project";
import ListOfOperators from "./ListOfOperators";
import AddAssetGroup from "./AddAssetGroup";

const AssetsDetail = () => {
  const { id } = useParams();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const assetData = location.state.asset;
  const { data, refetch } = useGetAssetGroupManager({
    id,
    enabled: Boolean(id),
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  console.log(data);

  const tabData = [
    { label: "PRODUCTION", component: Production },
    { label: "Facilities", component: Facilities },
    { label: "Project", component: Project },
    {
      label: "List of Operators",
      component: () => <ListOfOperators id={id} />,
    },
  ];

  return (
    <div className="bg-[#FFFFFF] rounded-[10px] w-full  p-6">
      <div className="flex items-center mb-6">
        <button
          onClick={handleGoBack}
          className="inline-flex items-center text-[#344054] bg-[#FFFFFF] rounded-[4px] px-2 py-2 mr-4 border border-[#E4E7EC]"
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.146446 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146446 2.64645L2.14645 0.646447C2.34171 0.451184 2.65829 0.451184 2.85355 0.646447C3.04882 0.841709 3.04882 1.15829 2.85355 1.35355L1.70711 2.5L9.5 2.5C9.77614 2.5 10 2.72386 10 3C10 3.27614 9.77614 3.5 9.5 3.5L1.70711 3.5L2.85355 4.64645C3.04882 4.84171 3.04882 5.15829 2.85355 5.35355C2.65829 5.54882 2.34171 5.54882 2.14645 5.35355L0.146446 3.35355Z"
              fill="black"
            />
          </svg>
        </button>
        <p className="text-[12px]">Go Back</p>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-[24px] font-[600]">{assetData.name}</div>
        <TransparentBtn
          text={"Edit Group"}
          onClick={() => setIsModalOpen(true)}
          className="flex items-center gap-2 text-[14px] "
        ></TransparentBtn>
      </div>
      <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] mt-4 shadow-custom-3">
        <div className="flex items-center justify-end pt-3 px-4"></div>
        <TabComponent
          tabs={tabData}
          className="cursor-pointer pb-4 font-[600] leading-[14px] text-sm md:text-[12px] pt-1 px-3 relative"
        />
      </div>
      <AddAssetGroup
      isEdit={true}
      details={data?.data}
      assetId={data?.data?.id}
          isOpen={isModalOpen}
          refetch={refetch}
          onClose={() => setIsModalOpen(false)}
        />
    </div>
  );
};

export default AssetsDetail;
