import React from "react";
const Dropdown = ({
  options = [],
  onChange,
  onBlur, // Add this
  name, // Add this
  title,
  value = "",
  error, // Change formik to error
  errorMessage, // Change formikFeedback to errorMessage
  placeholder,
  className,
}) => {
  return (
    <div className="">
      <h3 className="block text-[#181918] font-[500] leading-[18px] text-[12px]">
        {title}
      </h3>
      <div
        className={`${className} w-full p-2 border rounded-[5px] text-[12px] text-blackColor font=[400] bg-[#F6F8FC]`}
      >
        <select
          name={name} // Add this
          onChange={onChange}
          onBlur={onBlur} // Add this
          value={value}
          className="w-full text-left text-[12px] text-black h-[22.91px] flex justify-between items-center bg-transparent outline-none"
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {error ? (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      ) : null}
    </div>
  );
};

export default Dropdown;
