import axios from "axios";

// const baseURL = "https://emms-v2-0cd54c2c1466.herokuapp.com";
const baseURL = process.env.REACT_APP_BASE_URL;

const AxiosWithToken = axios.create({
  baseURL: baseURL,
  
});

// Request interceptor to attach the token
AxiosWithToken.interceptors.request.use(
  (config) => {
    // Retrieve the token dynamically
    const token = localStorage.getItem("token");
    console.log("Token in Request:", `Bearer ${token}`);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration or other errors
AxiosWithToken.interceptors.response.use(
  (response) => {
    // Handle successful responses here
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login on unauthorized response
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default AxiosWithToken;
