import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateCompanyGasProductionManager } from "../controllers/createCompanyGasProduction";
import { UpdateCompanyGasManager } from "../controllers/updatecompanyGasController";
import useGetSingleCompanyGasManager from "../controllers/getCompanyGasController";
import useGetAllClientsManager  from "../../clientManagement/controllers/getClientsController"
import { FormLabel } from "@mui/material";
import { FormSelect } from "../../omls/components/FormComponents";
const validationSchema = Yup.object({
  company: Yup.string().required("Company is required"),
  date: Yup.date().required("Date is required"),
  flarred_gas: Yup.string().required("Flared Gas is required"),
  fuel_gas: Yup.string().required("Fuel Gas is required"),
  lng: Yup.string().required("LNG is required"),
  ag: Yup.string().required("AG is required"),
  egtl: Yup.string().required("EGTL is required"),
  nlg: Yup.string().required("NLG is required"),
  dom_sales: Yup.string().required("Domestic Sales is required"),
  nag: Yup.string().required("NAG is required"),
  gas_reinjection: Yup.string().required("Gas Reinjection is required"),
});

const AddCompanyGasProduction = ({ isOpen, onClose, id }) => {
  const { createCompanyGasProduction, isLoading } =
    CreateCompanyGasProductionManager();

    const {data} = useGetSingleCompanyGasManager({id: id, enabled: Boolean(id)}) 
    
const {updateCompanyGas, isLoading:updating, isSuccess} = UpdateCompanyGasManager({
  id: id
})
  const formik = useFormik({
    initialValues: {
      company: "",
      date: "",
      flarred_gas: "",
      fuel_gas: "",
      lng: "",
      ag: "",
      egtl: "",
      nlg: "",
      dom_sales: "",
      nag: "",
      gas_reinjection: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if(id){
          await updateCompanyGas(values);
        }else{
          await createCompanyGasProduction(values);
        }
        
        onClose(); // Close modal after successful creation
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (id && data?.data) {
      
      
      // Set form values
      formik.setValues({
         
        company: data?.data?.company?.id || "",
        date: data?.data?.date || "",
        flarred_gas: data?.data?.flarred_gas || "",
        fuel_gas: data?.data?.fuel_gas || "",
        lng: data?.data?.lng || "",
        ag: data?.data?.ag || "",
        egtl: data?.data?.egtl || "",
        nlg: data?.data?.nlg || "",
        dom_sales: data?.data?.dom_sales || "",
        nag: data?.data?.nag || "",
        gas_reinjection: data?.data?.gas_reinjection || "",
      });
    }
  }, [id, data]);
  const { data: clientsData, isLoading: isClientsLoading } =
  useGetAllClientsManager({
    page: 1,
    searchQuery: "",
    enabled: true,
  });

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title={`${id ? "Edit Company Gas Production": "Create Company Gas Production"}`}
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          
            <div className="space-y-1 ">
          <FormLabel>Company</FormLabel>
          <FormSelect
            name="company"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company}
            options={[
              { value: "", label: "Select Company" },
              ...(clientsData?.data?.results?.map((client) => ({
                value: client.id,
                label: client.name,
              })) || []),
            ]}
            disabled={isClientsLoading}
          />
          {formik.touched.client && formik.errors.company && (
            <div className="text-red-500 text-sm">{formik.errors.company}</div>
          )}
        </div>

            <InputWithFullBoarder
              label="Date"
              id="date"
              name="date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && formik.errors.date}
              errorMessage={formik.errors.date}
            />

            <InputWithFullBoarder
              label="Flared Gas"
              id="flarred_gas"
              name="flarred_gas"
              placeholder="Enter Flared Gas"
              value={formik.values.flarred_gas}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.flarred_gas && formik.errors.flarred_gas}
              errorMessage={formik.errors.flarred_gas}
            />

            <InputWithFullBoarder
              label="Fuel Gas"
              id="fuel_gas"
              name="fuel_gas"
              placeholder="Enter Fuel Gas"
              value={formik.values.fuel_gas}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fuel_gas && formik.errors.fuel_gas}
              errorMessage={formik.errors.fuel_gas}
            />

            <InputWithFullBoarder
              label="LNG"
              id="lng"
              name="lng"
              placeholder="Enter LNG"
              value={formik.values.lng}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lng && formik.errors.lng}
              errorMessage={formik.errors.lng}
            />

            <InputWithFullBoarder
              label="AG"
              id="ag"
              name="ag"
              placeholder="Enter AG"
              value={formik.values.ag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ag && formik.errors.ag}
              errorMessage={formik.errors.ag}
            />

            <InputWithFullBoarder
              label="EGTL"
              id="egtl"
              name="egtl"
              placeholder="Enter EGTL"
              value={formik.values.egtl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.egtl && formik.errors.egtl}
              errorMessage={formik.errors.egtl}
            />

            <InputWithFullBoarder
              label="NLG"
              id="nlg"
              name="nlg"
              placeholder="Enter NLG"
              value={formik.values.nlg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nlg && formik.errors.nlg}
              errorMessage={formik.errors.nlg}
            />

            <InputWithFullBoarder
              label="Domestic Sales"
              id="dom_sales"
              name="dom_sales"
              placeholder="Enter Domestic Sales"
              value={formik.values.dom_sales}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.dom_sales && formik.errors.dom_sales}
              errorMessage={formik.errors.dom_sales}
            />

            <InputWithFullBoarder
              label="NAG"
              id="nag"
              name="nag"
              placeholder="Enter NAG"
              value={formik.values.nag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nag && formik.errors.nag}
              errorMessage={formik.errors.nag}
            />

            <InputWithFullBoarder
              label="Gas Reinjection"
              id="gas_reinjection"
              name="gas_reinjection"
              placeholder="Enter Gas Reinjection"
              value={formik.values.gas_reinjection}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.gas_reinjection && formik.errors.gas_reinjection
              }
              errorMessage={formik.errors.gas_reinjection}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading || updating}
            buttonText={`${id ? "Edit Company Gas Production": "Create Company Gas Production"}`}
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddCompanyGasProduction;
