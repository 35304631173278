import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Icon1 } from "../assets/icons/icon1.svg";
import { ReactComponent as Icon2 } from "../assets/icons/icon2.svg";
import { ReactComponent as Icon3 } from "../assets/icons/icon3.svg";
import { ReactComponent as Icon4 } from "../assets/icons/icon4.svg";
import { ReactComponent as Icon5 } from "../assets/icons/icon5.svg";
import { ReactComponent as Icon6 } from "../assets/icons/icon6.svg";
import { ReactComponent as Icon11 } from "../assets/icons/icon11.svg";
import { ReactComponent as Icon14 } from "../assets/icons/icon14.svg";
import { ReactComponent as Icon15 } from "../assets/icons/icon15.svg";
import { useSidebar } from "../layout/SidebarContext";

const Sidebar = () => {
  const { isExpanded } = useSidebar();

  const navItems = [
    { path: "/", label: "Dashboard", icon: Icon1 },
    { path: "/staff", label: "Staff Management", icon: Icon2 },
    { path: "/client", label: "Client Management", icon: Icon3 },
    { path: "/operators", label: "Operators", icon: Icon4 },
    { path: "/omls", label: "OMLs", icon: Icon5 },
    {
      path: "/assets_groups",
      label: "Asset Groups",
      icon: Icon6,
    },
    // { path: "/some", label: "Hydrocarbon Accounting", icon: Icon7 },
    // { path: "/hydrocarbon-accounting", label: "Planning", icon: Icon8 },
    // { path: "/maintenance", label: "Maintenance", icon: Icon9 },
    // { path: "/reporting", label: "Reporting Management", icon: Icon10 },
    { path: "/project", label: "Project Management", icon: Icon11 },
    // { path: "/exploration", label: "Exploration Management", icon: Icon12 },
    // { path: "/reservoir", label: "Reservoir Tracking", icon: Icon13 },
    { path: "/lifting", label: "Lifting Management", icon: Icon14 },
    { path: "/support", label: "Support", icon: Icon15 },
  ];

  return (
    <div
      className={`transition-all duration-300 my-2 rounded-[10px] h-[calc(100vh-5rem)] px-2 bg-white flex flex-col items-center py-2 ml-3 ${
        isExpanded ? "w-64" : "max-w-[50px]"
      }`}
    >
      <nav
        className={`flex flex-col items-center w-full ${
          isExpanded ? "gap-1" : "gap-2"
        }`}
      >
        {navItems.map(({ path, label, icon: Icon }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) =>
              `hover:bg-[#00AD511A] p-2 rounded w-full flex items-center ${
                isActive ? "text-[#00AD51] bg-[#00AD511A]" : "text-[#4F4F4F]"
              }`
            }
          >
            <Icon className="w-[15px] h-[15px]" />
            {isExpanded && (
              <span className="ml-3 text-sm whitespace-nowrap">{label}</span>
            )}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
