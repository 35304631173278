import React, { useEffect, useState } from "react";
// import DashboardTable from "./dashboard/DashboardTable";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";

import { download, plusCircle, upload } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import useGetReservoirsManager from "../controllers/getReservoirsController";

const ListOfReservoirs = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedClient, setSelectedClient] = useState("");
  

  const { data, isLoading } = useGetReservoirsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const headers = ["Name", "Reservoir Type", "Oil Field", "More"];

  useEffect(() => {
    setSelectedClient(true);
  }, [selectedClient]);

  const options = [
    "View Reservoir",
    "Edit Reservoir",
    "Update Hydrocarbon",
    "Delete Reservoir",
  ];

  const getFormattedValue = (el, index) => {
    return [el?.name, el?.reservoir_type, el?.oil_field || "N/A"];
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-between pt-3 px-4">
            <DropdownandSearch
              options={["Option1", "Option"]}
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} icon={download} />
              <GreenBtn
                // onClick={handleAddClient}
                icon={upload}
                text={"Upload Reservoir"}
              />
              <GreenBtn
                // onClick={handleAddClient}
                icon={plusCircle}
                text={"Add Reservoir"}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => {
                console.log(option, index, val);

                if (index === 2) {
                  console.log(val.id);
                  setSelectedClient(val.id);
                  // navigate(`?clientId=${val.id}`);
                }
              }}
              // Close popup function
            />
          </div>

          {/* Pagination */}
          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* <ClientTable /> */}
        </div>
      </>
      {/* {showAddClient && (
        <div className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6  w-full rounded-[10px] h-full overflow-y-auto">
            <AddClient
              onClose={handleCloseAddClient}
              clientId={selectedClient}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ListOfReservoirs;
