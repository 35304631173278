export default function CustomInput ({labelClassName, value,isTextarea = false, isRequired,radioOption = [],isRadio = false,onChange,noOfRows = 3 ,type,inputClassName, placeholder,title,wrapperClassName}) {


    return(
        <>
        {isTextarea ? 
                <div className={` flex flex-col ${wrapperClassName} h-fit gap-2 w-full`}>
                    <label className={`text-[12px] ${labelClassName} text-[#181918] font-medium leading-[18px]`}>{title} {isRequired && <span className="text-red-600">*</span>}</label>
                    <textarea value={value} onChange={(e) => onChange(e.target.value)} rows={noOfRows}   className={`rounded-[5px] ${inputClassName} w-full text-[12px] font-normal p-[17px] text-[#181918] placeholder:text-[#989898] bg-[#F6F8FC] outline-none border-0  `} placeholder={placeholder} />
                     
                </div> 
                
                :

        isRadio ? 

                <div className={` flex flex-col ${wrapperClassName} h-fit gap-2 w-full`}>
                    <label className={`text-[12px] ${labelClassName} text-[#181918] font-medium leading-[18px]`}>{title} {isRequired && <span className="text-red-600">*</span>}</label>
                    
                    <div className="w-full h-fit flex items-center px-2 justify-between">
                        {radioOption.map((el,l) => (
                            <div key={l} className='flex items-center'>
                                <input
                                    type="radio"
                                    id={`${el?.radio_value}Checkbox`}
                                    value={el?.radio_value}
                                    name={el?.name}
                                    checked= {value === el?.radio_value}
                                    className="h-[24px] w-[24px] border-2 border-brandBackground rounded-full checked:bg-brandBackground checked:border-brandBackground focus:outline-none"
                                    onChange={(e) => {onChange(e.target.value)}}
                                
                                />
                                <label htmlFor={`${el?.name_id}Checkbox`} className={`ml-2 text-[12px] font-semibold capitalize text-[#181918]`}>
                                                    {el?.radio_value}
                                </label>
                            </div>
                        ))}
                        
                    </div>
                    
                </div> 
        
                : 

                <div className={` flex flex-col ${wrapperClassName} h-fit gap-2 w-full`}>
                    <label className={`text-[12px] ${labelClassName} text-[#181918] font-medium leading-[18px]`}>{title} {isRequired && <span className="text-red-600">*</span>}</label>
                    <input value={value} onChange={(e) => onChange(e.target.value)} type={type ? type : "text"} className={`rounded-[5px] ${inputClassName} w-full text-[12px] font-normal px-[17px] text-[#181918] placeholder:text-[#989898] bg-[#F6F8FC] outline-none border-0 h-[50px]`} placeholder={placeholder} />
                </div>

                 }
        </>
    )
}