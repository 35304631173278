import mobil from "../../assets/images/mobilLogo.svg";
import ReusableTable from "../../generalComponents/ReusableTable";
const DashboardTable = () => {
  const data = [
    {
      name: "Mobil Oil",
      email: "mobiloil@gmail.com",
      contractType: "PSC",
      alias: "Exxon",
      permission: "Oil Field +3",
      date: "12 Jan 2024",
      logo: mobil,
    },
    {
      name: "Mobil Oil",
      email: "mobiloil@gmail.com",
      contractType: "JV",
      alias: "Exxon",
      permission: "Oil Field +3",
      date: "12 Jan 2024",
      logo: mobil,
    },
    {
      name: "Mobil Oil",
      email: "mobiloil@gmail.com",
      contractType: "JV",
      alias: "Exxon",
      permission: "Oil Field +3",

      date: "12 Jan 2024",
      logo: mobil,
    },
  ];

  const columns = [
    { header: "Name", key: "name" },
    { header: "Email Address", key: "email" },
    { header: "Contract Type", key: "contractType" },
    { header: "Alias", key: "alias" },
    { header: "Operator Permission", key: "permission" },
    { header: "Date", key: "date" },
  ];

  const coloredTextColumns = ["permission"];
  const coloredTextValues = [
    { value: "Oil Field", className: "text-[#00AD51] bg-[#00AD511A]" },
    { value: "+3", className: "text-[#00AD51] bg-[#00AD511A]" },
  ];

  return (
    <div className="overflow-x-auto rounded-[10px] bg-[#ffffff] border border-[#E4E7EC] shadow-custom-3 mt-2">
      <ReusableTable
        columns={columns}
        data={data}
        coloredTextColumns={coloredTextColumns}
        coloredTextValues={coloredTextValues}
      />
    </div>
  );
};

export default DashboardTable;
