import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";

export const DeleteOmlsManager = ({ id }) => {
  console.log(id)
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/omls/${id}/`,
    ["omls"]
  );
  const deleteOmls = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteOmls,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
