import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GreenBtn from "../../../generalComponents/GreenBtn";
import OilTab from "./OilTab";
import { ReactComponent as EyeGuy } from "../../../assets/icons/eye.svg";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import OilFieldModal from "./OilFieldModal";

const OperatorDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const operatorData = location.state;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both the menu and the button
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-white rounded-[10px] p-6 shadow-custom-1">
      <div className="flex items-center mb-6">
        <button
          onClick={handleGoBack}
          className="inline-flex items-center text-[#344054] bg-[#FFFFFF] rounded-[4px] px-2 py-2 mr-4 border border-[#E4E7EC]"
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.146446 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146446 2.64645L2.14645 0.646447C2.34171 0.451184 2.65829 0.451184 2.85355 0.646447C3.04882 0.841709 3.04882 1.15829 2.85355 1.35355L1.70711 2.5L9.5 2.5C9.77614 2.5 10 2.72386 10 3C10 3.27614 9.77614 3.5 9.5 3.5L1.70711 3.5L2.85355 4.64645C3.04882 4.84171 3.04882 5.15829 2.85355 5.35355C2.65829 5.54882 2.34171 5.54882 2.14645 5.35355L0.146446 3.35355Z"
              fill="black"
            />
          </svg>
        </button>
        <p className="text-[12px]">Go Back</p>
      </div>
      <div className="flex items-center justify-between">
        <h1 className="text-[24px] font-[600] text-[#000000] leading-[28.8px]">
          {operatorData.name}
        </h1>

        <div className="flex items-center gap-1">
          <GreenBtn className="flex items-center gap-2 text-[14px] font-[600] leading-[20px]">
            <span className="material-icons ">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.63322 1.89831C2.52722 1.37164 1.25 2.17807 1.25 3.40308V13.0944C1.25 14.0587 1.80457 14.937 2.67517 15.3516L6.47506 17.161C6.61717 17.2287 6.77554 17.2548 6.93184 17.2361L13.0259 16.5107L16.3668 18.1015C17.4728 18.6282 18.75 17.8218 18.75 16.5968V6.90544C18.75 5.94116 18.1954 5.06286 17.3248 4.64828L13.5249 2.83881C13.3862 2.77273 13.2318 2.74632 13.079 2.76249L9.51647 3.13947C9.50824 3.14035 9.50002 3.14134 9.49182 3.14245L6.96724 3.48594L3.63322 1.89831ZM17.0833 16.5968L14 15.1285V4.91101L16.6083 6.15305C16.8985 6.29124 17.0833 6.58401 17.0833 6.90544V16.5968ZM12.3333 14.9147V4.51737L9.7042 4.79558L7.66667 5.0728V15.4702L12.3333 14.9147ZM2.91667 3.40308L6 4.87133V15.0888L3.39172 13.8468C3.10152 13.7086 2.91667 13.4158 2.91667 13.0944V3.40308Z"
                  fill="white"
                />
              </svg>
            </span>{" "}
            View Top Structure Map
          </GreenBtn>
          <button
            ref={buttonRef}
            onClick={toggleMenu}
            className="btn btn-ghost btn-sm border border-[#D0D5DD] py-[8px] px-[12px] rounded-[40px] shadow-custom-1 hover:shadow-custom-2"
          >
            <span className="material-icons">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 2.66675C9 3.21903 8.55228 3.66675 8 3.66675C7.44772 3.66675 7 3.21903 7 2.66675C7 2.11446 7.44772 1.66675 8 1.66675C8.55228 1.66675 9 2.11446 9 2.66675Z"
                  fill="black"
                />
                <path
                  d="M9 8.00008C9 8.55237 8.55228 9.00008 8 9.00008C7.44772 9.00008 7 8.55237 7 8.00008C7 7.4478 7.44772 7.00008 8 7.00008C8.55228 7.00008 9 7.4478 9 8.00008Z"
                  fill="black"
                />
                <path
                  d="M8 14.3334C8.55228 14.3334 9 13.8857 9 13.3334C9 12.7811 8.55228 12.3334 8 12.3334C7.44772 12.3334 7 12.7811 7 13.3334C7 13.8857 7.44772 14.3334 8 14.3334Z"
                  fill="black"
                />
              </svg>
            </span>
          </button>
          {isMenuOpen && (
            <div
              ref={menuRef}
              className="absolute right-2 md:right-2 z-10 mt-28 w-28 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
            >
              <div className="p-1">
                <div
                  onClick={() => setIsModalOpen(true)}
                  className="btn btn-ghost btn-sm w-full text-[#828282] font-600 px-4 py-2 text-[10px] border-b hover:bg-[#E7FFF2] hover:text-[#00AD51]"
                >
                  <div className="flex w-full items-center gap-1">
                    <EyeGuy className="w-20px h-20px" />
                    <p className="whitespace-nowrap">See details</p>
                  </div>
                </div>
                <div className="btn btn-ghost btn-sm w-full text-[#828282] font-600 px-4 py-2 text-[10px] border-b hover:bg-[#E7FFF2] hover:text-[#00AD51]">
                  <div className="flex w-full items-center gap-1">
                    <Pencil className="w-20px h-20px" />
                    <p className="whitespace-nowrap">Edit</p>
                  </div>
                </div>
                <div className="btn btn-ghost btn-sm w-full font-600 px-4 py-2 text-[10px] border-b hover:bg-red-100">
                  <div className="flex items-center w-full gap-1 text-[#D00000]">
                    <Delete className="w-20px h-20px" />
                    <p className="whitespace-nowrap">Delete</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <OilFieldModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          fieldData={operatorData}
        />
      </div>

      <OilTab />
    </div>
  );
};

export default OperatorDetail;
