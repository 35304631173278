import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormWrapper from "../../../generalComponents/FormWrapper";
import { useNavigate } from "react-router-dom";
import { OTPManager } from "../controllers/otp";
import CustomButton from "../../../generalComponents/Button";
import OTPInput from "react-otp-input";
import { useSearchParams } from "react-router-dom";

const OTPForm = () => {
  const [otp, setOtp] = useState("");
  const { otpFn, isLoading, isSuccess, data } = OTPManager();
  const [timer, setTimer] = useState(59);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      console.log(data?.data?.token);
      localStorage.setItem("token", data?.data?.token);
    }
  });

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTime) => prevTime - 1);
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [timer]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const details = {
      email: userEmail,
      totp: otp,
    };
    // console.log(details);
    await otpFn(details);
  };

  return (
    <FormWrapper>
      <div className="bg-white p-8 rounded-[10px] border border-[#E0E0E0] w-full max-w-sm">
        <h2 className="text-[25px] flex justify-start font-[600] text-black text-center">
          Enter OTP
        </h2>
        <p className="flex justify-start text-[#667185] text-[13px] font-[400] mb-[40px]">
          Enter the 4 digit code sent to your email address
        </p>

        {/* OTP Input Fields */}
        <form
          onSubmit={handleSubmit}
          className="flex justify-center space-x-3 mt-12 mb-4"
        >
          <OTPInput
            containerStyle="w-full items-center justify-center mt-2 flex mb-10"
            inputStyle={{
              backgroundColor: "#F4F4F4",
              width: 40,
              height: 40,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 1,
              borderColor: "#000000",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span className="mr-3"></span>}
            renderInput={(props) => <input {...props} />}
          />
        </form>

        {/* OTP Timer */}
        <div className="text-center text-[#828282] text-[10px]">
          <p className="text-[10px] ">OTP will expire in </p>
          <span className="font-bold">
            {String(Math.floor(timer / 60)).padStart(2, "0")}:
            {String(timer % 60).padStart(2, "0")}
          </span>
        </div>

        {/* Confirm Button */}
        <CustomButton
          type={"submit"}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
          buttonText={"Confirm"}
          className={"w-full"}
        />

        {/* Support Footer */}
        <div className="text-center mt-3 text-[12px] text-[#98A2B3]">
          Having a bit of trouble?{" "}
          <Link to="#" className="text-[#00AD51] font-medium">
            Contact support
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
};

export default OTPForm;
