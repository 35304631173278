export const formatUnderscoreString = (str) => {
  // Handle empty or null input
  if (!str) return "";

  // Split by underscore, capitalize each word, and join with space
  return str
    .split("_")
    .map((word) => {
      // Handle empty parts
      if (!word) return "";
      // Capitalize first letter, make rest lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};
