import React from "react";
import { getInitials } from "../utils/getInitials";

const InitialsAvatar = ({ name, classN,textClass }) => {
  return (
    <div className={`w-10 h-10 rounded-full bg-[#E7F6EC] flex items-center justify-center ${classN}`}>
      <span className={`text-[#101928] font-bold ${textClass}`}>{getInitials(name)}</span>
    </div>
  );
};

export default InitialsAvatar;
