import { API_ROUTES } from "../../../constants/api_management/api_routes";
import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const NewPasswordManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    API_ROUTES.RESET,
    [""],
    false
  );
  const resetPassword = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    resetPassword,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
