import React, { useEffect, useState } from "react";
import { Loader2, X } from "lucide-react";
import { ReplyTicketManager } from "../controllers/replyTicket";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../../generalComponents/Loader";

const ReplyModal = ({ isOpen, onClose, recipientEmail, ticketId, recipientName }) => {
  const { replyTicket, isLoading, isSuccess } = ReplyTicketManager({ ticketId });

  // Formik setup (always called at the top)
  const formik = useFormik({
    initialValues: {
      body: "",
      attachment: [],
    },
    validationSchema: Yup.object({
      body: Yup.string().required("Body is required"),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("body", values.body); 
        // Append all files to formData
        Array.from(values.attachment).forEach((file) =>
          formData.append("attachment", file)
        ); 
        await replyTicket(formData);
      } catch (error) {
        console.error("Form submission error:", error);
      }
    },
  });

  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (files) {
      formik.setFieldValue("attachment", files);
    }
  };

  useEffect(() => {
    if(isSuccess){
      onClose()
    }
  }, [isSuccess])
  // Early return if modal is closed
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white rounded-lg w-full max-w-2xl mx-4 py-4"
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 bg-green-500 rounded-full flex items-center justify-center text-white">
              RE
            </div>
            <div>
              <span className="text-[13px] font-medium">Reply to </span>
              <span className="text-[10px] font-light bg-[#F2F2F2] p-1">
                {recipientName} ({recipientEmail})
              </span>
            </div>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Text Editor */}
        <div className="p-4">
          <div className="border rounded-[5px] bg-[#F6F8FC] p-2">
            <textarea
              name="body"
              value={formik.values.body}
              onChange={formik.handleChange}
              className="w-full h-48 p-4 bg-[#F6F8FC] resize-none focus:outline-none focus:ring-1 focus:ring-[#F6F8FC]"
              placeholder="Type your body here..."
            />
            {formik.errors.body && (
              <p className="text-red-500 text-sm mt-2">{formik.errors.body}</p>
            )}

            <div className="flex justify-between items-center mt-4">
              {/* Attachments */}
              <div
                onClick={() => document.getElementById("fileInput")?.click()}
                className="px-3 py-1 rounded-full bg-[#00AD511A] cursor-pointer"
              >
                <span className="text-gray-600">📎 Attachments </span>
              </div>
              <input
                type="file"
                id="fileInput"
                className="hidden"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.png"
                multiple
                onChange={handleFileSelect}
              />

              <button
                type="submit"
                className="bg-[#00AD51] text-white px-6 py-2 rounded-full hover:bg-green-600 flex items-center gap-2"
              >
               {isLoading ? <span className=" flex gap-1">Sending</span> : <span className=" flex gap-1">Send →</span>} 
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReplyModal;
