import React, { useEffect, useState } from "react";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle, upload } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import useGetWellsManager from "../controllers/getWellsController";

const ListOfWells = () => {
  // const [showAddWell, setShowAddWell] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWell, setSelectedWell] = useState("");
  // const navigate = useNavigate();

  // const handleAddWell = () => {
  //   navigate("");
  //   setShowAddWell(true);
  // };

  const { data, isLoading } = useGetWellsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  // Updated headers for wells
  const headers = [
    "Well Name",
    "Well Type",
    "Status",
    "Field",
    "Depth",
    "Location",
    "Lng",
    "Lat",
    "Completion Date",
    "More",
  ];

  useEffect(() => {
    setSelectedWell(true);
  }, [selectedWell]);

  // Updated options for wells
  const options = [
    "View Well",
    "Edit Well",
    "Update Production Data",
    "Delete Well",
  ];

  const getFormattedValue = (well) => {
    return [
      well?.name,
      well?.well_type,
      well?.status,
      well?.field_name,
      well?.depth,
      well?.location,
      well?.longitude,
      well?.latitude,
      formatDate(well?.completion_date),
    ];
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
        {/* Search and Actions Header */}
        <div className="flex items-center justify-between pt-3 px-4">
          <DropdownandSearch
            options={["Active Wells", "Inactive Wells", "All Wells"]}
            searchValue={searchValue}
            searchOnchange={(e) => setSearchValue(e.target.value)}
          />
          <div className="flex items-center gap-[5px]">
            <TransparentBtn text="Export" icon={download} />
            <GreenBtn
              // onClick={handleAddWell}
              icon={upload}
              text="Upload Wells"
            />
            <GreenBtn
              // onClick={handleAddWell}
              icon={plusCircle}
              text="Add Well"
            />
          </div>
        </div>

        {/* Table Section */}
        <div className="h-[67vh] w-full relative">
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            options={options}
            showCheckBox={false}
            popUpFunction={(option, index, val) => {
              if (index === 2) {
                setSelectedWell(val.id);
                // setShowAddWell(true);
              }
            }}
          />
        </div>

        {/* Pagination */}
        <NUIMSPagination
          pagination={data?.data?.pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      {/* Modal for Add/Edit Well would go here */}
      {/* {showAddWell && (
        <div className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 w-full rounded-[10px] h-full overflow-y-auto">
            <AddWell
              onClose={() => setShowAddWell(false)}
              wellId={selectedWell}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ListOfWells;
