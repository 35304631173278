import React, { useState } from "react";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle } from "../../../assets/icons";
import Analytics from "../../operators/component/Analytics";
import TabsManagement from "../../../generalComponents/TabsManagement";
import ListOfWells from "../components/ListOfWells";

const WellsPage = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentView, setCurrentView] = useState(0);

  // const handleClick = () => setIsModalOpen(true);

  const tabData = [
    { label: "Analytics", component: Analytics },
    { label: "List of Wells", component: ListOfWells },
  ];

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="font-[600] text-[24px] mb-2 w-full">Wells</h1>
          <p className="text-[#667185] text-[14px] leading-[20px]">
            Showing data over the last 30 days
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center gap-[5px]">
          <TransparentBtn text="Export CSV" icon={download} />
          <GreenBtn icon={plusCircle} text="Add Well" />
        </div>
      </div>

      {/* Tabs and Content Section */}
      <div className="flex flex-col gap-0 bg-[#ffffff]">
        <TabsManagement
          options={tabData.map((tab) => tab.label)}
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
        {React.createElement(tabData[currentView].component)}
      </div>

      {/* Add Well Modal */}
      {/* <AddWell isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> */}
    </div>
  );
};

export default WellsPage;
