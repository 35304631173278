import React, { useEffect, useState } from "react";
import OperatorSidebar from "../component/OperatorSidebar";
import OperatorsMain from "../component/OperatorsMain";
import AddOperatorForm from "../component/AddOperatorForm";
import useDebounce from "../../../utils/UseDebounce";
import useGetAllClientsManager from "../../clientManagement/controllers/getClientsController";
import { useNavigate } from "react-router-dom";

const OperatorsPage = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(
    `&search=${searchValue}&type=operator`,
    1000
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const { data, isLoading } = useGetAllClientsManager({
    enabled: true,
    searchQuery: searchValue ? debouncedSearchValue : "&type=operator`",
    page: currentPage,
  });

  useEffect(() => {
    if (data?.data?.results.length > 0) {
      navigate(`?id=${data?.data?.results[currentIndex]?.id}`);
    }
  }, [currentIndex, data]);

  const [showAddOperator, setShowAddOperator] = useState(false);

  const handleCloseAddOperator = () => {
    setShowAddOperator(false);
  };

  const handleSidebarToggle = (expanded) => {
    setIsSidebarExpanded(expanded);
  };

  return (
    <div className="relative">
      <div className="flex items-center w-auto gap-3">
        <div
          className={`
            fixed 
            top-[72px] 
            pt-[10px] 
            left-18 
            h-[calc(100vh-4rem)] 
            z-10 
            transition-all 
            duration-300 
            ease-in-out
          `}
        >
          <OperatorSidebar
            data={data}
            isLoading={isLoading}
            setSearchValue={setSearchValue}
            onToggle={handleSidebarToggle}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </div>
        <div
          className={` 
            w-auto
            transition-all 
            duration-300 
            ease-in-out 
            ${isSidebarExpanded ? "ml-[170px]" : "ml-[52px]"} 
            flex-grow 
            px-5
          `}
        >
          <OperatorsMain currentOperator={data?.data?.results[currentIndex]} />
        </div>
      </div>

      {showAddOperator && (
        <div className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 w-full rounded-[10px] h-full overflow-y-auto">
            <AddOperatorForm onClose={handleCloseAddOperator} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OperatorsPage;
