import React, { useEffect, useState } from "react";
// import DashboardTable from "./dashboard/DashboardTable";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";

import AddLiftings from "../../../generalComponents/lifting-management/AddLiftings";
import { download, plusCircle } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import useGetAllListingsManager from "../controllers/getLiftingsController";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { useNavigate } from "react-router-dom"; 
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import AddProducts from "../../../generalComponents/lifting-management/AddProducts";
import AddVessel from "../../../generalComponents/lifting-management/AddVessel";
import AddVesselAgent from "../../../generalComponents/lifting-management/AddVesselAgent";
import AddVesselSize from "../../../generalComponents/lifting-management/AddVesselSize";
import AddClient from "../../../generalComponents/lifting-management/AddClientt";
import AddClientContract from "../../../generalComponents/lifting-management/AddClientContract";
import AddDepartingPort from "../../../generalComponents/lifting-management/AddDepartingPort";
import AddDestinationPort from "../../../generalComponents/lifting-management/AddDestinationPort";
import { clientContractData, clientContractHeaders, clientDetailData, clientHeaders, departingPortData, destinationPortData, headers, liftingData, portHeaders, portTab, productData, productHeaders, productOptions, productTab, vesselAgentData, vesselAgentHeaders, vesselData, vesselHeaders, vesselSizeData, vesselSizeHeaders } from "../../../generalComponents/lifting-management/DataToExport";
import ListManagementGeneralDetailModal from "../../../generalComponents/lifting-management/ListManagementGeneralDetailModal";
import LiftingDetailsModal from "../../../generalComponents/lifting-management/LiftingDetailsModal";

const Lifting = () => {
  const [showAddLiftings, setshowAddLiftings] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPort, setCurrentPort] = useState(0)
  const [showAddProducts, setshowAddProducts] = useState(false);
  const [isListManageModalOpen, setIsListManageModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLiftings, setselectedLiftings] = useState("");
  const navigate = useNavigate();
  const handleAddLiftings = () => {
    setshowAddLiftings(true);
  };
  const handleAddProduct = () => {
    setshowAddProducts(true);
  };
  const handleCloseAddProduct = () => {
    setshowAddProducts(false);
  };
  const [selectedTab, setSelectedTab] = useState(0);

  const handleCloseAddLiftings = () => {
    setshowAddLiftings(false);
  };

  const { data, isLoading } = useGetAllListingsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );
  useEffect(() => {
    setselectedLiftings(true);
  }, [selectedLiftings]);

  const options = [
    "See Details",
    "View Dashboard",
    "Edit Client",
    "Deactivate Client",
  ];

  const getFormattedValue = (el, index) => {
    {
      console.log(el);
    }
    return [
      <div key={index} className="flex items-center gap-5">
        <InitialsAvatar name={el?.name} /> <p>{el?.name}</p>
      </div>,

      el?.email,
      el?.role || "N/A",
      formatDate(el?.created_datetime),
    ];
  };

  const openModal = () => setIsModalOpen(true);
  const listHeader = [
    productHeaders,
    vesselHeaders,
    vesselAgentHeaders,
    vesselSizeHeaders,
    clientHeaders,
    clientContractHeaders,
    portHeaders
  ]
const TestData = [
    productData,
    vesselData,
    vesselAgentData,
    vesselSizeData,
    clientDetailData,
    clientContractData, 
      departingPortData,
      destinationPortData, 
]
  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      {/* Tabs Section */}
      <div className="flex items-center border-b border-[#E5E7EB] overflow-x-auto mb-6">
        <div
          onClick={() => setSelectedTab(0)}
          className={`cursor-pointer pb-4 w-full text-center font-[600] leading-[14px] text-[12px] pt-3 px-3 relative ${
            selectedTab === 0
              ? "text-white bg-[#00AD51] rounded-md"
              : "text-[#828282] hover:text-gray-700"
          }`}
        >
          List Of Liftings
        </div>
        <div
          onClick={() => setSelectedTab(1)}
          className={`cursor-pointer pb-4 w-full text-center font-[600] leading-[14px] text-[12px] pt-3 px-3 relative ${
            selectedTab === 1
              ? "text-white bg-[#00AD51] rounded-md"
              : "text-[#828282] hover:text-gray-700"
          }`}
        >
          Lift Management
        </div>
      </div>

      {selectedTab === 0 && (
        <>
          <div className="flex items-center justify-between mb-6">
            <h1 className="font-[600] text-[24px] mb-2 w-full">
              List Of Liftings
            </h1>

            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} icon={download} />
              <GreenBtn
                onClick={handleAddLiftings}
                icon={plusCircle}
                text={"Add Lifting"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
            <div className="flex items-center justify-start pt-3 px-4">
              <DropdownandSearch
                options={["Option1", "Option"]}
                searchValue={searchValue}
                searchOnchange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="h-[67vh] w-full relative">
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={headers}
                options={options}
                showCheckBox={false}
                popUpFunction={(option, index, val) => {
                  console.log(option, index, val);
                  console.log("hello");
                  openModal();

                  if (index === 2) {
                    console.log(val.id);
                    setselectedLiftings(val.id);
                    setshowAddLiftings(true);
                    navigate(`?clientId=${val.id}`);
                  }
                }}
                // Close popup function
              />
              <LiftingDetailsModal
                isOpen={isModalOpen}
                title={"Lifting Details"}
                onClose={() => setIsModalOpen(false)}
                liftingData={{
                  laycanStartDate: "2023-04-01",
                  laycanEndDate: "2023-04-15",
                  product: "Crude Oil",
                  client: "ABC Inc.",
                  clientContract: "Contract #123",
                  cargoOwner: "XYZ Corp.",
                  exportVessel: "Vessel A",
                  vesselAgent: "Agent X",
                  offLoadingPort: "Port A",
                  departureVesselDraft: "12.5 MT",
                  remarks: "Lorem ipsum dolor sit amet",
                  blQuantityMT: "10,000 MT",
                  blQuantityLtrs: "12,000,000 Ltrs",
                  expectedTimeDeparture: "2023-04-15 12:00 PM",
                  laytime: "72 hours",
                  actualTimeDeparture: "2023-04-16 10:00 AM",
                }}
              />
            </div>

            <NUIMSPagination pagination={data?.data?.pagination} />
          </div>
        </>
      )}
      {selectedTab === 1 && (
        <>
          <div className="w-full h-fit p-5">
              <div className="w-full h-fit border-b border-[#E5E7EB] flex justify-between">
                  {productTab.map((el,l) => (
                     <div key={l} onClick={() => setCurrentTab(l)} className={`px-5 py-3 uppercase cursor-pointer h-fit text-[12px] font-semibold w-fit  ${currentTab === l ? "text-[#00AD51] border-b-[2px] border-[#00AD51] " : "text-[#828282]"}`}>
                        {el}
                     </div>
                  ))}
              </div>
          </div>
          {currentTab === 6 && <div className="w-fit h-fit flex mb-4  gap-[10px]">
            {portTab.map((el,l) => (
              <div key={l} onClick={() => setCurrentPort(l)} className={`p-[10px] capitalize cursor-pointer h-fit text-[12px]  w-fit rounded-[40px] ${currentPort === l ? "bg-[#00AD51] text-white font-semibold" : "text-[#828282] font-normal  border border-[#E5E7EB]"}`}> {el} </div>
            ))}
          </div>}
          <div className="flex items-center justify-between mb-6">
            <h1 className="font-[600] text-[24px] mb-2 w-full">
              {currentTab === 6 ? portTab[currentPort] : productTab[currentTab]}
            </h1>

            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} icon={download} />
              <GreenBtn
                onClick={handleAddProduct}
                icon={plusCircle}
                text={`Add ${currentTab === 6 ? portTab[currentPort] : productTab[currentTab]}`}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
            <div className="flex items-center justify-start pt-3 px-4">
              <DropdownandSearch
                options={["Option1", "Option"]}
                searchValue={searchValue}
                searchOnchange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="h-[67vh] w-full relative">
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={listHeader[currentTab]}
                options={productOptions}
                showCheckBox={false}
                popUpFunction={(option, index, val) => {
                  console.log(option, index, val);
                  console.log("hello");
                  openModal();
/* 
                  if (index === 2) {
                    console.log(val.id);
                    setselectedLiftings(val.id);
                    setshowAddLiftings(true);
                    navigate(`?clientId=${val.id}`);
                  } */
                }}
                // Close popup function
              />
              <ListManagementGeneralDetailModal
                isOpen={isListManageModalOpen}
                title={currentTab === 6 ? portTab[currentPort] : productTab[currentTab]}
                onClose={() => setIsListManageModalOpen(false)}
                listData={currentTab === 6 ? TestData[currentPort === 0 ? 6 : 7] : TestData[currentTab]}
              />
            </div>

            <NUIMSPagination type="Pages" pagination={data?.data?.pagination} />
          </div>
        </>
      )}
      {showAddLiftings && (
        <AddLiftings
          open={handleAddLiftings}
          onClose={handleCloseAddLiftings}
        />
      )}
      {(showAddProducts && currentTab === 0)&& (
        <AddProducts
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 1)&& (
        <AddVessel
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 2)&& (
        <AddVesselAgent
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 3)&& (
        <AddVesselSize
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 4)&& (
        <AddClient
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 5)&& (
        <AddClientContract
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 6 && currentPort === 0)&& (
        <AddDepartingPort
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
      {(showAddProducts && currentTab === 6 && currentPort === 1)&& (
        <AddDestinationPort
          open={handleAddProduct}
          onClose={handleCloseAddProduct}
        />
      )}
    </div>
  );
};

export default Lifting;
