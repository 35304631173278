import React from "react";

const NotificationsModal = ({ notifications, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end mt-10 mr-2">
      <div className="bg-white shadow-lg rounded-[12px] w-full max-w-md">
        <div className="px-6 py-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium">Notifications</h2>
            <button
              className="text-gray-400 hover:text-gray-600"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <ul className="space-y-4">
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className={`rounded-lg p-4 border-l-4 ${
                  notification.severity === "success"
                    ? " border-brandGreen"
                    : notification.severity === "warning"
                    ? "border-yellow-500"
                    : notification.severity === "error"
                    ? "border-red-500"
                    : "border-x-brandBlue"
                }`}
              >
                <h3 className="text-lg font-medium">{notification.title}</h3>
                <p className="text-gray-700">{notification.message}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationsModal;
