import React, { useState } from "react";
import TransparentBtn from "../../../generalComponents/TransparentButton";

import GreenBtn from "../../../generalComponents/GreenBtn";
// import StaffTable from "./staffManagement/StaffTable";
import AddOilFieldModal from "../components/AddOIlField";
import { download, plusCircle } from "../../../assets/icons";
import Analytics from "../../operators/component/Analytics";
import ListOfOilFields from "../components/ListOfOilFields";
import TabsManagement from "../../../generalComponents/TabsManagement";

const OilField = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClick = () => setIsModalOpen(true);
  const [currentView, setCurrentView] = useState(0);

  const tabData = [
    // { label: "PROFILE", component: Profile },
    { label: "Analytics", component: Analytics },
    { label: "List of Oil Fields", component: ListOfOilFields },
  ];
  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="font-[600] text-[24px] mb-2 w-full">Oil Field</h1>
          <p className="text-[#667185] text-[14px] leading-[20px]">
            Showing data over the last 30 days
          </p>
        </div>

        <div className="flex items-center gap-[5px]">
          <TransparentBtn text={"Export CSV"} icon={download} />
          <GreenBtn
            onClick={handleClick}
            icon={plusCircle}
            text={"Add Oil Field"}
          />
        </div>
      </div>
      <div className="flex flex-col gap-0 bg-[#ffffff] ">
        <TabsManagement
          options={tabData.map((el) => el.label)}
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
        {React.createElement(tabData[currentView].component)}
      </div>
      <AddOilFieldModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default OilField;
