import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import CustomInput from "../../../generalComponents/CustomInput";
import CustomButton from "../../../generalComponents/CustomButton";
import { toast } from "react-toastify";
import { CreateTicketManager } from "../controllers/createTicket";

const CreateTicket = ({ isTicketOpen, onTicketClose, recipientEmail, recipientName }) => {
  const {createTicket, isLoading, isSuccess} = CreateTicketManager()
  const initialData = {
    subject: "",
    department: "",
    body: "", 
    attachment: [],
    phone_number: "",
    priority: "high",
  }
  const [formData, setFormData] = useState(initialData);
  const option = [
    {
      radio_value : "high",
      name: "priority",  
    },
    {
      radio_value : "medium",
      name: "priority",  
    },
    {
      radio_value : "low",
      name: "priority",  
    },
  ]
  const validateForm = () => {

    return(
      formData.subject &&
      formData.body &&
      formData.department && 
      formData.priority  
    )
  } 
  useEffect(() => {
    if(isSuccess){
      onTicketClose() 
    }
  }, [isSuccess])

  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (files) {
      setFormData({...formData, attachment: files} )
    }
  };
  if (!isTicketOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 py-5 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-xl mx-4 py-4  ">
        <div className="p-4">
          {/* Header */}
          <div className="flex items-center justify-between mb-4">
             <h3 className="text-[#111111] text-[15px] font-medium">
              Add Ticket
             </h3>
            <button
              onClick={() => {setFormData(initialData); onTicketClose() }}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          <div className="w-full   max-h-[500px] py-[15px] h-full gap-[20px] border-t botder-[#F1F1F1] overflow-auto scrollbar-hide flex flex-col">
              <CustomInput title={"Subject"} isRequired={true} value={formData.subject} onChange={(e) => {setFormData({...formData, subject: e})}} placeholder={"Enter subject"} />
              <CustomInput title={"Department"} isRequired={true} value={formData.department} onChange={(e) => {setFormData({...formData, department: e})}} placeholder={"Enter department"} /> 
              <CustomInput title={"Describe Issue"} isRequired={true} isTextarea={true} noOfRows={10} value={formData.body} onChange={(e) => {setFormData({...formData, body: e})}} placeholder={"Write"} />
              {/* <CustomInput title={"Email"} isRequired={true} value={formData.email} onChange={(e) => {setFormData({...formData, email: e})}} placeholder={"Enter email"} /> 
               */}<CustomInput title={"Phone Number (Optional)"} value={formData.phone_number} onChange={(e) => {setFormData({...formData, phone_number: e})}} placeholder={"Enter phone number"} />
              <CustomInput title={"Priority"} isRequired={true} value={formData.priority} onChange={(e) => {setFormData({...formData, priority: e})}}  wrapperClassName={"my-[15px]"} isRadio={true} radioOption={option} />  
              <div className="flex justify-between items-center mt-4">
              {/* Attachments */}
              <div
                onClick={() => document.getElementById("fileInput")?.click()}
                className="px-3 py-1 rounded-full bg-[#00AD511A] cursor-pointer"
              >
                <span className="text-gray-600">📎 Attachments </span>
              </div>
              <input
                type="file"
                id="fileInput"
                className="hidden"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.png"
                multiple
                onChange={handleFileSelect}
              />

              
            </div>
              <CustomButton title={"Submit"} isLoading={isLoading} onClick={() => {
                if(validateForm){
                  const finalData = new FormData();
                  finalData.append("subject", formData.subject); 
                  finalData.append("phone_number", formData.phone_number); 
                  finalData.append("department", formData.department); 
                  finalData.append("priority", formData.priority); 
                  finalData.append("body", formData.body); 
                  // Append all files to formData
                  Array.from(formData.attachment).forEach((file) =>
                    finalData.append("attachment", file)
                  ); 
                  createTicket(finalData)
                }else{
                  toast.error("Ensure all required fields are filled")
                }
               }} buttonName={"my-[15px]"} />
                    
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;
