import React from "react";
import { X } from "lucide-react";  
import useGetCompanyOilProductionByIdManager from "../../modules/companyOIl/controllers/getCompanyOilProductionByIdController";

const CompanyOilDetails = ({ isOpen, onClose,  idd }) => {
  
  const {data} = useGetCompanyOilProductionByIdManager({id: idd}) 
  if (!isOpen) return null;
  console.log(idd)
  console.log(data)
  const detailFields = [
    { id: "company", label: "Company", value: data?.data?.company?.name },
    { id: "date", label: "Date", value: data?.data?.date },
    { id: "oil", label: "Oil", value: data?.data?.oil }, 
    { id: "oil_production", label: "Oil Production", value: data?.data?.oil_production },
    { id: "ag", label: "AG", value: data?.data?.ag },
    { id: "bsw", label: "BSW", value: data?.data?.bsw },
    { id: "condensate_blended", label: "Condensate Blended", value: data?.data?.condensate_blended },
    { id: "condensate_unblended", label: "Condensate Unblended", value: data?.data?.condensate_unblended },{
      id: "nag",
      label: "NAG",
      value: data?.data?.nag,
    },
    { id: "planned_ag", label: "Planned AG", value: data?.data?.planned_ag },
    { id: "planned_nag", label: "Planned NAG", value: data?.data?.planned_nag },
    { id: "planned_oil", label: "Planned Oil", value: data?.data?.planned_oil },
    { id: "planned_condensate_blended", label: "Planned Condensate Blended", value: data?.data?.planned_condensate_blended },
    {
      id: "planned_condensate_unblended",
      label: "Planned Condensate Unblended",
      value: data?.data?.planned_condensate_unblended,
    }, 
  ];

  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 overflow-y-auto p-4"
    >
      <div
        className="bg-white rounded-lg w-full max-w-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-[16px] font-medium text-gray-900">Company Oil Details</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close dialog"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-hide">
          <div className="space-y-4">
            {detailFields.map((field) => (
              <div
                key={field.id}
                className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0"
              >
                <span className="text-[14px] text-gray-900 font-medium">
                  {field.label}
                </span>
                <span className="text-[14px] text-gray-500">
                  {field.value || "Info"}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOilDetails;
