import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import TransparentBtn from "./TransparentButton";

const ReusableTable = ({
  data = [],
  columns = [],
  coloredTextColumns = [],
  coloredTextValues = [],
  colorClassName = "", // Default color class
  itemsPerPage = 10,
  onRowClick = null,
}) => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  // Handle page change
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Calculate data for current page
  const offset = currentPage * itemsPerPage;
  const currentData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  if (!data.length || !columns.length) {
    return <div>No data to display</div>;
  }

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(" ");
    if (nameArray.length === 1) {
      return nameArray[0][0].toUpperCase();
    }
    return nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase();
  };

  const applyColoredTextStyle = (columnKey, text) => {
    if (coloredTextColumns.includes(columnKey)) {
      let styledText = text;

      coloredTextValues.forEach(({ value, className }) => {
        if (styledText.includes(value)) {
          styledText = styledText.replace(
            value,
            `<span class="${className} rounded-full px-1 mx-1">${value}</span>`
          );
        }
      });

      return <span dangerouslySetInnerHTML={{ __html: styledText }} />;
    }

    return <span>{text}</span>;
  };

  return (
    <>
      <table className="table w-full bg-white rounded-[10px] border border-[#E5E7EB] p-2 mt-2">
        <thead className="bg-[#F9FAFB] text-[10px] font-[500]">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
            <th>More</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="text-[10px] cursor-pointer"
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column.key === "name" ? (
                    <div className="flex items-center space-x-3">
                      {row.logo ? (
                        <img
                          src={row.logo}
                          alt="logo"
                          className="w-10 h-10 rounded-full"
                        />
                      ) : (
                        <div className="w-10 h-10 rounded-full bg-[#E7F6EC] flex items-center justify-center">
                          <span className="text-[#101928] font-bold">
                            {getInitials(row.name)}
                          </span>
                        </div>
                      )}
                      <div className="text-[#101928] font-[500]">
                        {row[column.key]}
                      </div>
                    </div>
                  ) : (
                    applyColoredTextStyle(column.key, row[column.key])
                  )}
                </td>
              ))}
              <td>
                <button className="btn btn-ghost btn-xs border border-[#E4E7EC] p-1">
                  <span className="material-icons">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 2.66675C9 3.21903 8.55228 3.66675 8 3.66675C7.44772 3.66675 7 3.21903 7 2.66675C7 2.11446 7.44772 1.66675 8 1.66675C8.55228 1.66675 9 2.11446 9 2.66675Z"
                        fill="black"
                      />
                      <path
                        d="M9 8.00008C9 8.55237 8.55228 9.00008 8 9.00008C7.44772 9.00008 7 8.55237 7 8.00008C7 7.4478 7.44772 7.00008 8 7.00008C8.55228 7.00008 9 7.4478 9 8.00008Z"
                        fill="black"
                      />
                      <path
                        d="M8 14.3334C8.55228 14.3334 9 13.8857 9 13.3334C9 12.7811 8.55228 12.3334 8 12.3334C7.44772 12.3334 7 12.7811 7 13.3334C7 13.8857 7.44772 14.3334 8 14.3334Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex items-center justify-between space-x-4 py-3 border-t border-[#E4E7EC] px-[18px]">
        <span className="text-[12px] text-[#667185] leading-[17.4px]">
          Page {currentPage + 1} of {pageCount}
        </span>
        <div className="hidden md:-mt-px md:flex items-center text-[14px]">
          <a
            href="#"
            className="inline-flex items-center rounded-[8px] text-[#00AD51]  bg-[#E7FFF2]  p-[8px] text-sm font-medium "
          >
            1
          </a>
          {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-[#98A2B3] hover:text-gray-700 hover:border-gray-300" */}
          <a
            href="#"
            aria-current="page"
            className="inline-flex items-center rounded-[8px] p-[8px] text-sm font-medium "
          >
            2
          </a>
          <a
            href="#"
            className="inline-flex items-center rounded-[8px] p-[8px] text-sm font-medium "
          >
            3
          </a>
          <span className="inline-flex items-center border-t-2 border-transparent p-[8px] text-sm font-medium text-[#98A2B3]">
            ...
          </span>
          <a
            href="#"
            className="inline-flex items-center rounded-[8px] p-[8px] text-sm font-medium "
          >
            8
          </a>
          <a
            href="#"
            className="inline-flex items-center rounded-[8px]  p-[8px] text-sm font-medium "
          >
            9
          </a>
          <a
            href="#"
            className="inline-flex items-center rounded-[8px]  p-[8px] text-sm font-medium "
          >
            10
          </a>
        </div>
        <ReactPaginate
          previousLabel={
            <TransparentBtn
              text={"Previous"}
              className="text-[12px] text-[#344054] rounded-[8px] flex items-center gap-[8px] shadow-custom-1 hover:shadow-custom-2"
            >
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.910581 5.58893C0.585145 5.26349 0.585145 4.73586 0.910581 4.41042L4.24392 1.07709C4.56935 0.751649 5.09699 0.751649 5.42243 1.07709C5.74786 1.40252 5.74786 1.93016 5.42243 2.2556L3.51168 4.16634L16.4998 4.16634C16.9601 4.16634 17.3332 4.53944 17.3332 4.99968C17.3332 5.45991 16.9601 5.83301 16.4998 5.83301L3.51168 5.83301L5.42243 7.74375C5.74786 8.06919 5.74786 8.59683 5.42243 8.92226C5.09699 9.2477 4.56935 9.2477 4.24391 8.92226L0.910581 5.58893Z"
                  fill="#344054"
                />
              </svg>
              Previous
            </TransparentBtn>
          }
          nextLabel={
            <TransparentBtn
              text={"Next"}
              className="text-[12px] text-[#344054] rounded-[8px] flex items-center gap-[8px] shadow-custom-1 hover:shadow-custom-2"
            >
              Next
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.0891 5.58893C17.4145 5.26349 17.4145 4.73586 17.0891 4.41042L13.7558 1.07709C13.4303 0.751649 12.9027 0.751649 12.5772 1.07709C12.2518 1.40252 12.2518 1.93016 12.5772 2.2556L14.488 4.16634L1.49984 4.16634C1.0396 4.16634 0.666504 4.53944 0.666504 4.99968C0.666504 5.45991 1.0396 5.83301 1.49984 5.83301L14.488 5.83301L12.5772 7.74375C12.2518 8.06919 12.2518 8.59683 12.5772 8.92226C12.9027 9.2477 13.4303 9.2477 13.7558 8.92226L17.0891 5.58893Z"
                  fill="#344054"
                />
              </svg>
            </TransparentBtn>
          }
          // pageCount={pageCount}
          // marginPagesDisplayed={2}
          // pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="flex items-center justify-between space-x-2"
          pageClassName="text-[12px]"
          pageLinkClassName="px-3 py-2 rounded-md hover:bg-gray-100"
          activeClassName=""
          activeLinkClassName="bg-blue-500 text-white hover:bg-blue-600"
          // previousClassName="font-bold"
          // nextClassName="font-bold"
          // disabledClassName="opacity-50 cursor-not-allowed"
        />
      </div>
    </>
  );
};

export default ReusableTable;
