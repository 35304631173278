import useUpdateManager from "../../../constants/controller_templates/put_controller_template";

export const UpdateGasPlantManager = ({ id }) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/gas-plants-productions/${id}/`,
    ["update_gas-plants-productions"],
    false,
    true
  );
  const updateGasPlant = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    updateGasPlant,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
