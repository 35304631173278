const TransparentBtn = ({ icon, className = "", text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={` flex px-12 items-center text-[14px] leading-[20.3px] justify-center gap-[10px] shadow-custom-1 hover:shadow-custom-2 rounded-[40px]  text-[#344054]  bg-whiteColor border border-offWhite  font-[600] py-2 `}
    >
      <img src={icon} alt="" />
      <p> {text}</p>
    </button>
  );
};

export default TransparentBtn;
