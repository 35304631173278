import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetTicketManager = ({
  page,
  status,
  searchQuery = "",
  enabled = true,
  pageSize = "10",
}) => {
  return useQuery(
    ["/supports/tickets/data/data", page, searchQuery, status],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/support/tickets?page=${page}&size=${pageSize}${searchQuery}&status=${status}`
          ),
        ];
        //${searchQuery}&status=${status}
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { refetchOnWindowFocus: false, enabled: enabled }
  );
};

export default useGetTicketManager;
