import React from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from "@headlessui/react";
import { X } from "lucide-react";

const ListManagementGeneralDetailModal = ({ isOpen, onClose, listData, title  }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center">
        <DialogPanel className="w-full max-w-2xl bg-white rounded-lg p-6 text-left overflow-y-auto shadow-xl transform transition-all">
          {/* Close Button */}
          <div className="flex justify-between items-center border-b pb-3 mb-4">
            <DialogTitle className="text-xl font-semibold text-gray-900">
              {title} Details
            </DialogTitle>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>

          {/* Modal Content */}
          <DialogDescription className="space-y-6">
            <div className="flex flex-col gap-2">
              {/* Repeatable Item Component */}
              {listData?.name && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Name</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.name || "Info"}
                  </p>
                </div>}
                {listData?.density && <div   className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Density</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.density || "Info"}
                  </p>
                </div>}
                {listData?.consumption_per_day && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">CONSUMPTION PER DAY</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.consumption_per_day || "Info"}
                  </p>
                </div>}
                {listData?.quality_name && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Quality Name</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.quality_name || "Info"}
                  </p>
                </div>}
                {listData?.quality_value && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Quality Value</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.quality_value || "Info"}
                  </p>
                </div>}
                {listData?.description && <div  className="flex flex-col gap-[14px]">
                  <p className="text-sm uppercase font-medium text-gray-500">Description</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.description || "Info"}
                  </p>
                </div>}
                {listData?.type && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Type</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.type || "Info"}
                  </p>
                </div>}
                {listData?.imo_number && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">IMO Number</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.imo_number || "Info"}
                  </p>
                </div>}
                {listData?.deadweight_tonnage && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Dead Weight Tonnage</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.deadweight_tonnage || "Info"}
                  </p>
                </div>}
                {listData?.vessel_size && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Vessel Size</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.vessel_size || "Info"}
                  </p>
                </div>}
                {listData?.status && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">status</p>
                  <p className={`text-sm font-medium capitalize  capitalize px-[12px] py-[2px] w-fit h-fit rounded-[12px] ${listData?.status === "active" ? "bg-[#E7FFF2] text-brandBackground " : "bg-[#ffe7ec] text-red-600"}`}>
                    {listData?.status }
                  </p>
                </div>}
                {listData?.quantity && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Quantity</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.quantity || "Info"}
                  </p>
                </div>}
                {listData?.dm_grace_period && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">DM Grace Period</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.dm_grace_period || "Info"}
                  </p>
                </div>}
                {listData?.location && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Location</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.location || "Info"}
                  </p>
                </div>}
                {listData?.country && <div  className="flex justify-between items-start">
                  <p className="text-sm uppercase font-medium text-gray-500">Country</p>
                  <p className="text-sm font-medium capitalize text-gray-900">
                    {listData?.country || "Info"}
                  </p>
                </div>}
            </div>

            {/* Section Headers */}
           
          </DialogDescription>

          
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ListManagementGeneralDetailModal;
