import React, { useEffect, useState } from "react";
import AddStaff from "./AddStaff";
import StaffDetails from "./StaffDetails";
import GreenBtn from "../../../generalComponents/GreenBtn";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import { download, plusCircle } from "../../../assets/icons";
import useDebounce from "../../../utils/UseDebounce";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";

import useGetStaffsManager from "../controllers/getStaffsController.js";

import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/formatDate";
import Loader from "../../../generalComponents/Loader";
import FilterDrawer from "./FilterDrawer";
import RoleManagement from "../../roleManagement/view/RoleManagement";
import SearchField from "../../../generalComponents/SearchField.jsx";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination.jsx";
import FilterButton from "../../../generalComponents/FilterButton.jsx";
import { CustomTabs } from "../../../generalComponents/CustomTabs.jsx";

const headers = ["Staff Name", "Email", "Role", "Date", "Action"];

const StaffManagement = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewStaffModalOpen, setIsViewStaffModalOpen] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedStaffId, setSelectedStaffId] = useState(null); // Add this state

  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    selectedMonth: "",
    roles: [],
  });

  const searchQuery = [
    debouncedSearchValue,
    filter.startDate && `startDay=${filter.startDate}`,
    filter.endDate && `endDay=${filter.endDate}`,
    filter.selectedMonth && `month=${filter.selectedMonth}`,
    filter.roles.length > 0 && `roles=${filter.roles.join(",")}`,
  ]
    .filter(Boolean)
    .join("&");

  const { data, isLoading } = useGetStaffsManager({
    enabled: true,
    searchQuery: searchQuery,
    page: currentPage,
  });

  // useEffect(() => {
  //   setSelectedStaff(true);
  // }, []);

  // Update filter criteria and refetch data
  const handleFilterUpdate = (newFilter) => {
    setFilter(newFilter);
  };

  const getFormattedValue = (el, index) => {
    const fullName =
      `${el?.user?.first_name ?? ""} ${el?.user?.last_name ?? ""}`.trim() ||
      "No name";

    // Display the role IDs directly
    const formattedRoles = el?.user?.roles?.map((roleId) => roleId) || [];

    return [
      <div key={index} className="flex items-center gap-5">
        <InitialsAvatar name={fullName} /> <p>{fullName}</p>
      </div>,

      el?.user?.email,
      formattedRoles.length > 0 ? (
        <div className="flex flex-wrap gap-2">
          {formattedRoles.map((roleId, index) => (
            <span
              key={roleId + index}
              className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-brandBackground"
            >
              {roleId}
            </span>
          ))}
        </div>
      ) : (
        "No roles assigned"
      ),
      formatDate(el?.user?.created_datetime ?? ""),
    ];
  };

  const openIsEditingStaffModalHandler = () => {
    setIsViewStaffModalOpen(false);
    setIsModalOpen(true);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const openStaffModal = (staffId) => {
    setSelectedStaffId(staffId);
    setIsViewStaffModalOpen(true);
    navigate(`?id=${staffId}`);
  };

  const closeStaffModal = () => {
    setSelectedStaffId(null);
    setIsViewStaffModalOpen(false);
    navigate(""); // Clear the URL params
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      {/* Tabs Section */}
      <CustomTabs
        tabs={["Staffs", "Role Management"]}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
      />

      {selectedTab === 0 && (
        <>
          <div className="flex items-center justify-between mb-6">
            <h1 className="font-[600] text-[24px] mb-2 w-full">
              Staff Management
            </h1>

            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} icon={download} />
              <GreenBtn
                onClick={handleClick}
                icon={plusCircle}
                text={"Add Staff"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
            <div className="flex items-center space-x-2 justify-start pt-3 px-4">
              <SearchField
                placeholder={"Search here..."}
                searchValue={searchValue}
                searchOnchange={(e) => setSearchValue(e.target.value)}
              />
              <FilterButton onClick={() => setIsFilterDrawerOpen(true)} />
            </div>
            <div className="h-[67vh] w-full relative">
              {isLoading ? (
                <Loader />
              ) : (
                <TablesComponent
                  isLoading={isLoading}
                  data={data?.data?.results}
                  getFormattedValue={getFormattedValue}
                  headers={headers}
                  showCheckBox={false}
                  buttonFunction={(option) => openStaffModal(option.id)} // Modified this line
                />
              )}
            </div>
            {/* Pagination */}
            <NUIMSPagination
              pagination={data?.data?.pagination}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
      )}
      {selectedTab === 1 && <RoleManagement />}

      <AddStaff isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <StaffDetails
        isOpen={isViewStaffModalOpen}
        staffId={selectedStaffId} // Pass the staffId as prop
        openEditModalHandler={openIsEditingStaffModalHandler}
        onClose={closeStaffModal} // Use the new closeStaffModal function
      />

      <FilterDrawer
        isOpen={isFilterDrawerOpen}
        onUpdateFilters={handleFilterUpdate}
        onClose={() => setIsFilterDrawerOpen(false)}
      />
    </div>
  );
};

export default StaffManagement;
