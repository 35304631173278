import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetTicketByIdManager = ({
   
  ticketId = "",
  enabled = true, 
}) => {
  return useQuery(
    ["/supports/ticket/id/", ticketId, enabled],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/support/ticket/${ticketId}/threads`
          ),
        ];
        //${searchQuery}&status=${status}
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { refetchOnWindowFocus: false, enabled: enabled }
  );
};

export default useGetTicketByIdManager;
