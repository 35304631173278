import React, { useEffect, useState } from "react";
import { Loader } from "lucide-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateStaffManager } from "../controllers/createStaffController";
import useGetAllRolesManager from "../../roleManagement/controllers/getRolesController";
import { useSearchParams } from "react-router-dom";
import useGetStaffManager from "../controllers/getStaffController";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { UpdateStaffManager } from "../controllers/updateStaffController";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";

const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const AddStaff = ({ isOpen, onClose }) => {
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get("id");
  const [selectedRoles, setSelectedRoles] = useState(new Set());

  const { data: singleStaffData, isLoading: loadingSingleStaff } =
    useGetStaffManager({ id: staffId, enabled: Boolean(staffId) });

  const { data: rolesData, isLoading: isLoadingRoles } = useGetAllRolesManager({
    enabled: true,
    searchQuery: "",
    page: 1,
  });

  const { createStaff, isLoading: isLoadingStaff } = CreateStaffManager();
  const { updateStaff, isLoading: isUpdatingStaff } = UpdateStaffManager({
    id: staffId,
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const submitData = {
          ...values,
          roles: Array.from(selectedRoles),
        };
        console.log(submitData);
        if (staffId) {
          console.log("Edit Payload:", submitData);
          await updateStaff(staffId, submitData);
          await updateStaff(staffId, submitData);
        } else {
          await createStaff(submitData);
        }

        // Reset form after successful submission
        resetForm();
        setSelectedRoles(new Set());
        onClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Effect to populate form when editing
  useEffect(() => {
    if (staffId && singleStaffData?.data) {
      const staffData = singleStaffData.data;

      // Use setFieldValue instead of setValues to avoid potential infinite loops
      formik.setFieldValue("first_name", staffData.user?.first_name || "");
      formik.setFieldValue("last_name", staffData.user?.last_name || "");
      formik.setFieldValue("email", staffData.user?.email || "");

      // Set selected roles
      const staffRoles = new Set(staffData.roles?.map((role) => role.id) || []);
      setSelectedRoles(staffRoles);
    }
  }, [staffId, singleStaffData]);
  const handleRoleToggle = (roleId) => {
    setSelectedRoles((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(roleId)) {
        newSet.delete(roleId);
      } else {
        newSet.add(roleId);
      }
      return newSet;
    });
  };

  if (loadingSingleStaff) {
    return <Loader />;
  }

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title={staffId ? "Edit Staff" : "Create Staff"}
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputWithFullBoarder
              label="First Name"
              id="first_name"
              name="first_name"
              placeholder="Enter first name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && formik.errors.first_name}
              errorMessage={formik.errors.first_name}
              className={
                formik.touched.first_name && formik.errors.first_name
                  ? "border-red-500"
                  : ""
              }
            />

            <InputWithFullBoarder
              label="Last Name"
              id="last_name"
              name="last_name"
              placeholder="Enter last name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && formik.errors.last_name}
              errorMessage={formik.errors.last_name}
              className={
                formik.touched.last_name && formik.errors.last_name
                  ? "border-red-500"
                  : ""
              }
            />
          </div>

          <div className="mt-4">
            <InputWithFullBoarder
              label="Email Address"
              id="email"
              name="email"
              type="email"
              placeholder="Enter email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
              errorMessage={formik.errors.email}
              className={
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : ""
              }
            />
          </div>

          <div className="mt-4">
            <p className="mb-3 text-[#181918] text-[12px] font-[500]">
              Assign Staff Role
            </p>
            <div className="grid grid-cols-2 gap-3">
              {isLoadingRoles ? (
                <p>Loading roles...</p>
              ) : (
                rolesData?.data?.results.map(({ name, id }) => (
                  <label key={id} className="text-[12px] flex items-center">
                    <input
                      type="checkbox"
                      value={id}
                      checked={selectedRoles.has(id)}
                      onChange={() => handleRoleToggle(id)}
                      className="checkbox checkbox-success checkbox-sm mr-2 border border-[#D0D5DD] rounded-[4px] checked:text-[#ffffff]"
                    />
                    <span className="text-[#4F4F4F] text-[12px]">{name}</span>
                  </label>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoadingStaff || isUpdatingStaff}
            buttonText={staffId ? "Edit Staff" : "Create Staff"}
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddStaff;
