import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CreateRoleManager } from "../controllers/createRoleController";
import { UpdateRoleManager } from "../controllers/updateRoleController";
import useGetSingleRoleManager from "../controllers/getRoleController";
import * as Yup from "yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PermissionSection } from "../../../generalComponents/PermissionSection";
import { BaseModal } from "../../../generalComponents/BaseModal";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import Loader from "../../../generalComponents/Loader";
import { DeleteRoleManager } from "../controllers/deleteRoleController";
import { DeleteModal } from "../../../generalComponents/ConfirmationModals";
import GoBackButton from "../../../generalComponents/GoBackButton";

export default function CreateStaffRolePage() {
  const { roleId } = useParams();
  const navigate = useNavigate();

  const isEditMode = Boolean(roleId); // Determine if we're in edit mode based on roleId
  const { deleteRole, isLoading: isDeletingRole } = DeleteRoleManager({
    id: roleId,
  });
  const { createRole, isLoading: isCreatingRole } = CreateRoleManager();
  const { updateRole, isLoading: isUpdatingRole } = UpdateRoleManager({
    id: roleId,
  });
  const {
    data: singleRoleData,
    isLoading: loadingRoleClient,
    isSuccess: loadedRole,
  } = useGetSingleRoleManager({ id: roleId, enabled: isEditMode });

  const [permissions, setPermissions] = useState([]);

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object({
      name: Yup.string().required("Role name is required"),
    }),
    onSubmit: async (values) => {
      const submitData = { name: values.name, permissions: permissions };
      if (isEditMode) {
        await updateRole(submitData);
      } else {
        await createRole(submitData);
      }
      navigate("/staff");
    },
  });

  useEffect(() => {
    if (isEditMode && loadedRole && singleRoleData?.data) {
      formik.setValues({ name: singleRoleData.data.name || "" });
      if (singleRoleData.data.permissions) {
        const allPermissionIds = singleRoleData.data.permissions.reduce(
          (acc, module) => {
            const modulePermissionIds = module.permissions.map(
              (perm) => perm.id
            );
            return [...acc, ...modulePermissionIds];
          },
          []
        );
        setPermissions(allPermissionIds);
      }
    }
  }, [isEditMode, loadedRole, singleRoleData]);

  return (
    <div className="container bg-white mx-auto p-6">
      <div className="flex items-center justify-start">
        <GoBackButton onClick={onclick} />
        <h1 className="text-2xl font-bold mb-4">
          {isEditMode ? "Update Role" : "Create Role"}
        </h1>
      </div>
      {loadingRoleClient ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <InputWithFullBoarder
            label="Role Name"
            id="name"
            name="name"
            placeholder="Enter name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <PermissionSection
            permissions={permissions}
            setPermissions={setPermissions}
            title="Role Permissions"
            description="Mark permissions to operators"
          />
          <div className="flex items-center w-full space-x-2">
            <CustomButton
              buttonText={isEditMode ? "Edit Role" : "Create Role"}
              type="submit"
              className="w-full"
              isLoading={isCreatingRole || isUpdatingRole}
            />
            {isEditMode && (
              <CustomButton
                buttonText="Delete Role"
                buttonColor="bg-red-100"
                textColor="text-red-500"
                className="w-full"
                type="button"
                onClick={() =>
                  document.getElementById("delete_modal").showModal()
                }
              />
            )}
          </div>
        </form>
      )}
      <DeleteModal
        title="Delete Role"
        itemName={formik.values.name}
        isLoading={isDeletingRole}
        onDelete={async () => {
          await deleteRole();
          navigate("/staff");
        }}
      />
    </div>
  );
}
