import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";

export const DeleteStaffManager = ({ id }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/clients/staffs/${id}/`,
    ["staffs"]
  );
  const deleteStaff = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteStaff,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
