import { ListFilterIcon } from "lucide-react";
import React from "react";

const FilterButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="flex items-center space-x-3 rounded-md bg-white px-3 py-2 text-sm font-[500] ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      <ListFilterIcon />
      <span>Filter</span>
    </button>
  );
};

export default FilterButton;
