export default function StatsCard ({details, wrapperClass}){

    return (
        <div className={`w-full h-fit flex flex-col gap-[15px] ${wrapperClass}`}> 
                    <h3 className="font-semibold text-[24px] text-black ">{details?.title} </h3>
                    <div className="rounded-[9px] border flex flex-col border-[#E5E7EB]"> 
                        <div className="bg-[#00AD511A] rounded-t-[9px] w-full h-[83px] px-[23px] flex justify-between items-center"> 
                            <div className="w-fit flex h-fit flex-col"> 
                                <h3 className="text-[16px] font-medium text-[#828282]">
                                            {details?.option1}
                                </h3>
                                <h3 className="text-[27px] font-medium text-[#00AD51]">
                                    {details?.value1}
                                </h3>
                            </div>
                            <div className="w-fit flex h-fit flex-col items-end"> 
                                <h3 className="text-[16px] font-medium text-[#828282]">
                                {details?.option2}
                                </h3>
                                <h3 className="text-[27px] font-medium text-[#00AD51]">
                                {details?.value2}
                                </h3>
                            </div>
                        </div>
                        <div className="w-full h-[342px]"> 

                        </div>
                    </div>
                </div>
    )
}