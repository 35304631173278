// getAGGStationsController.js
import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetAGGStationsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  operator = "",
  pageSize = "20",
}) => {
  return useQuery(
    ["agg-stations", page, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/agg-stations?page=${page}&size=${pageSize}${searchQuery}${operator}`
          ),
        ];
        return response.data;
      } catch (error) {
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { refetchOnWindowFocus: false, enabled: enabled }
  );
};

export default useGetAGGStationsManager;
