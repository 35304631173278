// PermissionSection.jsx
import React, { useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "lucide-react";
import { formatUnderscoreString } from "../utils/convertWords";
import Toggle from "../modules/operators/component/Toggle";
import Loader from "./Loader";
import useGetPermissionsManager from "../modules/auth/controllers/getPermissionsController";

export const PermissionSection = ({
  title = "Assign Client's Permission",
  description = "Mark permissions to operators",
  permissions,
  nonEdit = false,
  setPermissions,
}) => {
  const { data, isLoading } = useGetPermissionsManager({ enabled: true });
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleCategoryClick = (index) => {
    setExpandedCategory(index === expandedCategory ? null : index);
  };

  const handleCategoryToggle = (category) => {
    const categoryPermissionIds = category.permissions.map((perm) => perm.id);
    if (!nonEdit) {
      if (isCategoryFullyChecked(category)) {
        setPermissions((prev) =>
          prev.filter((id) => !categoryPermissionIds.includes(id))
        );
      } else {
        setPermissions((prev) => {
          const newPermissions = [...prev];
          categoryPermissionIds.forEach((id) => {
            if (!newPermissions.includes(id)) {
              newPermissions.push(id);
            }
          });
          return newPermissions;
        });
      }
    }
  };

  const handlePermissionToggle = (permissionId) => {
    if (!nonEdit) {
      setPermissions((prev) => {
        if (prev.includes(permissionId)) {
          return prev.filter((id) => id !== permissionId);
        } else {
          return [...prev, permissionId];
        }
      });
    }
  };

  const isCategoryFullyChecked = (category) => {
    return category?.permissions?.every((perm) => permissions?.includes(perm?.id));
  };

  const isCategoryPartiallyChecked = (category) => {
    return (
      category?.permissions?.some((perm) => permissions?.includes(perm?.id)) &&
      !isCategoryFullyChecked(category)
    );
  };

  // Filter categories based on `nonEdit` status
  const filteredData = nonEdit
    ? data?.data.map((category) => ({
        ...category,
        permissions: category.permissions.filter((perm) =>
          permissions?.includes(perm.id)
        ),
      })).filter((category) => category.permissions.length > 0) // Exclude categories with no permissions
    : data?.data;

  return (
    <div className={`mb-4 mt-10 ${nonEdit && "w-full"}`}>
      <h3 className="text-[24px] font-[600] leading-[28px]">{title}</h3>
      <p className="text-[13px] text-[#667185] mb-4">{description}</p>

      {isLoading ? (
        <Loader />
      ) : (
        filteredData &&
        filteredData.map((category, index) => (
          <div
            key={index}
            className={`mb-2 ${
              index !== expandedCategory ? "border-b" : ""
            } pb-2`}
          >
            <div className="flex items-center justify-between cursor-pointer">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="checkbox checkbox-success checkbox-sm mr-2 border border-[#D0D5DD] rounded-[4px] checked:text-[#ffffff]"
                  checked={isCategoryFullyChecked(category)}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate =
                        isCategoryPartiallyChecked(category);
                    }
                  }}
                  onChange={() => handleCategoryToggle(category)}
                />
                <span className="text-sm font-medium">
                  {formatUnderscoreString(category.module)}
                </span>
              </div>
              <button onClick={() => handleCategoryClick(index)}>
                {expandedCategory === index ? (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            {expandedCategory === index && (
              <div className="ml-6 mt-2">
                <div className="grid grid-cols-2 gap-4 p-2 relative">
                  {category.permissions.map((permission, subIndex) => (
                    <div
                      key={subIndex}
                      className="flex items-center justify-between"
                    >
                      <span className="text-[13px] text-[#828282]">
                        {permission?.name}
                      </span>
                      <Toggle
                        labelText=""
                        checked={permissions.includes(permission.id)}
                        onToggle={() => handlePermissionToggle(permission.id)}
                      />
                    </div>
                  ))}
                  <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-200"></div>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

