import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling
import FIrstLogin from "./pages/FirstLogin";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SetNewPasswordForm from "./pages/SetNewPassword";
import Dashboard from "./pages/Dashboard";
import Layout from "./layout/Layout";
import SupportPage from "./modules/support/views/Support";
import LoginForm from "./modules/auth/view/Login";
import OTPForm from "./modules/auth/view/OTP";
import ForgotPassword from "./modules/auth/view/ForgotPassword";
import ResetPassWord from "./modules/auth/view/ResetPassword";
import ClientManagement from "./modules/clientManagement/view/ClientManagement";
import StaffManagement from "./modules/staffManagement/view/StaffManagement";
import ProjectManagement from "./modules/projectManagement/view/ProjectManagement";
import Lifting from "./modules/lifting/view/Lifting";
import Oml from "./modules/omls/views/Oml";
import OperatorDetail from "./modules/operators/component/OperatorDetail";
import AssetsDetail from "./modules/assetGroupManagement/components/AssetsDetail";
import AssetGroupsPage from "./modules/assetGroupManagement/view/AssetGroupsPage";
import OperatorsPage from "./modules/operators/view/OperatorsPage";
import Hydrocarbon from "./modules/hydroCarbon/view/Hydrocarbon";
import ExplorationDetails from "./modules/projectManagement/view/explorationDetails/page";
import OperationDetail from "./modules/projectManagement/view/operationDetails/page"; 
import RoleDetailsPage from "./modules/roleManagement/view/RoleDetail";
import CreateStaffRolePage from "./modules/roleManagement/view/CreateStaffRole";
import EditClient from "./modules/clientManagement/view/ClientEdit";
import ClientAdd from "./modules/clientManagement/view/ClientAdd";  

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/start",
    element: <FIrstLogin />,
  },
  {
    path: "/set-password",
    element: <SetNewPasswordForm />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassWord />,
  },
  {
    path: "/otp",
    element: <OTPForm />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "staff", element: <StaffManagement /> },
      { path: "staff/create-role", element: <CreateStaffRolePage /> },
      { path: "staff/edit-role/:roleId", element: <CreateStaffRolePage /> }, // Edit role page
      { path: "staff/details/:roleId", element: <RoleDetailsPage /> },
      { path: "client", element: <ClientManagement /> },
      { path: "operators", element: <OperatorsPage /> },
      { path: "operators/:name", element: <OperatorDetail /> },
      { path: "omls", element: <Oml /> },
      { path: "assets_groups", element: <AssetGroupsPage /> },
      { path: "assets/:id", element: <AssetsDetail /> },
      { path: "client/add", element: <ClientAdd /> },
      { path: "client/edit/:id", element: <EditClient /> },
      { path: "hydrocarbon-accounting", element: <Hydrocarbon /> },
      { path: "project", element: <ProjectManagement /> },
      { path: "project/exploration/:id", element: <ExplorationDetails /> },
      { path: "project/operation/:id", element: <OperationDetail /> },
      { path: "lifting", element: <Lifting /> },
      { path: "support", element: <SupportPage /> },
    ],
  },
]);
function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
