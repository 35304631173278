import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader2, X } from "lucide-react";
import { CreateLiftingsManager } from "../../modules/lifting/controllers/createLiftingsController";
import useGetSingleClient from "../../modules/lifting/controllers/getClientController";
import CalendarPicker from "../CalendarPicker";
import AddComponent from "../AddComponent";
const validationSchema = Yup.object({
  laycan_start_date: Yup.date().required("Start date is required"),
  laycan_end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("laycan_start_date"), "End date must be after start date"),
  client: Yup.string().required("Client is required"),
  off_loading_port: Yup.string().required("Off loading port is required"),
  export_vessel: Yup.string().required("Export vessel is required"),
  product: Yup.string().required("Product is required"),
  vessel_agent: Yup.string().required("Vessel agent is required"),
  client_contract: Yup.string().required("Client contract is required"),
  cargo_owner: Yup.string().required("Cargo owner is required"),
  vessel_size: Yup.string().required("Vessel size is required"),
  departure_vessel_draft: Yup.string().required(
    "Departure vessel draft is required"
  ),
  remarks: Yup.string(), 
  actual_time_of_departure: Yup.string().required("Actual Time of Departure is required"),
  notification_of_readiness: Yup.string().required("Notification of readiness is required"),
  departure_quantity: Yup.string().required("Departure Quantity is required"),
  laytime: Yup.string().required("Laytime is required"),
  expected_time_of_departure: Yup.string().required("Expected time of departure is required"),
  bl_date: Yup.date().required("BL Date is required"),
  bl_quantity_ltrs: Yup.string().required("BL Quantity (Ltrs) is required"),
  bl_quantity_mt: Yup.string().required("BL Qauntity MT is required")
});

const AddLiftings = ({ open, onClose }) => {
  const { createLiftings, isLoading, error } = CreateLiftingsManager();
  const { data: clientsData, isLoading: isClientsLoading } = useGetSingleClient(
    { enabled: true }
  );
  console.log(clientsData);
  const formik = useFormik({
    initialValues: {
      laycan_start_date: "",
      laycan_end_date: "",
      client: "",
      off_loading_port: "",
      export_vessel: "",
      product: "",
      vessel_agent: "",
      client_contract: "",
      cargo_owner: "",
      vessel_size: "",
      departure_vessel_draft: "",
      remarks: "",
      actual_time_of_departure: "",
      notification_of_readiness: "",
      departure_quantity: "",
      laytime: "",
      expected_time_of_departure: "",
      bl_date: "",
      bl_quantity_ltrs: "",
      bl_quantity_mt: ""

    },
    validationSchema,

    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        laycan_start_date: values.laycan_start_date,
        laycan_end_date: values.laycan_end_date,
      };
      console.log(formik.values,formattedValues)
      /* await createLiftings(formattedValues); */
      if (!error) {
        onClose();
        formik.resetForm();
      }
    },
  });

  return open ? (
    <div  className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6  w-full rounded-[10px] h-full overflow-y-auto">
        <div
          onClick={(e) => e.target === e.currentTarget && onClose()}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 overflow-y-auto scrollbar-hide max-h-[80%]">
            
            <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold ">Add Lifting</h2>
                <button
                  onClick={() => {onClose() }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            <form onSubmit={formik.handleSubmit} className="space-y-6">
               <AddComponent  formik={formik} data={[
                  { name: "duration", label: "Laycan Start and End Dates", span: "1" ,type: "time", placeholder: ""},
                  { name: "product", label: "Product", span: "1" ,type: "select", placeholder: ""},
                  { name: "client", label: "Client", span: "1" ,type: "select", placeholder: "",options: clientsData || [] },
                  { name: "client_contract", label: "Client Contract", span: "1" ,type: "select", placeholder: ""},
                  { name: "cargo_owner", label: "Cargo Owner", span: "1" ,type: "select", placeholder: "" }, 
                  { name: "export_vessel", label: "Export Vessel", span: "1" ,type: "select", placeholder: "" },
                  { name: "vessel_agent", label: "Vessel Agent", span: "1" ,type: "select", placeholder: "" },
                  { name: "off_loading_port", label: "Off Loading Port", span: "1" ,type: "select", placeholder: "" },
                  { name: "departure_vessel_draft", label: "Departure Vessel Draft", span: "1" ,type: "select", placeholder: ""},  
                  { name: "vessel_size", label: "Vessel Size", type: "text", span: "1",placeholder:"Enter size" },
                  
                ]} start_date={"laycan_start_date"} end_date={"laycan_end_date"} />

               

              <h2 className="text-xl font-bold mb-4">Bill of loading Details</h2>

               <AddComponent formik={formik} data={[
                    { name: "bl_quantity_mt", label: "BL Quantity (MT)", type: "text", span: "1",placeholder: "Enter MT" },
                    { name: "bl_quantity_ltrs", label: "BL Quantity (Ltrs)", type: "text", span: "1",placeholder: "Enter Ltrs" },
                    { name: "bl_date", label: "BL Date", type: "date", span: "1",placeholder: "" }, 
                    { name: "expected_time_of_departure", label: "Expected Time of Departure (ETA)", type: "datetime-local", span: "1",placeholder: "" },
                    { name: "laytime", label: "Laytime", type: "text", span: "1",placeholder: "Enter laytime" }, 
                    
                  ]} />

              <h2 className="text-xl font-bold mb-4">On Site Information</h2>

               <AddComponent formik={formik} data={[
                    { name: "actual_time_of_departure", label: "Actual time of Departure (ATA)", type: "datetime-local", span: "1",placeholder: "Enter MT" },
                    { name: "notification_of_readiness", label: "Notification of Readiness", type: "text", span: "1",placeholder: "Enter Ltrs" },
                    { name: "departure_quantity", label: "Departure Quantity", type: "text", span: "1",placeholder: "Enter departure quantity" },  
                    
                  ]} />
              {/* Action Buttons */}
              <div className="flex justify-end space-x-4">
                
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`px-4 py-2 w-full rounded-[40px] flex items-center justify-center ${
                    isLoading ? "bg-gray-400" : "bg-brandGreen text-white"
                  } flex items-center`}
                >
                  {isLoading && <Loader2 className="animate-spin mr-2" />}
                  {isLoading ? "Adding..." : "Add Lifting"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddLiftings;
