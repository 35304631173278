import React, { useState } from "react";
// import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
// import StaffTable from "./staffManagement/StaffTable";
// import ClientTable from "../clientMangement/ClientTable";
import AddOilFieldModal from "../../../generalComponents/project-management/AddOIlField";
import ProjectsTab from "../../../generalComponents/project-management/ProjectsTab";

const ProjectManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="font-[600] text-[24px] mb-2 w-full">
            Project Management
          </h1>
        </div>
      </div>
      <div className="flex flex-col gap-4 bg-[#ffffff] ">
        <ProjectsTab />
        {/* <ClientTable /> */}
      </div>
      <AddOilFieldModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default ProjectManagement;
