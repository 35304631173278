import React, { useEffect } from "react";
import { useFormik } from "formik";
import { FormLabel, FormInput, FormSelect } from "./FormComponents";
import { INITIAL_VALUES, OML_CLASSES } from "./constants";
import { validationSchema } from "./schema";
import useGetAllClientsManager from "../../clientManagement/controllers/getClientsController";

const OMLForm = ({ onClose, createOmls, updateOMLs,omlId,omlData,isLoading, isSuccess }) => {
  const { data: clientsData, isLoading: isClientsLoading } =
    useGetAllClientsManager({
      page: 1,
      searchQuery: "",
      enabled: true,
    });

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("Submitting values:", JSON.stringify(values, null, 2));
      try {
        const formattedValues = {
          ...values,
          oml_conversion_date: values.oml_conversion_date || null,
          renewal_date: values.renewal_date || null,
          application_date: values.application_date || null,
          opl_award_date: values.opl_award_date || null,
        };

       if(omlId && omlData?.data){
        await updateOMLs(formattedValues);
       }else{ await createOmls(formattedValues);}
      } catch (error) {
        console.error("Form submission error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (omlId && omlData?.data) {
      
      
      // Set form values
      formik.setValues({
        number: omlData?.data?.number || "",
        oml_class: omlData?.data?.oml_class.toLowerCase() || "",
        lng: omlData?.data?.lng || 0,
        lat: omlData?.data?.lat || 0,
        opl_right_type: omlData?.data?.opl_right_type || "",
        block_validity: omlData?.data?.block_validity || "",
        resource_type: omlData?.data?.resource_type || "",
        contract_type: omlData?.data?.contract_type || "",
        renewal_date: omlData?.data?.renewal_date || new Date().toISOString().split("T")[0],
        oml_conversion_date: omlData?.data?.oml_conversion_date || new Date().toISOString().split("T")[0],
        client: omlData?.data?.client?.id || "",
        area: omlData?.data?.area || "",
        basil_name: omlData?.data?.basil_name || "",
        opl: omlData?.data?.opl || "",
        application_date: omlData?.data?.application_date || new Date().toISOString().split("T")[0],
        opl_award_date: omlData?.data?.opl_award_date || new Date().toISOString().split("T")[0],
        opl_block_size: omlData?.data?.opl_block_size || 0,
        onshore_size: omlData?.data?.onshore_size || 0,
        shelf_size: omlData?.data?.shelf_size || 0,
        deep_water_size: omlData?.data?.deep_water_size || 0,
        min_water_depth: omlData?.data?.min_water_depth || 0,
        max_water_depth: omlData?.data?.max_water_depth || 0,
        median_water_depth: omlData?.data?.median_water_depth || 0,
        contract_status: omlData?.data?.contract_status === "revoked" ? "inactive" : omlData?.data?.contract_status || "active",
        x: omlData?.data?.x || 0,
        y: omlData?.data?.y || 0,
        lng_summary: omlData?.data?.lng_summary || 0,
        lat_summary: omlData?.data?.lat_summary || 0,
      });
    }
  }, [omlId, omlData]);
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Client Dropdown */}
        <div className="space-y-1 col-span-2">
          <FormLabel>Client</FormLabel>
          <FormSelect
            name="client"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.client}
            options={[
              { value: "", label: "Select Client" },
              ...(clientsData?.data?.results?.map((client) => ({
                value: client.id,
                label: client.name,
              })) || []),
            ]}
            disabled={isClientsLoading}
          />
          {formik.touched.client && formik.errors.client && (
            <div className="text-red-500 text-sm">{formik.errors.client}</div>
          )}
        </div>

        {/* OML Number & Area Row */}
        <div className="space-y-1">
          <FormLabel>OML Number</FormLabel>
          <FormInput
            name="number"
            placeholder="Enter OML Number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.number}
          />
          {formik.touched.number && formik.errors.number && (
            <div className="text-red-500 text-sm">{formik.errors.number}</div>
          )}
        </div>

        <div className="space-y-1">
          <FormLabel>Area</FormLabel>
          <FormInput
            name="area"
            placeholder="Enter area"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.area}
          />
          {formik.touched.area && formik.errors.area && (
            <div className="text-red-500 text-sm">{formik.errors.area}</div>
          )}
        </div>

        {/* State & Basin Name Row */}

        <div className="space-y-1">
          <FormLabel>Basin Name</FormLabel>
          <FormInput
            name="basil_name"
            placeholder="Enter name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.basil_name}
          />
          {formik.touched.basil_name && formik.errors.basil_name && (
            <div className="text-red-500 text-sm">
              {formik.errors.basil_name}
            </div>
          )}
        </div>

        {/* Dates Section */}
        <div className="col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="space-y-1">
              <FormLabel>Application Date</FormLabel>
              <FormInput
                type="date"
                name="application_date"
                onChange={formik.handleChange}
                value={formik.values.application_date}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>OPL Award Date</FormLabel>
              <FormInput
                type="date"
                name="opl_award_date"
                onChange={formik.handleChange}
                value={formik.values.opl_award_date}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Renewal Date</FormLabel>
              <FormInput
                type="date"
                name="renewal_date"
                onChange={formik.handleChange}
                value={formik.values.renewal_date}
              />
            </div>
          </div>
        </div>

        {/* Sizes Grid */}
        <div className="col-span-2">
          <div className="grid grid-cols-4 gap-4">
            <div className="space-y-1">
              <FormLabel>OPL</FormLabel>
              <FormInput
                name="opl"
                placeholder="Enter opl"
                onChange={formik.handleChange}
                value={formik.values.opl}
              />
            </div>
            <div className="space-y-1">
              <FormLabel>OPL Block Size</FormLabel>
              <FormInput
                type="number"
                name="opl_block_size"
                placeholder="Enter size"
                onChange={formik.handleChange}
                value={formik.values.opl_block_size}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Onshore Size</FormLabel>
              <FormInput
                type="number"
                name="onshore_size"
                placeholder="Enter size"
                onChange={formik.handleChange}
                value={formik.values.onshore_size}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Shelf Size</FormLabel>
              <FormInput
                type="number"
                name="shelf_size"
                placeholder="Enter size"
                onChange={formik.handleChange}
                value={formik.values.shelf_size}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Deep Water Size</FormLabel>
              <FormInput
                type="number"
                name="deep_water_size"
                placeholder="Enter size"
                onChange={formik.handleChange}
                value={formik.values.deep_water_size}
              />
            </div>
          </div>
        </div>

        {/* OML Class Radio Buttons */}
        <div className="col-span-2">
          <FormLabel>OML Class</FormLabel>
          <div className="flex space-x-6 mt-2">
            {OML_CLASSES.map((value) => (
              <label key={value} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="oml_class"
                  value={value}
                  checked={formik.values.oml_class === value}
                  onChange={formik.handleChange}
                  className="form-radio text-green-600"
                />
                <span>{value}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Water Depth Fields */}
        <div className="grid grid-cols-3 gap-4 col-span-2">
          <div className="space-y-1">
            <FormLabel>Min Water Depth</FormLabel>
            <FormInput
              type="number"
              name="min_water_depth"
              placeholder="Enter depth"
              onChange={formik.handleChange}
              value={formik.values.min_water_depth}
            />
          </div>

          <div className="space-y-1">
            <FormLabel>Max Water Depth</FormLabel>
            <FormInput
              type="number"
              name="max_water_depth"
              placeholder="Enter depth"
              onChange={formik.handleChange}
              value={formik.values.max_water_depth}
            />
          </div>

          <div className="space-y-1">
            <FormLabel>Med Water Depth</FormLabel>
            <FormInput
              type="number"
              name="median_water_depth"
              placeholder="Enter depth"
              onChange={formik.handleChange}
              value={formik.values.median_water_depth}
            />
          </div>
        </div>

        {/* Coordinates */}
        <div className="space-y-1">
          <FormLabel>Latitude</FormLabel>
          <FormInput
            type="number"
            name="lat"
            placeholder="Enter latitude"
            onChange={formik.handleChange}
            value={formik.values.lat}
          />
        </div>

        <div className="space-y-1">
          <FormLabel>Longitude</FormLabel>
          <FormInput
            type="number"
            name="lng"
            placeholder="Enter longitude"
            onChange={formik.handleChange}
            value={formik.values.lng}
          />
        </div>

        {/* Classification Section */}
        <div className="space-y-4 col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* <div className="space-y-1">
              <FormLabel>OML Class</FormLabel>
              <FormSelect
                name="class"
                onChange={formik.handleChange}
                value={formik.values.class}
                options={[
                  { value: "unitized", label: "Unitized" },
                  { value: "productive", label: "Productive" },
                  { value: "non-productive", label: "Non-Productive" },
                  { value: "open", label: "Open" },
                ]}
              />
            </div> */}

            <div className="space-y-1">
              <FormLabel>Contract Type</FormLabel>
              <FormSelect
                name="contract_type"
                onChange={formik.handleChange}
                value={formik.values.contract_type}
                options={[
                  { value: "JV", label: "JV" },
                  { value: "PSC", label: "PSC" },
                  { value: "SC", label: "SC" },
                ]}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Contract Status</FormLabel>
              <FormSelect
                name="contract_status"
                onChange={formik.handleChange}
                value={formik.values.contract_status}
                options={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Resource Type</FormLabel>
              <FormSelect
                name="resource_type"
                onChange={formik.handleChange}
                value={formik.values.resource_type}
                options={[
                  { value: "conventional", label: "Conventional" },
                  { value: "unconventional", label: "Unconventional" },
                ]}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>Block Validity</FormLabel>
              <FormSelect
                name="block_validity"
                onChange={formik.handleChange}
                value={formik.values.block_validity}
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
              />
            </div>

            <div className="space-y-1">
              <FormLabel>OPL Right Type</FormLabel>
              <FormSelect
                name="opl_right_type"
                onChange={formik.handleChange}
                value={formik.values.opl_right_type}
                options={[
                  { value: "exploration", label: "Exploration" },
                  { value: "production", label: "Production" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Coordinates Section */}
      <div className="grid grid-cols-4 gap-4">
        <div className="space-y-1">
          <FormLabel>X Coordinate</FormLabel>
          <FormInput
            type="number"
            name="x"
            placeholder="Enter X coordinate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.x}
          />
          {formik.touched.x && formik.errors.x && (
            <div className="text-red-500 text-sm">{formik.errors.x}</div>
          )}
        </div>

        <div className="space-y-1">
          <FormLabel>Y Coordinate</FormLabel>
          <FormInput
            type="number"
            name="y"
            placeholder="Enter Y coordinate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.y}
          />
          {formik.touched.y && formik.errors.y && (
            <div className="text-red-500 text-sm">{formik.errors.y}</div>
          )}
        </div>
        <div className="space-y-1">
          <FormLabel>Lng Summary</FormLabel>
          <FormInput
            type="number"
            name="lng_summary"
            placeholder="Enter LNG Sumarry"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lng_summary}
          />
          {formik.touched.lng_summary && formik.errors.lng_summary && (
            <div className="text-red-500 text-sm">
              {formik.errors.lng_summary}
            </div>
          )}
        </div>

        <div className="space-y-1">
          <FormLabel>Lat Summary</FormLabel>
          <FormInput
            type="number"
            name="lat_summary"
            placeholder="Enter Lat Summary"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lat_summary}
          />
          {formik.touched.lat_summary && formik.errors.lat_summary && (
            <div className="text-red-500 text-sm">{formik.errors.lat}</div>
          )}
        </div>
      </div>

      <div className="flex justify-end space-x-4 mt-6">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          disabled={isLoading || isClientsLoading}
        >
          {isLoading ? `${(omlData && omlId) ? "Editing...": "Adding..."}` : (omlId && omlData?.data) ? "Edit OML" : "Add OML"}
        </button>
      </div>
    </form>
  );
};

export default OMLForm;
