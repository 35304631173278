import React, { useEffect, useState } from "react";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle, upload } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import { useCompanyOilProductionUpload, useWellProductionUpload } from "../../hydroCarbon/controllers/uploadController";
import FileUpload from "../../../generalComponents/FileUpload";
import AddCompanyOilProduction from "./AddCompanyOilProduction";
import useGetCompanyOilProductionManager from "../controllers/getCompanyOilProductionController";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import CompanyOilDetails from "../../../generalComponents/hydrocarbons/companyOilDetails";
import DeactivationModal from "../../../generalComponents/client-management/DeactiveClient";
import { DeleteCompanyOilManager } from "../controllers/deleteCompanyOilController";
import ExportToCSV from "../../../utils/downloadInCSV";
const CompanyOilProductionSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOilOpen, setIsOilOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState()
  const [showUploadModal, setShowUploadModal] = useState(false);

  const CompanyOilProductionUploadHook = useCompanyOilProductionUpload();

  const [showDeactivate, setShowDeactive] = useState(false);
   const handleShowDeactivate = () => {
    setShowDeactive(!showDeactivate);
    };
  const handleAddModal = () => {
    setIsOpen(true);
  };
  const handleOilOpenModal = () => {
    setIsOilOpen(!isOilOpen);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleUploadSuccess = (result, file) => {
    console.log("Upload successful", result);

    // refetch();

    handleCloseUploadModal();
  };

  const handleUploadError = (error) => {
    console.error("Upload failed", error);
  };

  const { data, isLoading, refetch } = useGetCompanyOilProductionManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const {deleteCompanyOil, isLoading:deleting, isSuccess: deleted} = DeleteCompanyOilManager({id: selectedId})

  useEffect(() => {
    if(deleted){
      handleShowDeactivate()
      refetch()
    }
  }, [deleted])
  const headers = [
    "Company",
    "Date",
    "Oil",
    "Oil Production",
    "Condensate Blended (bbls)",
    "Condensate Unblended (bbls)",
    "Action",
  ];

  // useEffect(() => {
  //   setSelectedClient(true);
  // }, [selectedClient]);

  const options = ["View Production", "Edit Production", "Delete Production"];

  const getFormattedValue = (el, index) => {
    return [
      <div key={index} className="flex items-center gap-5">
       { el?.company?.photo ? <img src={el?.company?.photo} alt="" className="w-10 h-10 rounded-full" /> : <InitialsAvatar name={el?.company?.name ?? "No name"} />}
        <p>{el?.company?.name ?? "No name"}</p>
      </div>,
      formatDate(el?.date),
      el?.oil,
      el?.oil_production || "N/A",
      el?.condensate_blended || "N/A",
      el?.condensate_unblended || "N/A",
    ];
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-between pt-3 px-4">
            <DropdownandSearch
              options={["Option1", "Option"]}
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} onClick={() => ExportToCSV(data?.data?.results, "COMPANY OIL")} icon={download} />
              <GreenBtn
                onClick={handleOpenUploadModal}
                icon={upload}
                text={"Upload Company Oil"}
              />
              <GreenBtn
                onClick={() => {handleAddModal(); setSelectedId("")}}
                icon={plusCircle}
                text={"Add Company Oil"}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => {
                console.log(option, index, val);
                setSelectedId(val.id)
                if(index === 0){
                  handleOilOpenModal()
                }
                if(index === 1 ){
                  handleAddModal();
                }
                if (index === 2) {
                  handleShowDeactivate()
                }
              }}
              // Close popup function
            />
          </div>

          {/* Pagination */}
          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* <ClientTable /> */}
        </div>
      </>
      <AddCompanyOilProduction isOpen={isOpen} id={selectedId} onClose={handleCloseModal} />
      <FileUpload
        title={"Upload Company Oil Production"}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        uploadHook={CompanyOilProductionUploadHook}
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        additionalData={
          {
            // Any additional metadata you want to send with the upload
            // For example:
            // year: new Date().getFullYear(),
            // source: 'web-upload'
          }
        }
      />
      {showDeactivate && (
        <DeactivationModal
        onClose={handleShowDeactivate} 
        onClick={() => 
           {deleteCompanyOil()}
        }
        isLoading={deleting}
        title={"Delete Production"}
        body={"Are you sure you want to proceed with this action? "}
        actionText={"Delete"}
      />
      )}
      <CompanyOilDetails isOpen={isOilOpen} onClose={handleOilOpenModal} idd={selectedId} />
    </div>
  );
};

export default CompanyOilProductionSection;
