import { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import StatsCard from "../../../../generalComponents/stats/statsCard";
import { download, plusCircle } from "../../../../assets/icons";
import TransparentBtn from "../../../../generalComponents/TransparentButton";
import GreenBtn from "../../../../generalComponents/GreenBtn";
import DropdownandSearch from "../../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../../generalComponents/TablesComponent"; 
import NUIMSPagination from "../../../../generalComponents/NUIMSPagination";
import InitialsAvatar from "../../../../generalComponents/InitialsAvatar";
import { formatDate } from "../../../../utils/formatDate";
import useDebounce from "../../../../utils/UseDebounce";
import CalendarPicker from "../../../../generalComponents/CalendarPicker";
import AddActivity from "../../../../generalComponents/project-management/AddOperationActivity";

import LiftingDetailsModal from "../../../../generalComponents/lifting-management/LiftingDetailsModal";
import ProductDetailsModal from "../../../../generalComponents/project-management/ProductOperationDetailModal";

export default function OperationDetail (){
    const router = useNavigate()
    const [selectedTab, setSelectedTab] = useState(0)
    const [searchValue, setSearchValue] = useState("");
    const [showModal, setShowModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
    
  const openModal = () => setIsModalOpen(true);
  const toggleShowModal = () => {
    setShowModal(!showModal)
}
    const { id } = useParams();
    const data = [
        {
            title: "Exploration Activity",
            action:"Drilling"
        },
        {
            title: "Operator",
            action:"Mobil"
        },
        {
            title: "Contract Type",
            action:"PSC"
        },
        {
            title: "Funding",
            action:"Info"
        },
        {
            title: "OML",
            action:"15er23817y3"
        },
        {
            title: "Field",
            action:"Rutoe234"
        },
        {
            title: "Planed Area",
            action:"Ray Area"
        },
        {
            title: "Actual Area Covered",
            action:"Info"
        },
        {
            title: "Start Date",
            action:"12 Dec 2024"
        },
        {
            title: "Planned Duration",
            action:"3 months"
        },
        {
            title: "Planned Cost",
            action:"Info"
        },

    ]
    const tab = [
        "Analytics",
        "Activity History"
    ]
    const stats = [
        {
            title:"Total Project Coverage",
            option1: "Actual Area Covered",
            option2: "Planed Area",
            value1: "23,567",
            value2: "23,567"
        },
        {
            title:"Budget",
            option1: "Actual Cost",
            option2: "Planed Cost",
            value1: "23,567",
            value2: "23,567"
        },
    ]
    const rigData = [
        {
            title: "Rig Name",
            action:"Info"
        },
        {
            title: "Rig Type",
            action:"Info"
        },
        {
            title: "Well Type",
            action:"Info"
        },
    ]
     const activityHeader = [
        "Date",
        "Activity",
        "Stage",
        "Cost ($)",
        "Coverage (sq.km)",
        "Remarks", 
        "More"
      ];
      
  const getFormattedValue = (el, index) => {
    {
      console.log(el);
    }
    return [
      <div key={index} className="flex items-center gap-5">
        <InitialsAvatar name={el?.name} /> <p>{el?.name}</p>
      </div>,

      el?.email,
      el?.role || "N/A",
      formatDate(el?.created_datetime),
    ];
  };
  
  const options = [
    "See Details", 
    "Edit ",
    "Delete",
  ];
    return (

        <div className="bg-white rounded-[10px] p-6 flex flex-col gap-7 w-full h-full">
            <div className="w-fit font-medium text-[#667185] text-[12px] h-fit flex items-center gap-2">
                <div onClick={() => router("/project")} className="rounded-[4px] cursor-pointer border border-[#E4E7EC] flex items-center justify-center w-fit h-fit p-2">
                    <FaAngleLeft color="#000" size={16} />
                </div>
                Go Back
            </div>
            <div className="w-full h-fit flex flex-col gap-3">
                <h3 className="text-[24px] font-semibold text-black">
                Operation Name
                </h3>
                <div className="w-full h-fit grid grid-cols-3 sm:grid-cols-5 gap-[31px] lg:grid-cols-6  rounded-[10px] border-[#E5E7EB] bg-[#00AD510D] p-6">
                        {data.map((el,l) => (
                            <div key={l} className="border-l-[2px] pl-1 flex flex-col border-[#00AD51]">
                                <h3 className="text-[12px] font-normal text-[#828282]">
                                    {el.title}
                                </h3>
                                <p className="text-[16px] font-semibold text-black">
                                    {el.action}
                                </p>
                            </div>
                        ))}
                </div>
            </div>
            <div className="w-full h-fit grid p-2 grid-cols-2 bg-[#F6F8FC] rounded-[5px] gap-[10px]">
                {
                    tab.map((el,l) => (
                        <div key={l} onClick={() => setSelectedTab(l)} className={`rounded-[3px] cursor-pointer flex items-center justify-center py-[10px] text-[14px] ${selectedTab === l ? "bg-[#00AD51] text-white font-semibold" : "bg-white text-[#828282] font-normal"} `}> 
                            {el}
                        </div>
                    ))
                }            
            </div>
            {selectedTab === 0  && 
            <div className="flex flex-col w-full h-fit gap-7"> 
                 <div className="w-full h-fit grid grid-cols-1 lg:grid-cols-2 gap-[22px]">
                {
                    stats.map((el,l) => (
                        <StatsCard key={l} details={el} /> 
                    ))
                }
            </div>
            <div className="w-full h-fit grid grid-cols-1 lg:grid-cols-3 gap-[22px]"> 
                <StatsCard wrapperClass={"col-span-2"} details={
                   { title: "Drilling Chart",
                     option1: "Actual Depth",
                     value1: "23,567"
                   }
                } />
                <div className="w-full h-full col-span-1 flex flex-col gap-[15px]"> 
                    <h3 className="font-semibold text-[24px] text-black ">Rig Details </h3>
                    <div className="w-full h-full flex flex-col gap-[30px] rounded-[9px] border px-[18px] py-[31px] border-[#E5E7EB]"> 
                        {rigData.map((el,l) => (
                                <div key={l} className="border-l-[2px] pl-1 flex flex-col border-[#00AD51]">
                                    <h3 className="text-[12px] font-normal text-[#828282]">
                                        {el.title}
                                    </h3>
                                    <p className="text-[16px] font-semibold text-black">
                                        {el.action}
                                    </p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            </div>
           }
            {selectedTab === 1 &&
                <div className="flex flex-col px-2"> 
                    <div className="flex items-center justify-between mb-6">
                        <h1 className="font-[600] text-[24px] mb-2 w-full">
                        Activity History
                        </h1>

                        <div className="flex items-center gap-[5px]">
                        <TransparentBtn text={"Export"} icon={download} />
                        <GreenBtn
                        onClick={toggleShowModal}
                            icon={plusCircle}
                            text={`Add Activity`}
                        />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
                        <div className="flex items-center justify-between pt-3 px-4">
                        <DropdownandSearch
                            options={["Option1", "Option"]}
                            searchValue={searchValue}
                            searchOnchange={(e) => setSearchValue(e.target.value)}
                        />
                        <CalendarPicker wrapperClass={"!w-fit h-full"} classN={"!w-fit !h-full !bg-white !border !border-[#D0D5DD]"} />
                        </div>
                        <div className="h-[67vh] w-full relative">
                        <TablesComponent
                            isLoading={false}
                            data={[]}
                            getFormattedValue={getFormattedValue}
                            headers={activityHeader}
                            options={options}
                            showCheckBox={false}
                            popUpFunction={(option, index, val) => {
                            console.log(option, index, val);
                            console.log("hello");
                            openModal();
            /* 
                            if (index === 2) {
                                console.log(val.id);
                                setselectedLiftings(val.id);
                                setshowAddLiftings(true);
                                navigate(`?clientId=${val.id}`);
                            } */
                            }}
                            // Close popup function
                        />
                         <ProductDetailsModal
                            isOpen={isModalOpen}
                            
                            title={"Product Details"}
                            onClose={() => setIsModalOpen(false)}
                            productData={{
                                date: "2023-04-01",
                                activity: "info",
                                cost: "info",
                                stage: "info",
                                coverage: "info",
                                challenges: "info",
                                forward_plan: "info",
                                remarks: "Lorem ipsum dolor sit amet consectetur. Venenatis eu tincidunt porta turpis nibh. In eget tempus nascetur ultrices in metus amet ullamcorper ut. Est donec cursus elementum sagittis mauris id imperdiet mauris. Nullam cras eget commodo eu mattis ut faucibus cras. Amet dictum felis vitae non euismod justo. Proin massa arcu libero orci tellus eget nunc. Laoreet ut a tempus.", 
                              }}
                        /> 
                        </div>

                        <NUIMSPagination type="Pages" pagination={[]} />
                    </div>
                </div>
            }
            <AddActivity onClose={toggleShowModal} open={showModal} />
        </div>
    )
}