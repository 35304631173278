import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "lucide-react";

// Stats Card Component export
export const StatsCard = ({ title, value, className = "" }) => (
  <div className={`bg-green-50 p-6 rounded-lg shadow-sm ${className}`}>
    <h3 className="text-gray-600 text-lg font-medium">{title}</h3>
    <p className="text-green-600 text-4xl font-semibold mt-2">
      {value.toLocaleString()}
    </p>
  </div>
);

// Year Selector Component
export const YearSelector = ({ selected, onChange }) => {
  const years = ["2024", "2023", "2022"];

  return (
    <Listbox value={selected} onChange={onChange}>
      <div className="relative mt-1 w-32">
        <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 text-sm">
          <span className="block truncate">{selected}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
            {years.map((year) => (
              <Listbox.Option
                key={year}
                value={year}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-3 pr-4 ${
                    active ? "bg-green-100 text-green-900" : "text-gray-900"
                  }`
                }
              >
                {year}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

// Card Component
export const Card = ({ children, className = "" }) => (
  <div
    className={`bg-white rounded-lg shadow-sm border border-gray-200 ${className}`}
  >
    {children}
  </div>
);

export const CardHeader = ({ children, className = "" }) => (
  <div className={`px-6 py-4 border-b border-gray-200 ${className}`}>
    {children}
  </div>
);

export const CardContent = ({ children, className = "" }) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

// Gas Production Stats Component
const colorMap = {
  Flared: "#059669",
  Fuel: "#10B981",
  LNG: "#FCD34D",
  EGTL: "#FBBF24",
  "NGL/LPG": "#F59E0B",
  "Dom Sales": "#D97706",
  "Gas reinjection/Gas Lift": "#B45309",
};

export const GasProductionStats = ({ stats }) => (
  <div className="flex flex-col gap-1 items-start p-1">
    {Object.entries(stats).map(([key, value]) => (
      <div
        key={key}
        className="p-3 w-full border-l-4"
        style={{
          borderLeftColor: colorMap[key] || "#gray-300",
        }}
      >
        <p className="text-sm text-gray-600">{key}</p>
        <p className="text-sm font-semibold text-gray-900">
          {value.toLocaleString()}
        </p>
      </div>
    ))}
  </div>
);
