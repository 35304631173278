import React from "react";

export const FormLabel = ({ children }) => (
  <div className="bg-[#f0f7f0] px-2 py-1 text-sm">{children}</div>
);

export const FormInput = ({ name, ...props }) => (
  <input
    name={name}
    {...props}
    className="w-full bg-[#f6f6f9] rounded px-3 py-2"
  />
);

export const FormSelect = ({ field, form, options, ...props }) => (
  <select
    {...field}
    {...props}
    className="w-full bg-[#f6f6f9] rounded px-3 py-2"
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);
