import React from "react";

const OperatorsCard = ({
  title,
  value,
  unit,
  comparison,
  comparisonText,
  icon,
  active, inactive,
  bgColor,
  textColor,
  iconBg,
}) => {
  return (
    <div
      className={`p-4 rounded-[10px] border border-[#E5E7EB] flex flex-col gap-2 ${bgColor}`}
    >
      <div className=" flex items-center justify-between mb-3">
        <h3 className={`${textColor} text-[15px] font-[500]`}>{title}</h3>
        <div
          className={`h-12 w-12 rounded-[10px] ${iconBg} flex items-center justify-center`}
        >
          <img src={icon} alt="icon" className="h-6 w-6" />
        </div>
      </div>
      {active ?   <div className="flex divide-x divide-[#E5E7EB]">
        <div className="flex flex-col pr-10">
            <div className="w-fit h-fit flex items-center gap-1 text-[15px] text-[#667185] font-medium"> <div className="w-[12px] h-[12px] rounded-full bg-[#00AD51]"> </div> Active </div>
            <p className={`text-4xl font-bold text-[#1D2739]`}>
              {active} 
            </p>
        </div>
        <div className="flex flex-col pl-10">
            <div className="w-fit h-fit flex items-center gap-1 text-[15px] text-[#667185] font-medium"> <div className="w-[12px] h-[12px] rounded-full bg-[#EA0000]"> </div> Inactive </div>
            <p className={`text-4xl font-bold text-[#1D2739]`}>
              {inactive} 
            </p>
        </div>

      </div> :<p className={`text-4xl font-bold ${textColor}`}>
        {value} <span className="text-xl">{unit}</span>
      </p>}
      <p className={`text-[12px] font-[500] ${textColor}`}>
        <span
          className={`text-[#AD3307] text-[12px] rounded-[12px]  px-1 bg-[#FFECE5]`}
        >
          {comparison}
        </span>{" "}
        {comparisonText}
      </p>
    </div>
  );
};

export default OperatorsCard;
