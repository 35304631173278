import React from "react";
import ProductionLineChart from "./ProductionLineChart";

const ProductionContainer = () => {
  return (
    <div className="bg-[#FFFFFF] rounded-[10px] border border-[#E5E7EB] p-2">
      <div className="flex justify-between w-full mb-3 text[18px] font-[600]">
        Production for a year
      </div>
      <div className="w-full h-[231px]">
        <ProductionLineChart />
      </div>
    </div>
  );
};

export default ProductionContainer;
