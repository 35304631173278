import React, { useState } from "react";
import {
  GasProductionChart,
  gasProductionData,
  OilProductionChart,
  oilProductionData,
} from "./ProductionCharts";
import {
  Card,
  CardContent,
  CardHeader,
  GasProductionStats,
  StatsCard,
  YearSelector,
} from "./ProductionComponents";

// Main Production Component
const Production = () => {
  const [selectedYear, setSelectedYear] = useState("2024");

  const gasStats = {
    Flared: 4639,
    Fuel: 2345,
    LNG: 456,
    EGTL: 72456,
    "NGL/LPG": 123,
    "Dom Sales": 1234,
    "Gas reinjection/gas lift": 3456,
  };

  return (
    <div className="space-y-6">
      {/* Fields Production Volume */}
      <div className="grid md:grid-cols-2 gap-6">
        {/* left card */}
        <div>
          {/* Oil Production Section */}
          <Card>
            <StatsCard title="Fields Production Volume" value={23567} />
            <CardHeader className="flex flex-row items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900">
                Activity Status
              </h2>
              <YearSelector
                selected={selectedYear}
                onChange={setSelectedYear}
              />
            </CardHeader>
            <CardContent>
              <OilProductionChart data={oilProductionData} />
            </CardContent>
          </Card>
        </div>

        {/* right card */}
        <div>
          {/* Oil Production Section */}
          <Card>
            <StatsCard title="Fields Production Volume" value={23567} />
            <CardHeader className="flex flex-row items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900">
                Activity Status
              </h2>
              <YearSelector
                selected={selectedYear}
                onChange={setSelectedYear}
              />
            </CardHeader>
            <CardContent>
              <OilProductionChart data={oilProductionData} />
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Gas Production Section */}

      <CardHeader>
        <h2 className="text-xl font-semibold text-gray-900">Gas Production</h2>
      </CardHeader>

      <div className="flex gap-6 items-center">
        <Card className="flex-1 p-2">
          <div>
            <div className="flex justify-between mb-4">
              <h1>Activity Status</h1>
              <YearSelector
                selected={selectedYear}
                onChange={setSelectedYear}
              />
            </div>
            <GasProductionChart data={gasProductionData} />
          </div>
        </Card>
        <Card>
          <GasProductionStats stats={gasStats} />
        </Card>
      </div>
    </div>
  );
};

export default Production;
