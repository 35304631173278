const GreenBtn = ({ icon, text, className = "", onClick }) => {
  return (
    <button
      className={` text-[#ffffff]  bg-[#00AD51]   whitespace-nowrap font-semibold py-2 px-6 flex items-center rounded-[40px]  text-[14px] leading-[20.3px] justify-center gap-[10px] shadow-custom-1 hover:shadow-custom-2`}
      onClick={onClick}
    >
      <img src={icon} alt="" />
      <p>{text}</p>
    </button>
  );
};

export default GreenBtn;
