import React, {useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../generalComponents/Header";
import Sidebar from "../generalComponents/Sidebar";
import { SidebarProvider, useSidebar } from "./SidebarContext";

const LayoutContent = () => {
  const { isExpanded } = useSidebar();
  const navigate = useNavigate()
  const tokenExists =
  typeof window !== "undefined" && localStorage.getItem("token") !== null;
  useEffect(()=> {
    console.log("checks", tokenExists)
    if(!tokenExists){
      navigate("/login");
    }
  }, [tokenExists])
  return (
    <div className="flex flex-col min-h-screen bg-[#F6F8FC]">
      {/* Fixed Header */}
      <div className="fixed top-0 left-0 right-0 z-20 bg-[#F6F8FC]">
        <Header />
      </div>

      {/* Content Area */}
      <div className="flex pt-[72px] min-h-screen">
        {/* Fixed Sidebar */}
        <div className="fixed top-[72px] left-0 bottom-0 z-10">
          <Sidebar />
        </div>

        {/* Main Content - Dynamically adjusted margin based on sidebar state */}
        <main
          className={`flex-grow mt-2 transition-all duration-300 ${
            isExpanded ? "ml-[252px]" : "ml-[46px]"
          } px-5 overflow-x-hidden`}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};

const Layout = () => {
  return (
    <SidebarProvider>
      <LayoutContent />
    </SidebarProvider>
  );
};

export default Layout;
