import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card } from "./ProductionComponents";

const getChartColors = (type) => {
  const colors = {
    "not-started": {
      stroke: "#10B981",
      fill: "#D1FAE5",
    },
    ongoing: {
      stroke: "#EF4444",
      fill: "#FEE2E2",
    },
    completed: {
      stroke: "#10B981",
      fill: "#D1FAE5",
    },
  };
  return colors[type];
};

export const TrendBadge = ({ value, trend }) => {
  const isPositive = trend === "up";
  const bgColor = isPositive ? "bg-emerald-50" : "bg-red-50";
  const textColor = isPositive ? "text-emerald-700" : "text-red-700";

  return (
    <span
      className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${bgColor} ${textColor}`}
    >
      {isPositive ? "+" : ""}
      {value}%
    </span>
  );
};

export const ProjectCard = ({
  title,
  metric,
  change,
  timeframe,
  data,
  type,
}) => {
  const chartColors = getChartColors(type);

  return (
    <Card className="p-6">
      <div className="flex justify-between items-start mb-4">
        <div>
          <div className="flex items-center gap-2">
            <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
            <TrendBadge value={change.value} trend={change.trend} />
          </div>
          <p className="text-sm text-gray-500 mt-1">{timeframe}</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="text-3xl font-bold text-gray-900">{metric}</p>
      </div>
      <div className="h-[200px] mt-4">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke="#e0e0e0"
            />
            <defs>
              <linearGradient
                id={`gradient-${type}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={chartColors.fill}
                  stopOpacity={0.6}
                />
                <stop
                  offset="100%"
                  stopColor={chartColors.fill}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
              dy={10}
              tickFormatter={(tick, index) => {
                const isFirstTick = index === 0;
                const isLastTick = index === data.length - 1;
                return isFirstTick || isLastTick ? tick : "";
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
              dx={-10}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke={chartColors.stroke}
              fill={`url(#gradient-${type})`}
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export const ProjectSection = ({ title, metrics }) => (
  <div className="space-y-6">
    <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {metrics.map((metric, index) => (
        <ProjectCard key={index} {...metric} />
      ))}
    </div>
  </div>
);
