import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const CreateOmlsManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/omls/",
    ["omls"],
    true
  );
  const createOmls = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("Detailed error in createOmls:", error);
      throw error; // Re-throw to allow calling component to handle
    }
  };
  return {
    createOmls,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
