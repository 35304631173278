"use client";

import React from "react";
import { useNavigate } from "react-router-dom";

const GoBackButton = ({ onClick, buttonClass }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={
        onClick === null
          ? () => {
              navigate(-1);
            }
          : onClick
      }
      className={`w-full flex items-center justify-start gap-2 ${buttonClass}`}
    >
      <div className="inline-flex items-center text-[#344054] bg-[#FFFFFF] rounded-[4px] px-2 py-2  border border-[#E4E7EC]">
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.146446 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146446 2.64645L2.14645 0.646447C2.34171 0.451184 2.65829 0.451184 2.85355 0.646447C3.04882 0.841709 3.04882 1.15829 2.85355 1.35355L1.70711 2.5L9.5 2.5C9.77614 2.5 10 2.72386 10 3C10 3.27614 9.77614 3.5 9.5 3.5L1.70711 3.5L2.85355 4.64645C3.04882 4.84171 3.04882 5.15829 2.85355 5.35355C2.65829 5.54882 2.34171 5.54882 2.14645 5.35355L0.146446 3.35355Z"
            fill="black"
          />
        </svg>
      </div>
      <p className="text-[12px] leading-[17px] text-[#667185]">Go Back</p>
    </button>
  );
};

export default GoBackButton;
