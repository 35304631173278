import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";
import useUpdateManager from "../../../constants/controller_templates/put_controller_template";

export const DeactivateClientManager = ({ id }) => {
  console.log(id)
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/clients/${id}/`,
    ["clients_delete"]
  );
  const deactivateClient = async (details) => {
    console.log(details)
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deactivateClient,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
