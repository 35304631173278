import React, { useEffect, useRef, useState } from "react"; 
import GoBackButton from "../GoBackButton"; 
import InitialsAvatar from "../InitialsAvatar";
import useGetPermissionsManager from "../../modules/auth/controllers/getPermissionsController";
import useGetSingleClient from "../../modules/clientManagement/controllers/getClientController";
import { PermissionSection } from "../PermissionSection";
import Loader from "../Loader";
import StatusButton from "../StatusButton";
const ViewClient = ({ onClose, clientId }) => { 
  const {data: clientDetails, isLoading: getting} = useGetSingleClient({id: clientId, enabled: true})
  console.log(clientDetails)
  const [permissions, setPermissions] = useState(clientDetails?.data?.permissions?.map((perm) => perm.id)); 
  return (
    <div  className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6  w-full rounded-[10px] h-full overflow-y-auto">
    <div
          onClick={(e) => e.target === e.currentTarget && onClose()}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
    <div  className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 overflow-y-auto scrollbar-hide max-h-[80%]">
      <div className=" flex justify-between w-full  whitespace-nowrap items-center p-1">
        Client Details
        <GoBackButton buttonClass={"!w-fit"} onClick={onClose} />
      </div>

      {getting ? <Loader /> :  <div className="flex flex-col w-full items-center gap-3 ">
        <div  className="flex items-center gap-5">
         {clientDetails?.data?.photo ? <img src={clientDetails?.data?.photo} alt="" className="w-[120px] h-[120px] rounded-full" /> : <InitialsAvatar classN={"!w-[120px] !h-[120px]"} textClass={"text-[40px]"} name={"bendict"} /> } 
        </div>
        <div className="w-fit items-center flex flex-col ">
           <h3 className="text-[25px] text-[#181918] font-medium">{clientDetails?.data?.name}</h3>
           <h3 className="text-[12px] text-[#344054] font-normal">{clientDetails?.data?.email}</h3>
        </div>
        <div className="w-full items-center flex  justify-between ">
          <h3 className="text-[12px] text-[#181918] font-medium">Status</h3>
          <StatusButton status={clientDetails?.data?.status} /> 
        </div>
        {clientDetails?.data?.alias && <div className="w-full items-center flex  justify-between ">
          <h3 className="text-[12px] text-[#181918] font-medium">Alias</h3>
          <h3 className="text-[12px] text-[#828282] font-normal">{clientDetails?.data?.alias}</h3>
        </div>}
        {clientDetails?.data?.asset_group && <div className="w-full items-center flex  justify-between ">
          <h3 className="text-[12px] text-[#181918] font-medium">Asset Group</h3>
          <h3 className="text-[12px] text-[#828282] font-normal">{clientDetails?.data?.asset_group?.name}</h3>
        </div>}
        {clientDetails?.data?.contract_type && <div className="w-full items-center flex  justify-between ">
          <h3 className="text-[12px] text-[#181918] font-medium">Contract Type</h3>
          <h3 className="text-[12px] text-[#828282] font-normal">{clientDetails?.data?.contract_type}</h3>
        </div>}
         <PermissionSection
              title="Client's Permission"
              permissions={permissions}
              nonEdit={true}
              setPermissions={setPermissions}
            />  
         
      </div>}
    </div>
    </div>
    </div>
    </div>
  );
};

export default ViewClient;
