export function formatDate(inputDate) {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour format; set to false for 24-hour format
  };

  const formattedDate = new Date(inputDate).toLocaleDateString("en-US", options);
  return formattedDate ?? null;
}
