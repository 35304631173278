import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateWellProductionManager } from "../controllers/createWellProductionControler";
import useGetWellsManager from "../controllers/getWellsController";
import { FormLabel, FormSelect } from "../../omls/components/FormComponents";

const validationSchema = Yup.object({
  well: Yup.string().required("Well is required"),
  date: Yup.date().required("Date is required"),
  planned_oil_production: Yup.string().required(
    "Planned oil production is required"
  ),
  actual_oil_production: Yup.string().required(
    "Actual oil production is required"
  ),
  planned_condensate_blended: Yup.string().required(
    "Planned condensate blended is required"
  ),
  actual_condensate_blended: Yup.string().required(
    "Actual condensate blended is required"
  ),
  planned_condensate_unblended: Yup.string().required(
    "Planned condensate unblended is required"
  ),
  actual_condensate_unblended: Yup.string().required(
    "Actual condensate unblended is required"
  ),
  tubing_head_pressure: Yup.string().required(
    "Tubing head pressure is required"
  ),
  well_productability: Yup.string().required("Well productability is required"),
  commercial_allowable: Yup.string().required(
    "Commercial allowable is required"
  ),
  approved_technical_allowable: Yup.string().required(
    "Approved technical allowable is required"
  ),
});

const AddWellProduction = ({ isOpen, onClose }) => {
  const { createWellProduction, isLoading } = CreateWellProductionManager();

  const { data: wellsData, isLoading: iswellsLoading } = useGetWellsManager({
    page: 1,
    searchQuery: "",
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      well: "",
      date: "",
      planned_oil_production: "",
      actual_oil_production: "",
      planned_condensate_blended: "",
      actual_condensate_blended: "",
      planned_condensate_unblended: "",
      actual_condensate_unblended: "",
      tubing_head_pressure: "",
      well_productability: "",
      commercial_allowable: "",
      approved_technical_allowable: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await createWellProduction(values);
        onClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title="Create Well Production"
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <FormLabel>Well</FormLabel>
              <FormSelect
                label="Well"
                id="well"
                name="well"
                placeholder="Enter well"
                value={formik.values.well}
                options={[
                  { value: "", label: "Select Well" },
                  ...(wellsData?.data?.results?.map((well) => ({
                    value: well.id,
                    label: well.name,
                  })) || []),
                ]}
                disabled={iswellsLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.well && formik.errors.well}
                errorMessage={formik.errors.well}
              />
            </div>

            <InputWithFullBoarder
              label="Date"
              id="date"
              name="date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && formik.errors.date}
              errorMessage={formik.errors.date}
            />

            <InputWithFullBoarder
              label="Planned Oil Production"
              id="planned_oil_production"
              name="planned_oil_production"
              placeholder="Enter planned oil production"
              value={formik.values.planned_oil_production}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_oil_production &&
                formik.errors.planned_oil_production
              }
              errorMessage={formik.errors.planned_oil_production}
            />

            <InputWithFullBoarder
              label="Actual Oil Production"
              id="actual_oil_production"
              name="actual_oil_production"
              placeholder="Enter actual oil production"
              value={formik.values.actual_oil_production}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_oil_production &&
                formik.errors.actual_oil_production
              }
              errorMessage={formik.errors.actual_oil_production}
            />

            <InputWithFullBoarder
              label="Planned Condensate Blended"
              id="planned_condensate_blended"
              name="planned_condensate_blended"
              placeholder="Enter planned condensate blended"
              value={formik.values.planned_condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_condensate_blended &&
                formik.errors.planned_condensate_blended
              }
              errorMessage={formik.errors.planned_condensate_blended}
            />

            <InputWithFullBoarder
              label="Actual Condensate Blended"
              id="actual_condensate_blended"
              name="actual_condensate_blended"
              placeholder="Enter actual condensate blended"
              value={formik.values.actual_condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_condensate_blended &&
                formik.errors.actual_condensate_blended
              }
              errorMessage={formik.errors.actual_condensate_blended}
            />

            <InputWithFullBoarder
              label="Planned Condensate Unblended"
              id="planned_condensate_unblended"
              name="planned_condensate_unblended"
              placeholder="Enter planned condensate unblended"
              value={formik.values.planned_condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_condensate_unblended &&
                formik.errors.planned_condensate_unblended
              }
              errorMessage={formik.errors.planned_condensate_unblended}
            />

            <InputWithFullBoarder
              label="Actual Condensate Unblended"
              id="actual_condensate_unblended"
              name="actual_condensate_unblended"
              placeholder="Enter actual condensate unblended"
              value={formik.values.actual_condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_condensate_unblended &&
                formik.errors.actual_condensate_unblended
              }
              errorMessage={formik.errors.actual_condensate_unblended}
            />

            <InputWithFullBoarder
              label="Tubing Head Pressure"
              id="tubing_head_pressure"
              name="tubing_head_pressure"
              placeholder="Enter tubing head pressure"
              value={formik.values.tubing_head_pressure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.tubing_head_pressure &&
                formik.errors.tubing_head_pressure
              }
              errorMessage={formik.errors.tubing_head_pressure}
            />

            <InputWithFullBoarder
              label="Well Productability"
              id="well_productability"
              name="well_productability"
              placeholder="Enter well productability"
              value={formik.values.well_productability}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.well_productability &&
                formik.errors.well_productability
              }
              errorMessage={formik.errors.well_productability}
            />

            <InputWithFullBoarder
              label="Commercial Allowable"
              id="commercial_allowable"
              name="commercial_allowable"
              placeholder="Enter commercial allowable"
              value={formik.values.commercial_allowable}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.commercial_allowable &&
                formik.errors.commercial_allowable
              }
              errorMessage={formik.errors.commercial_allowable}
            />

            <InputWithFullBoarder
              label="Approved Technical Allowable"
              id="approved_technical_allowable"
              name="approved_technical_allowable"
              placeholder="Enter approved technical allowable"
              value={formik.values.approved_technical_allowable}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.approved_technical_allowable &&
                formik.errors.approved_technical_allowable
              }
              errorMessage={formik.errors.approved_technical_allowable}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading}
            buttonText="Create Well Production"
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddWellProduction;
