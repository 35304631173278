export const API_ROUTES = {
  EVENT: "/event",
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  FORGOT: "auth/password/forgot",
  RESET: "auth/password/reset",
  RESET_PASSWORD: "auth/password/reset",
  OTP: "auth/mfa/verify",

  // Add more routes as needed
};
