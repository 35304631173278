import React, { useState } from "react";
import { ReactComponent as More } from "../assets/icons/moreVector.svg";
import logo from "../assets/images/nnpcLogo.svg";
import notification from "../assets/icons/notification.svg";
import dangerCircle from "../assets/icons/dangerCircle.svg";
import { useSidebar } from "../layout/SidebarContext";
import ProfileModal from "./ProfileModal";
import { ReactComponent as Icon4 } from "../assets/icons/icon4.svg";
import { ReactComponent as Icon5 } from "../assets/icons/icon5.svg";
import { ReactComponent as Icon6 } from "../assets/icons/icon6.svg";
import { ReactComponent as Icon7 } from "../assets/icons/icon7.svg";
import { ReactComponent as Icon8 } from "../assets/icons/icon8.svg";
import { ReactComponent as Icon9 } from "../assets/icons/icon9.svg";
import { ReactComponent as Icon10 } from "../assets/icons/icon10.svg";
import { ReactComponent as Icon11 } from "../assets/icons/icon11.svg";
import { ReactComponent as Icon12 } from "../assets/icons/icon12.svg";
import { ReactComponent as Icon13 } from "../assets/icons/icon13.svg";
import { ReactComponent as Icon14 } from "../assets/icons/icon14.svg";
import { ReactComponent as Icon15 } from "../assets/icons/icon15.svg";
import NotificationsModal from "./Notification";
import { dropdown, flag, lightSearch } from "../assets/icons";

const Header = () => {
  const { isExpanded, setIsExpanded } = useSidebar();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const notifications = [
    {
      id: 1,
      title: "New Message",
      message: "You have a new message from John Doe",
      severity: "success", // or "warning", "error", "info"
    },
    {
      id: 2,
      title: "System Maintenance",
      message: "The system will be down for maintenance tomorrow",
      severity: "error",
    },
  ];

  const handleNotificationIconClick = () => {
    setIsNotificationsModalOpen(true);
  };

  const handleCloseNotificationsModal = () => {
    setIsNotificationsModalOpen(false);
  };

  const dropdownItems = [
    { label: "OMLs", icon: Icon5 },
    { label: "Operators", icon: Icon4 },
    {
      label: "Asset Gp Management",
      icon: Icon6,
    },
    { label: "Hydrocarbon Accounting", icon: Icon7 },
    { label: "Planning", icon: Icon8 },
    { label: "Maintenance", icon: Icon9 },
    { label: "Reporting Management", icon: Icon10 },
    { label: "Project Management", icon: Icon11 },
    { label: "Exploration Management", icon: Icon12 },
    { label: "Reservoir Tracking", icon: Icon13 },
    { label: "Lifting Management", icon: Icon14 },
    { label: "Support", icon: Icon15 },
  ];

  const UserInitials = ({ name, className = "" }) => {
    const getInitials = (name) => {
      if (!name) return "";
      const nameArray = name.trim().split(" ");
      if (nameArray.length === 1) {
        return nameArray[0][0].toUpperCase();
      }
      return nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase();
    };

    return (
      <div
        className={`w-8 h-8 rounded-full text-white flex items-center justify-center text-sm font-medium ${className}`}
      >
        {getInitials(name)}
      </div>
    );
  };
  return (
    <>
      <header className="p-2 flex items-center justify-between">
        {/* Logo Section */}
        <div className="flex items-center px-1 space-x-[32px]">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`h-[50px] w-[50px] flex items-center justify-center hover:bg-[#00AD511A]  transition-colors duration-300 rounded-full  ${
              isExpanded ? "bg-[#00AD51]" : "text-[#4F4F4F]"
            }`}
          >
            <More
              className={`transition-colors duration-300 ${
                isExpanded ? "text-white" : "text-[#000000]"
              }`}
            />
          </button>
          <img src={logo} alt="Logo" className="w-[85px] h-[47.81px]" />
        </div>

        {/* Dropdown and Search Bar */}
        <div className="flex bg-white border border-[#E0E0E0] rounded-[5px] justify-center items-center space-x-4">
          {/* Dropdown */}
          <div className="dropdown dropdown-end border-r border-[#E0E0E0] ">
            <label
              tabIndex={0}
              className="btn btn-ghost text-[12px] space-x-3 text-[#4F4F4F] "
            >
              <img src={flag} alt="" />
              Operators
              <img src={dropdown} alt="" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-4 shadow-lg mt-2 bg-white rounded-box w-60 "
            >
              {dropdownItems.map(({ label, icon: Icon }) => (
                <li key={label}>
                  <button className="flex items-center gap-6 text-12px whitespace-nowrap z-50">
                    <Icon /> {label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Search Bar */}
          <div className="relative ">
            <input
              type="text"
              placeholder="Search of Operators"
              className="input  w-[500px] rounded-[1px] pr-4 focus:outline-none"
            />
          </div>
          <div>
            <div className="button bg-[#00AD51] p-3 rounded-tr-[5px] rounded-br-[5px]">
               <img src={lightSearch} alt="" />
            </div>
          </div>
        </div>

        {/* Right-side Icons */}
        <div className="flex items-center space-x-6">
          {/* Notification Icon */}
          <div className="flex items-center space-x-1">
            <div
              className="bg-white rounded-full p-3"
              onClick={handleNotificationIconClick}
            >
              <img
                src={notification}
                alt="Notifications"
                className="w-6 h-6 text-gray-500 hover:text-green-500"
              />
            </div>
            <div className="bg-white rounded-full p-3">
              <img
                src={dangerCircle}
                alt="Danger"
                className="w-6 h-6 object-cover"
              />
            </div>
          </div>

          {/* User Profile */}
          <div className="dropdown dropdown-end">
            <button onClick={() => setIsProfileModalOpen(true)}>
              <UserInitials name="Mary Davis" className="bg-[#00AD51]" />
            </button>
            {isProfileModalOpen && (
              <ProfileModal
                name="Mary Davis"
                email="marydavis@gmail.com"
                onClose={() => setIsProfileModalOpen(false)}
              />
            )}
          </div>
        </div>
      </header>
      {isNotificationsModalOpen && (
        <NotificationsModal
          notifications={notifications}
          onClose={handleCloseNotificationsModal}
        />
      )}
    </>
  );
};

export default Header;
