// CustomTabs.jsx
import React from "react";

export const CustomTabs = ({
  tabs,
  selectedTab,
  onTabChange,
  containerClassName = "",
}) => {
  return (
    <div
      className={`flex items-center border-b border-[#E5E7EB] overflow-x-auto mb-6 ${containerClassName}`}
    >
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => onTabChange(index)}
          className={`
            cursor-pointer pb-4 w-full text-center font-[600] leading-[14px] text-[12px] pt-3 px-3 relative
            ${
              selectedTab === index
                ? "text-white bg-[#00AD51] rounded-md"
                : "text-[#828282] hover:text-gray-700"
            }
          `}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
