import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateClientManager } from "../controllers/createClientController";
import { UpdateClientManager } from "../controllers/updateClientController";
import useGetPermissionsManager from "../../auth/controllers/getPermissionsController";
import { useEffect, useState } from "react";
import useGetSingleClient from "../controllers/getClientController";
import useGetAssetGroupsManager from "../../assetGroupManagement/controllers/getAssetGroupsController";
import GoBackButton from "../../../generalComponents/GoBackButton";
import Loader from "../../../generalComponents/Loader";
import { FaTrash } from "react-icons/fa";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import Dropdown from "../../../generalComponents/Dropdown";
import { PermissionSection } from "../../../generalComponents/PermissionSection";
import CustomButton from "../../../generalComponents/Button";
import { Upload } from "lucide-react";

const ClientAdd = ({ onClose }) => { 
    const navigate = useNavigate()
    const [showUploadModal, setShowUploadModal] = useState(false);
    const { data, isLoading } = useGetPermissionsManager({ enabled: true });
    const { createClient, isLoading: creatingClient, isSuccess } = CreateClientManager(); 
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const handleOpenUploadModal = () => {
      setShowUploadModal(true);
    };
    const handleCloseUploadModal = () => {
      setShowUploadModal(false);
    };
    useEffect(() => {
        if(isSuccess){
          navigate("/client")
        }
    }, [isSuccess])
  
    
  
    const { data: assetGroups, isLoading: loadingAssetGroups } =
      useGetAssetGroupsManager({
        enabled: true,
      });
  
    const formik = useFormik({
      initialValues: {
        name: "",
        email: "",
        photo: "",
        company_type: "", // Changed from companyType to type
        alias: "",
        asset_group: "",
        contract_type: "",
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        name: Yup.string().required("Operator name is required"), 
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        company_type: Yup.string().required("Company type is required"), // Changed from companyType to type
        alias: Yup.string(),
        asset_group: Yup.string(),
        contract_type: Yup.string(),
      }),
      onSubmit: async (values) => { 
        try {
          // const selectedGroup = assetGroups?.data?.results.find(
          //   (group) => group.name === values.asset_group
          // );
          const selectedGroup = assetGroups?.data?.results.find(
            (group) => group.name?.replace(/"/g, "") === values.asset_group
          );
   
          
          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("photo", values.photo); // Attach the File object
          formData.append("company_type", values.company_type.toLowerCase());
          formData.append("alias", values.alias || "");
          formData.append("asset_group", selectedGroup?.id || "");
          formData.append("contract_type", values.contract_type || "");
      
          // Add permissions (if it’s an array, you might need to stringify or loop)
          permissions.forEach((permission, index) =>
            formData.append(`permissions[${index}]`, permission)
          );
       
          const result = await createClient(formData); 
          onClose();
        } catch (error) {
          console.error("Form submission error:", error);
        }
      },
    });
  
    const handleFileSelect = (e) => {
      const file = e.target.files?.[0];
      console.log(file)
      if (file) {
        formik.setFieldValue("photo", file); 
      }
    };
    return (
      <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <div  className="  w-full   p-6  ">
        <div className=" flex flex-col justify-start items-center p-1">
          <GoBackButton onClick={() => navigate("/client")} />
        </div>
        <div className="flex-1 ml-2 ">
          <div className="flex flex-col gap-0">
            <h2 className="text-2xl font-bold ">
              {"Add Client"}
            </h2>
            <p className="text-13px text-[#667185]">
              Enter the required information below to add a field
            </p>
          </div> 
            <form className="p-8" onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-2 gap-4 p-2">
                <div className="w-full h-fit flex justify-between items-center col-span-2">
                  <div className="w-fit h-fit flex items-center gap-2">
                    <div  onClick={() => document.getElementById("fileInput")?.click()} className="rounded-full cursor-pointer h-[82px] w-[82px] flex items-center justify-center   border ">
                      { formik.values.photo ? <img src={ formik.values.photo instanceof File ? URL.createObjectURL(formik.values.photo) : formik.values.photo }  alt="" className="rounded-full  h-[82px] w-[82px] object-cover  "  /> : <Upload className="w-12 h-12 mx-auto text-gray-400  " />}
                    </div>
                  
                    
                    {(formik.touched.photo && formik.errors.photo) ? <h3 className="text-red-600">{formik.errors.photo}</h3> : <h3>Upload logo</h3>} 
                  </div>
                  <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  accept={"image/*"}
                  onChange={handleFileSelect}
                />
                  <div onClick={() =>  formik.setFieldValue("photo", "")} className="w-fit h-fit cursor-pointer flex py-2 bg-black/10 px-2 gap-2 border rounded-full">
                    <FaTrash color="#dc2626" size={24} />
                  </div>
                </div> 
                {/* Operator Name */}
                <InputWithFullBoarder
                  label="Client Name"
                  id="name"
                  name="name"
                  placeholder="Enter operator name"
                  value={formik.values.name ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                  errorMessage={formik.errors.name}
                />
                <InputWithFullBoarder
                  label="Email Address"
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  errorMessage={formik.errors.email}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 p-2">
                {/* Operator Name */}
                <Dropdown
                  name="company_type"
                  title="Company Type"
                  className={"w-full"}
                  options={["client", "operator"]}
                  placeholder="Select Company Type"
                  value={formik.values.company_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.company_type && formik.errors.company_type
                  }
                  errorMessage={formik.errors.company_type}
                />
                {formik.values.company_type === "operator" && (
                  <InputWithFullBoarder
                    label="Operator Alias"
                    id="alias"
                    name="alias"
                    placeholder="Enter operator alias"
                    value={formik.values.alias}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.alias && formik.errors.alias}
                    errorMessage={formik.errors.alias}
                  />
                )}
              </div>
              {formik.values.company_type === "operator" && (
                <div className="grid grid-cols-2 gap-4 p-2">
                  {loadingAssetGroups ? (
                    <p>{"Loading..."}</p>
                  ) : (
                    <Dropdown
                      name="asset_group" // Add this - important for formik
                      title="Asset Group"
                      options={
                        assetGroups &&
                        assetGroups?.data?.results.map((el, i) => el?.name)
                      }
                      placeholder="Select Asset Group"
                      value={formik.values.asset_group}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.asset_group && formik.errors.asset_group
                      }
                      errorMessage={formik.errors.asset_group}
                    />
                  )}
                  <Dropdown
                    name="contract_type" // Add this - important for formik
                    title="Contract Type"
                    options={["JV", "PSC"]}
                    placeholder="Select Contract Type"
                    value={formik.values.contract_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.contract_type && formik.errors.contract_type
                    }
                    errorMessage={formik.errors.contract_type}
                  />
                </div>
              )}
  
              {/* Permissions Section */}
  
              <PermissionSection
                title="Assign Client's Permission"
                permissions={permissions}
                setPermissions={setPermissions}
              />
  
              <CustomButton
                buttonText={"Add Client"}
                type="submit"
                className={"w-full"}
                isLoading={creatingClient}
                // onClick={() => {
                //   console.log(permissions);
                // }}
              />
            </form> 
        </div>
      </div>
      </div> 
    );
  };
  
  export default ClientAdd;