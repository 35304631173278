import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateLactPointProductionManager } from "../controllers/createLactPointProduction";

const validationSchema = Yup.object({
  lactpoint: Yup.string().required("Lact Point is required"),
  company: Yup.string().required("Company is required"),
  date: Yup.date().required("Date is required"),
  actual_oil_production: Yup.number().required(
    "Actual oil production is required"
  ),
  actual_condensate_blended: Yup.number().required(
    "Actual condensate blended is required"
  ),
  actual_condensate_unblended: Yup.number().required(
    "Actual condensate unblended is required"
  ),
  tempressure: Yup.string().required("Temperature is required"),
  pressure: Yup.number().required("Pressure is required"),
  bsw: Yup.string().required("BSW is required"),
});

const AddLactPointProduction = ({ isOpen, onClose }) => {
  const { createLactPointProduction, isLoading } =
    CreateLactPointProductionManager();

  const formik = useFormik({
    initialValues: {
      lactpoint: "",
      company: "",
      date: "",
      actual_oil_production: "",
      actual_condensate_blended: "",
      actual_condensate_unblended: "",
      tempressure: "",
      pressure: "",
      bsw: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await createLactPointProduction(values);
        onClose(); // Close modal after successful creation
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title="Create Lact Point Production"
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputWithFullBoarder
              label="Lact Point"
              id="lactpoint"
              name="lactpoint"
              placeholder="Enter Lact Point"
              value={formik.values.lactpoint}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lactpoint && formik.errors.lactpoint}
              errorMessage={formik.errors.lactpoint}
            />

            <InputWithFullBoarder
              label="Company"
              id="company"
              name="company"
              placeholder="Enter Company (UUID)"
              value={formik.values.company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.company && formik.errors.company}
              errorMessage={formik.errors.company}
            />

            <InputWithFullBoarder
              label="Date"
              id="date"
              name="date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && formik.errors.date}
              errorMessage={formik.errors.date}
            />

            <InputWithFullBoarder
              label="Actual Oil Production"
              id="actual_oil_production"
              name="actual_oil_production"
              placeholder="Enter Actual Oil Production"
              value={formik.values.actual_oil_production}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_oil_production &&
                formik.errors.actual_oil_production
              }
              errorMessage={formik.errors.actual_oil_production}
            />

            <InputWithFullBoarder
              label="Actual Condensate Blended"
              id="actual_condensate_blended"
              name="actual_condensate_blended"
              placeholder="Enter Actual Condensate Blended"
              value={formik.values.actual_condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_condensate_blended &&
                formik.errors.actual_condensate_blended
              }
              errorMessage={formik.errors.actual_condensate_blended}
            />

            <InputWithFullBoarder
              label="Actual Condensate Unblended"
              id="actual_condensate_unblended"
              name="actual_condensate_unblended"
              placeholder="Enter Actual Condensate Unblended"
              value={formik.values.actual_condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_condensate_unblended &&
                formik.errors.actual_condensate_unblended
              }
              errorMessage={formik.errors.actual_condensate_unblended}
            />

            <InputWithFullBoarder
              label="Temperature"
              id="tempressure"
              name="tempressure"
              placeholder="Enter Temperature"
              value={formik.values.tempressure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.tempressure && formik.errors.tempressure}
              errorMessage={formik.errors.tempressure}
            />

            <InputWithFullBoarder
              label="Pressure"
              id="pressure"
              name="pressure"
              placeholder="Enter Pressure"
              value={formik.values.pressure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pressure && formik.errors.pressure}
              errorMessage={formik.errors.pressure}
            />

            <InputWithFullBoarder
              label="BSW"
              id="bsw"
              name="bsw"
              placeholder="Enter BSW"
              value={formik.values.bsw}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bsw && formik.errors.bsw}
              errorMessage={formik.errors.bsw}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading}
            buttonText="Create Lact Point Production"
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddLactPointProduction;
