import { ProjectSection } from "./ProjectComponents";

const Project = () => {
  const explorationMetrics = [
    {
      title: "Not Started",
      metric: 865,
      change: { value: 30, trend: "up" },
      timeframe: "Last 30 days",
      type: "not-started",
      data: [
        { date: "13 Jun", value: 200 },
        { date: "14 Jun", value: 300 },
        { date: "15 Jun", value: 250 },
        { date: "16 Jun", value: 400 },
        { date: "17 Jun", value: 350 },
        { date: "18 Jun", value: 500 },
        { date: "19 Jun", value: 865 },
      ],
    },
    {
      title: "Ongoing",
      metric: 865,
      change: { value: 30, trend: "down" },
      timeframe: "Last 30 days",
      type: "ongoing",
      data: [
        { date: "13 Jun", value: 200 },
        { date: "14 Jun", value: 300 },
        { date: "15 Jun", value: 250 },
        { date: "16 Jun", value: 400 },
        { date: "17 Jun", value: 350 },
        { date: "18 Jun", value: 500 },
        { date: "19 Jun", value: 865 },
      ],
    },
    {
      title: "Completed",
      metric: 865,
      change: { value: 30, trend: "up" },
      timeframe: "Last 30 days",
      type: "completed",
      data: [
        { date: "13 Jun", value: 200 },
        { date: "14 Jun", value: 300 },
        { date: "15 Jun", value: 250 },
        { date: "16 Jun", value: 400 },
        { date: "17 Jun", value: 350 },
        { date: "18 Jun", value: 500 },
        { date: "19 Jun", value: 865 },
      ],
    },
  ];

  return (
    <div className="space-y-8 p-6">
      <ProjectSection title="Exploration" metrics={explorationMetrics} />
      <ProjectSection title="Drilling" metrics={explorationMetrics} />
      <ProjectSection title="Rig Activity" metrics={explorationMetrics} />
    </div>
  );
};

export default Project;
