import * as Yup from "yup";

export const validationSchema = Yup.object({
  client: Yup.string().required("Client is required"),
  number: Yup.string().required("OML Number is required"),
  area: Yup.string().required("Area is required"),
  basil_name: Yup.string().required("Basin Name is required"),
  opl: Yup.string().required("OPL is required"),
  application_date: Yup.date().required("Application Date is required"),
  opl_award_date: Yup.date().required("OPL Award Date is required"),
  lng: Yup.number()
    .required("Longitude is required")
    .min(-180, "Must be at least -180")
    .max(180, "Must be at most 180"),
  lat: Yup.number()
    .required("Latitude is required")
    .min(-90, "Must be at least -90")
    .max(90, "Must be at most 90"),
  opl_block_size: Yup.number()
    .required("OPL Block Size is required")
    .positive("Size must be positive"),
  onshore_size: Yup.number().required("Onshore Size is required"),
  shelf_size: Yup.number().required("Shelf Size is required"),
  deep_water_size: Yup.number().required("Deep Water Size is required"),
  oml_class: Yup.string().required("OML Class is required"),
  min_water_depth: Yup.number().required("Min Water Depth is required"),
  max_water_depth: Yup.number().required("Max Water Depth is required"),
  median_water_depth: Yup.number().required("Med Water Depth is required"),
  x: Yup.number().required("X Coordinate is required"),
  y: Yup.number().required("Y Coordinate is required"),
});
