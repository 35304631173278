import React from "react"; // Remove useEffect since we won't need it

import Loader from "../../../generalComponents/Loader";
import useGetStaffManager from "../controllers/getStaffController";
import { DeleteStaffManager } from "../controllers/deleteStaffController";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { getInitials } from "../../../utils/getInitials";
import {
  DeactivateModal,
  DeleteModal,
} from "../../../generalComponents/ConfirmationModals";

// Add staffId to props
const StaffDetails = ({ isOpen, onClose, openEditModalHandler, staffId }) => {
  const { data: singleStaffData, isLoading: loadinSingleStaff } =
    useGetStaffManager({
      id: staffId,
      enabled: Boolean(staffId && isOpen), // Only fetch when both staffId exists and modal is open
    });

  const { deleteStaff, isLoading: isDeletingStaff } = DeleteStaffManager({
    id: staffId,
  });

  // Early return if modal is not open
  if (!isOpen) return null;

  // Show loader while fetching data
  if (loadinSingleStaff) {
    return <Loader />;
  }

  // Handler for delete action
  const handleDelete = async () => {
    try {
      await deleteStaff();
      onClose();
    } catch (error) {
      console.error("Error deleting staff:", error);
    }
  };

  // Handler for suspend action
  const handleSuspend = async () => {
    try {
      //suspend staff function
      onClose();
    } catch (error) {
      console.error("Error suspending staff:", error);
    }
  };

  const staffName = singleStaffData?.data?.user?.first_name ?? "No";
  const staffLastName = singleStaffData?.data?.user?.last_name ?? "Name";
  const fullName = `${staffName} ${staffLastName}`;

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title="Staff Details"
      maxWidth="xl"
    >
      <div className="flex flex-col items-center mb-6">
        <div className="w-32 h-32 rounded-full bg-[#e6f7f1] flex items-center justify-center mb-3">
          <span className="text-2xl">{getInitials(fullName)}</span>
        </div>

        <h3 className="text-3xl font-medium">{fullName}</h3>
        <p className="text-sm text-gray-500">
          {singleStaffData?.data?.user?.email}
        </p>
      </div>

      <div className="space-y-4 mb-6">
        <div className="flex justify-between items-center">
          <span className="text-sm">Phone Number</span>
          <span className="text-sm text-gray-400">
            {singleStaffData?.data?.user?.mobile_number ?? "No Number"}
          </span>
        </div>

        <div>
          <span className="text-sm block mb-2">Staff Role</span>
          <div className="flex flex-wrap gap-2">
            {singleStaffData?.data?.roles?.map((role, index) => (
              <span
                key={role.id || index}
                className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-brandBackground"
              >
                {role?.name}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="space-y-3">
        <button
          onClick={openEditModalHandler}
          type="submit"
          className="btn btn-success w-full flex-1 bg-[#00AD51] rounded-[40px] text-[13px] font-[500] text-white"
        >
          Edit/Assign Staff
        </button>
        <div className="grid grid-cols-2 gap-3">
          <button
            type="button"
            onClick={() =>
              document.getElementById("deactivate_modal").showModal()
            }
            className="btn w-full flex-1 bg-slate-50 rounded-[40px] text-[13px] font-[500]"
          >
            Suspend Staff
          </button>
          <button
            onClick={() => document.getElementById("delete_modal").showModal()}
            type="button"
            className="btn w-full flex-1 bg-red-100 text-red-500 font-semibold rounded-[40px] text-[13px]"
          >
            Delete Staff
          </button>
        </div>
      </div>

      <DeleteModal
        title="Delete Staff"
        itemName={staffName}
        isLoading={isDeletingStaff}
        onDelete={handleDelete}
      />

      <DeactivateModal
        title="Suspend Staff"
        itemName={staffName}
        isLoading={false}
        onDeactivate={handleSuspend}
      />
    </BaseModal>
  );
};

export default StaffDetails;
