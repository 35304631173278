import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const AddVesselAgentManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/vessel-agents",
    ["vessel-agents"],
    true
  );
  const addVesselAgent = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    addVesselAgent,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
