import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { useNavigate } from "react-router-dom";

import emailIcon from "../assets/icons/emailIcon.svg";
import FormWrapper from "../generalComponents/FormWrapper";
import { NavLink } from "react-router-dom";
import { LoginManager } from "../modules/auth/controllers/login";

// Validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const FirstLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login } = LoginManager();

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const res = await login(values);
    localStorage.setItem("token", res.token);
    localStorage.setItem("user", res.user);
    navigate("/set-password");
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormWrapper>
      <div className="bg-white p-8 rounded-[10px] border border-[#E0E0E0] w-full max-w-sm">
        <h2 className="flex justify-start text-[25px] font-[600] ">Welcome</h2>
        <p className="text-[#667185] text-[13px] font-[400] mb-[40px]">
          Enter your credentials to access your account
        </p>
        <Formik
          initialValues={{ email: "", password: "", rememberMe: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isLoading }) => (
            <Form>
              {/* Email field */}
              <div className="mb-4 relative">
                <label
                  htmlFor="email"
                  className="block text-[13px] font-[500] text-[#101928]"
                >
                  EMAIL ADDRESS
                </label>
                <div className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center">
                  <img
                    src={emailIcon}
                    alt="Email Icon"
                    className="h-[15PX] w-[18px]"
                  />
                </div>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className="mt-1 block w-full pl-2 text-[11px] p-2 py-3 border rounded-md"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Password field */}
              <div className="mb-4 relative">
                <label
                  htmlFor="password"
                  className="block text-[13px] font-[500] text-[#101928]"
                >
                  DEFAULT PASSWORD
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    className="mt-1 block w-full pl-2 text-[11px] p-2 py-3 border rounded-md"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {/* Toggle eye icon */}
                    {showPassword ? (
                      <FaEyeSlash
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    ) : (
                      <FaEye
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-[#00AD51] mt-[50px] mb-2 text-[14px] font-[500] py-3 text-white  rounded-[100px] hover:bg-green-600"
              >
                Continue
              </button>
              <div className="flex justify-center">
                <p className="text-[#98A2B3] text-[12px]">
                  Already have an account?{" "}
                  <NavLink to="/login">
                    <span className="text-[13px] font-[500] text-[#00AD51] hover:underline">
                      Log in
                    </span>
                  </NavLink>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormWrapper>
  );
};

export default FirstLogin;
