import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { X, CalendarIcon } from "lucide-react";

export default function FilterDrawer({
  isOpen,
  onClose,
  onUpdateFilters,
  initialFilters = {},
}) {
  // State for all filter values
  const [dateRange, setDateRange] = useState({
    from: initialFilters.startDate || "",
    to: initialFilters.endDate || "",
  });
  const [status, setStatus] = useState({
    PSC: initialFilters.status?.includes("PSC") || false,
    JV: initialFilters.status?.includes("JV") || false,
  });
  const [selectedPermissions, setSelectedPermissions] = useState(
    initialFilters.permissions || []
  );

  const permissions = [
    { category: "Role", options: ["Supervisor", "Manager", "Support"] },
    { category: "Status", options: ["Clack", "Cable Ready", "Available now"] },
    {
      category: "Property Type",
      options: ["College", "Corporate", "Elevator", "Extra Storage"],
    },
    {
      category: "Amenities",
      options: ["High speed internet", "Garage", "Pet allowed"],
    },
  ];

  // Reset all filters to initial state
  const handleReset = () => {
    setDateRange({ from: "", to: "" });
    setStatus({ PSC: false, JV: false });
    setSelectedPermissions([]);

    // Notify parent component of reset
    onUpdateFilters({
      startDate: "",
      endDate: "",
      status: [],
      permissions: [],
    });
  };

  // Apply filters and close drawer
  const handleApplyFilters = () => {
    const activeStatus = Object.entries(status)
      .filter(([_, isChecked]) => isChecked)
      .map(([key]) => key);

    const filters = {
      startDate: dateRange.from,
      endDate: dateRange.to,
      status: activeStatus,
      permissions: selectedPermissions,
    };

    onUpdateFilters(filters);
    onClose();
  };

  // Validate and format date input
  const handleDateChange = (value, field) => {
    // Basic date validation (you can enhance this)
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (value && !dateRegex.test(value)) return;

    setDateRange((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Toggle permission selection
  const handlePermissionToggle = (permission) => {
    setSelectedPermissions((prev) => {
      const isSelected = prev.includes(permission);
      return isSelected
        ? prev.filter((p) => p !== permission)
        : [...prev, permission];
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel className="pointer-events-auto w-screen max-w-sm">
              <div className="flex h-full flex-col overflow-y-auto bg-white">
                {/* Header */}
                <div className="px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-xl font-semibold text-gray-900">
                      Filter
                    </DialogTitle>
                    <div className="flex items-center gap-4">
                      <button
                        onClick={handleReset}
                        className="text-green-600 hover:text-green-700 text-sm"
                      >
                        Reset all
                      </button>
                      <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <X className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="px-4 sm:px-6 flex-1">
                  {/* Date Range */}
                  <div className="mb-6">
                    <label className="text-sm text-gray-500">Date</label>
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div className="relative">
                        <input
                          type="date"
                          placeholder="YYYY-MM-DD"
                          value={dateRange.from}
                          onChange={(e) =>
                            handleDateChange(e.target.value, "from")
                          }
                          className="input input-bordered w-full pl-3 pr-10"
                        />
                        <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                      </div>
                      <div className="relative">
                        <input
                          type="date"
                          placeholder="YYYY-MM-DD"
                          value={dateRange.to}
                          onChange={(e) =>
                            handleDateChange(e.target.value, "to")
                          }
                          className="input input-bordered w-full pl-3 pr-10"
                        />
                        <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                      </div>
                    </div>
                  </div>

                  {/* Status */}
                  <div className="mb-6">
                    <label className="text-sm text-gray-500">Status</label>
                    <div className="space-y-2 mt-2">
                      {Object.keys(status).map((key) => (
                        <label
                          key={key}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type="checkbox"
                            checked={status[key]}
                            onChange={(e) =>
                              setStatus((prev) => ({
                                ...prev,
                                [key]: e.target.checked,
                              }))
                            }
                            className="checkbox checkbox-sm"
                          />
                          <span className="text-sm text-gray-700">{key}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Operator Permission */}
                  <div className="mb-6">
                    <label className="text-sm text-gray-500">
                      Operator Permission
                    </label>
                    <div className="mt-2">
                      {permissions.map((section, index) => (
                        <div key={index} className="mb-4">
                          <div className="flex flex-wrap gap-2">
                            {section.options.map((option, optionIndex) => {
                              const isSelected =
                                selectedPermissions.includes(option);
                              return (
                                <button
                                  key={optionIndex}
                                  onClick={() => handlePermissionToggle(option)}
                                  className={`px-4 py-2 rounded-full text-sm ${
                                    isSelected
                                      ? "bg-green-100 text-green-800"
                                      : "bg-gray-100 text-gray-800"
                                  } hover:bg-opacity-80 transition-colors`}
                                >
                                  {option}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Footer */}
                <div className="border-t border-gray-200 p-4">
                  <button
                    onClick={handleApplyFilters}
                    className="btn btn-primary w-full bg-green-600 hover:bg-green-700 border-none text-white"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
