import React from "react";
import { Search } from "lucide-react";
import { Reply } from "lucide-react";
import { Download } from "lucide-react";
import { formatDate } from "../../../utils/formatDate";

const STATUS_COLORS = {
  pending: "bg-[#ECF7FF] text-[#0070F9]",
  open: "bg-[#ECF7FF] text-[#0070F9]",
  closed: "bg-green-100 text-green-700",
  "high": "bg-[#FFF3F3] text-[#E80101]",
  "medium": "bg-[#FFF7FF] text-[#FF9B05]",
  "low": "bg-[#ECF7FF] text-[#0070F9]",
};

export const TicketStatus = ({ status, priority, department }) => (
  <div className="flex flex-wrap gap-2">
    {status && (
      <span
        className={`px-2 py-1 rounded-full capitalize text-[10px] font-medium ${STATUS_COLORS[status]}`}
      >
        {status}
      </span>
    )}
    {priority && (
      <span
        className={`px-2 py-1 rounded-full capitalize text-[10px] font-medium ${STATUS_COLORS[priority]}`}
      >
        {priority}
      </span>
    )}
    {department && (
      <span className="px-2 py-1 rounded-full capitalize text-[10px] font-medium bg-yellow-100 text-yellow-700">
        {department}
      </span>
    )}
  </div>
);

export const UserInitials = ({ name, className = "" }) => {
  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(" ");
    return nameArray.length === 1
      ? nameArray[0][0].toUpperCase()
      : nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase();
  };

  return (
    <div
      className={`w-8 h-8 rounded-full text-white flex items-center justify-center text-sm font-medium ${className}`}
    >
      {getInitials(name)}
    </div>
  );
};

export const TicketList = ({ tickets, ticketData }) => (
  <div className="flex flex-col gap-4">
    {tickets.map((ticket) => (
      <div key={ticket.id} className="border rounded-lg p-4">
        <div className="flex gap-4">
          <UserInitials name={ticketData.name} className="bg-green-500" />
          <div className="flex-1">
            <h3 className="font-medium">{ticket.title}</h3>
            <p className="text-sm text-gray-500">{ticketData.name}</p>
            <p className="text-sm text-gray-500 mt-2">{ticket.preview}</p>
            <div className="mt-2">
              <TicketStatus
                status={ticket.status}
                priority={ticket.priority}
                department={ticket.department}
              />
            </div>
          </div>
          <span className="text-sm text-gray-500">{ticket.time}</span>
        </div>
      </div>
    ))}
  </div>
);

export const FileAttachment = ({ file, showSharedWith = false }) => {
  const getTypeStyles = (type) => {
    switch (type) {
      case "PDF":
        return "bg-red-100 text-red-700";
      case "JPG":
        return "bg-purple-100 text-purple-700";
      default:
        return "bg-green-100 text-green-700";
    }
  };

  return (
    <div className="flex items-start gap-3">
      <span
        className={`px-2 py-1 rounded text-[10px] font-medium ${getTypeStyles(
          file.type
        )}`}
      >
        {file.type}
      </span>
      <div>
        <p className="text-sm font-[600]">{file.name}</p>
        {showSharedWith && (
          <p className="text-[10px] font-medium text-[#98A2B3]">
            Shared with {file.sharedWith}
          </p>
        )}
        <p className="text-[12px] text-[#98A2B3]">
          {file.date} • {file.time} • {file.size}
        </p>
      </div>
    </div>
  );
};

export const ClientInfo = ({ clientData }) => (
  <div className="rounded-[10px] border border-[#E5E7EB] p-4">
    <h2 className="text-lg font-medium mb-6">Client information</h2>
    <div className="space-y-2">
      <InfoRow label="Name" value={clientData.name} />
      <InfoRow label="Email" value={clientData.email} isEmail />
      <InfoRow label="Phone" value={clientData.phone} />
      <InfoRow
        label="Location"
        value={clientData.location}
        extra={
          <button className="text-[#0085FF] text-sm">(View on map)</button>
        }
      />
      <InfoRow label="Local time" value={clientData.localTime} />
      <InfoRow label="Company" value={clientData.company} />
    </div>
  </div>
);

const InfoRow = ({ label, value, isEmail, extra }) => (
  <div className="flex items-center justify-between">
    <p className="text-[12px] text-[#828282] mb-1">{label}</p>
    <div className="flex items-center gap-2">
      <p
        className={`font-medium text-[12px] ${isEmail ? "text-[#0085FF]" : ""}`}
      >
        {value}
      </p>
      {extra}
    </div>
  </div>
);

export const SharedFiles = ({ files }) => (
  <div className="mt-8 rounded-[10px] border border-[#E5E7EB] p-4">
    <div className="border-b border-[#E5E7EB] mb-4 p-2">
      <h2 className="text-[18px] font-[600]">File Shared</h2>
    </div>
    <div className="space-y-3">
      {files.map((file, index) => (
        <FileAttachment key={index} file={file} showSharedWith />
      ))}
      <button className="text-[#0085FF] text-sm">See more</button>
    </div>
  </div>
);

// File: components/support/TabButtons.jsx
export const TabButtons = ({ activeTab, setActiveTab, no }) => (
  <div className="flex gap-2 mb-4 bg-[#F6F8FC] border rounded-[5px] border-[#E5E7EB] w-full p-1">
    <button
      className={`px-4 py-2 w-full rounded-[3px] text-[12px] ${
        activeTab === 0 ? "bg-[#00AD51] text-white" : "bg-transparent"
      }`}
      onClick={() => setActiveTab(0)}
    >
      Open Tickets {activeTab === 0 && `(${no})`}
    </button>
    <button
      className={`px-4 py-2 w-full rounded-[3px] text-[12px] ${
        activeTab === 1
          ? "bg-[#00AD51] text-white"
          : "bg-transparent"
      }`}
      onClick={() => setActiveTab(1)}
    >
      Closed Tickets {activeTab === 1 && `(${no})`}
    </button>
  </div>
);

// File: components/support/SearchBar.jsx

export const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <div className="flex gap-2 mb-4">
    <div className="flex-1 relative">
      <Search className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <input
        type="text"
        placeholder="Search here..."
        className="w-full pl-10 pr-4 py-2 border rounded-lg"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
    <button className="flex items-center gap-2 px-3 py-2 border border-[#D0D5DD] rounded-lg text-sm">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 8H12M2 4H14M6 12H10"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Filter
    </button>
  </div>
);

// File: components/support/TicketHeader.jsx

export const TicketHeader = ({ setIsModalOpen, ticketData, showClientInfo, setShowClientInfo }) => (
  <div className="flex justify-between items-start mb-6">
    <div>
    <div className="flex gap-2">
                  <div className="w-10 h-10 rounded-full bg-green-500 text-white flex items-center justify-center text-sm font-medium">
                    {ticketData?.sender ? ticketData?.sender
                      .split(" ")
                      .map((n) => n[0])
                      .join("") : null}
                  </div>
                  <div>
                    <p onClick={() => { 
                      if(showClientInfo === ticketData  ){ 
                          setShowClientInfo("")
                      }else{
                        setShowClientInfo(ticketData)
                      }
                    }} className="text-sm cursor-pointer font-medium">
                      {ticketData?.sender}
                    </p>
                    <p className="text-xs text-gray-500">
                    {formatDate(ticketData?.created_datetime)}
                    </p>
                  </div>
                </div>
      <h2 className="text-[20px] capitalize font-semibold">{ticketData?.name}</h2>{/* 
      <h2 className="text-[15px] capitalize font-medium">{ticketData?.email}</h2> */}
      <TicketStatus
        status={ticketData?.status}
        priority={ticketData?.priority}
        department={ticketData?.department}
      />
    </div>
    <button
      onClick={() => setIsModalOpen(true)}
      className="flex items-center gap-2 bg-[#00AD51] text-white px-4 py-2 rounded-[30px]"
    >
      <Reply className="w-4 h-4" />
      Reply
    </button>
  </div>
);

// File: components/support/TicketContent.jsx
export const TicketContent = ({ ticketData }) => (
  <>
    <div className="flex justify-between items-start mb-6">
      <div className="flex gap-2">
        <UserInitials
          name={ticketData.name}
          className="bg-green-500 w-[40px] h-[40px]"
        />
        <div className="flex flex-col gap-0">
          <p className="text-[13px] font-medium">{ticketData.name}</p>
          <p className="text-[10px] text-[#828282]">23 Jun, 2:34pm</p>
        </div>
      </div>
    </div>

    <div className="prose max-w-none">
      <p className="text-[#4F4F4F] text-[12px] leading-[17.4px] mb-6">
        Lorem ipsum dolor sit amet consectetur. Risus lectus nulla libero
        mauris. Sit dolor malesuada velit at sed consequat libero. Turpis
        facilisi augue at integer. Urna lacus a duis mis sed augue cul. Ac
        rutrum suscipit id vita.
      </p>
      <p className="text-[#4F4F4F] text-[12px] leading-[17.4px] mb-6">
        Magna lobortis mauris accumsan consectetur. Molestie ultrices aliquet
        tortor sed bibendum lacus sit suscipit neque. Id tristique et sodales
        malesuada metus possere posuere ipsum. Tincidunt ornare felis et eu orci
        odio commodo in. Vel pretium urna quis suspendisse pharelius.
      </p>
    </div>
  </>
);

// File: components/support/TicketAttachments.jsx

export const TicketAttachments = ({ attachments }) => (
  <div className="mt-6">
    {attachments && ( <h3 className="font-medium mb-4 text-[13px] text-[#CCCCCC]">
      {attachments?.length} {attachments?.length === 1 ? "Attachment" : "Attachments" } 
    </h3>)}
    <div className="flex flex-col gap-2">
      {attachments?.map((file, index) => (
        <div key={index} className="flex items-center justify-between p-3">
          <FileAttachment file={file} />
          <Download className="w-4 h-4 text-gray-500 cursor-pointer" />
        </div>
      ))}
    </div>
  </div>
);

// File: components/support/TicketReply.jsx
export const TicketReply = ({ ticketData }) => (
  <div className="mt-6 border-t pt-6">
    <div className="bg-[#00AD510D] p-4">
      <div className="flex gap-1 items-start">
        <UserInitials
          name={ticketData?.sender}
          className="bg-green-500 p-4 w-[40px] h-[40px]"
        />
        <div className="">
          <div className="flex flex-wrap items-center  gap-1">
            <span className="font-medium text-[12px]">
              {ticketData?.sender} replied to
            </span>
            <span className="text-gray-700 text-[12px] px-2 py-1 rounded-[12px] bg-gray-200">
              {ticketData?.name} ({ticketData.ticket?.sender})
            </span>
          </div>
          <p className="text-sm text-gray-500">23 Jun, 2:34pm</p>
          
        </div>
      </div>
      <div className="w-full h-fit px-5 flex flex-col gap-3">
      <p className="mt-4 text-gray-600">
            {ticketData?.body}
          </p>
          <TicketAttachments attachments={ticketData?.attachments} />
      </div>
      
    </div>
  </div>
);
