import React from "react";
import { RadialBarChart, RadialBar, ResponsiveContainer } from "recharts";

const ActiveStatusChart = () => {
  const data = [
    {
      name: "Wells",
      value: 50,
      fill: "#00AD5126",
      angle: 180, // 50% of 360 degrees
    },
    {
      name: "Reservoir",
      value: 63,
      fill: "#00AD5180",
      angle: 226.8, // 63% of 360 degrees
    },
    {
      name: "Oil Fields",
      value: 79,
      fill: "#00AD51",
      angle: 284.4, // 79% of 360 degrees
    },
  ];

  return (
    <div className="bg-[#FFFFFF] rounded-[10px] border border-[#E5E7EB] p-2">
      <h2 className="flex justify-between w-full mb-3 text[18px] font-[600]">
        Active Status
      </h2>
      <div className="h-[250.63px] relative">
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx="50%"
            cy="50%"
            innerRadius="30%"
            outerRadius="100%"
            barSize={18}
            barGap={10}
            data={data}
            startAngle={180}
            endAngle={-120}
          >
            <RadialBar
              background={false}
              clockWise
              dataKey="angle"
              cornerRadius={20}
              angleAxisId={0}
            />
          </RadialBarChart>
        </ResponsiveContainer>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <span className="text-[25.11px] mt-2 font-[500]">140%</span>
        </div>
      </div>
      <div className="mt-4">
        {data.map((entry, index) => (
          <div
            key={entry.name}
            className="flex w-full justify-between items-center mt-2 px-8"
          >
            <div className="flex items-center gap-2 ">
              <div
                className="w-[4px] h-[28px] mr-2"
                style={{ backgroundColor: entry.fill }}
              ></div>
              <span className="mr-2 text-[#4E5969]">{entry.name}</span>
            </div>
            <span className="font-[500] text-[20px]">
              {entry.value}{" "}
              <span className="text-[#86909C] text-[14px]">%</span>{" "}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveStatusChart;
