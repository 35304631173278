import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GreenBtn from "../../../generalComponents/GreenBtn";
import Toggle from "./Toggle";
import { ChevronDownIcon, ChevronRightIcon } from "lucide-react";

const permissionCategories = [
  "Oil Field",
  "Reservoir",
  "Wells",
  "Rigs",
  "Gas Plant",
  "Pipeline",
  "Pipeline Network",
  "Terminal/(FSO)",
  "FPSO",
  "Last point",
  "Loading bouy",
  "Manifold",
  "Flow Station",
  "Platform",
];

const subPermissions = [
  "Permission 1",
  "Permission 2",
  "Permission 3",
  "Permission 4",
  "Permission 5",
  "Permission 1",
  "Permission 2",
  "Permission 3",
  "Permission 4",
  "Permission 5",
];

const AddOperatorForm = ({ onClose }) => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [permissions, setPermissions] = useState(
    permissionCategories.reduce((acc, category) => {
      acc[category] = {
        checked: false,
        subPermissions: subPermissions.reduce((subAcc, subPerm) => {
          subAcc[subPerm] = false;
          return subAcc;
        }, {}),
      };
      return acc;
    }, {})
  );

  const handleCategoryClick = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const handleCategoryToggle = (category) => {
    setPermissions((prev) => {
      const newState = { ...prev };
      const categoryState = !prev[category].checked;
      newState[category] = {
        checked: categoryState,
        subPermissions: Object.keys(prev[category].subPermissions).reduce(
          (acc, subPerm) => {
            acc[subPerm] = categoryState;
            return acc;
          },
          {}
        ),
      };
      return newState;
    });
  };

  // const handleSubPermissionToggle = (category, subPermission) => {
  //   setPermissions((prev) => {
  //     const newState = { ...prev };
  //     newState[category].subPermissions[subPermission] =
  //       !prev[category].subPermissions[subPermission];
  //     newState[category].checked = Object.values(
  //       newState[category].subPermissions
  //     ).every(Boolean);
  //     return newState;
  //   });
  // };

  const formik = useFormik({
    initialValues: {
      operatorName: "",
      email: "",
      alias: "",
      assetGroup: "",
      contractType: "JV", // default
      permissions: {
        oilField: false,
        reservoir: false,
        wells: false,
        // Add other fields as needed
      },
    },
    validationSchema: Yup.object({
      operatorName: Yup.string().required("Operator name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      alias: Yup.string().required("Alias is required"),
      assetGroup: Yup.string().required("Asset group is required"),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);
      onClose();
    },
  });

  return (
    <div className=" p-1 bg-white flex">
      <div className=" flex flex-col justify-start items-center p-1">
        <div className="flex items-center mb-6">
          <button
            onClick={onClose}
            className="inline-flex items-center text-[#344054] bg-[#FFFFFF] rounded-[4px] px-2 py-2 mr-3 border border-[#E4E7EC]"
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.146446 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146446 2.64645L2.14645 0.646447C2.34171 0.451184 2.65829 0.451184 2.85355 0.646447C3.04882 0.841709 3.04882 1.15829 2.85355 1.35355L1.70711 2.5L9.5 2.5C9.77614 2.5 10 2.72386 10 3C10 3.27614 9.77614 3.5 9.5 3.5L1.70711 3.5L2.85355 4.64645C3.04882 4.84171 3.04882 5.15829 2.85355 5.35355C2.65829 5.54882 2.34171 5.54882 2.14645 5.35355L0.146446 3.35355Z"
                fill="black"
              />
            </svg>
          </button>
          <p className="text-[12px] leading-[17px] text-[#667185]">Go Back</p>
        </div>
      </div>
      <div className="flex-1 ml-2 px-16">
        <h2 className="text-2xl font-bold mb-4">Add Operator</h2>
        <form className="p-8" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-4 p-2">
            {/* Operator Name */}
            <div className="mb-4">
              <label className="block text-[#181918] font-[500] leading-[18px] text-[12px]">
                Operator Name
              </label>
              <input
                type="text"
                name="operatorName"
                className="w-full p-2 border rounded-[5px] text-[12px] text-[#989898] font=[400] bg-[#F6F8FC]"
                placeholder="Enter operator name"
                value={formik.values.operatorName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.operatorName && formik.errors.operatorName ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.operatorName}
                </div>
              ) : null}
            </div>

            {/* Email Address */}
            <div className="mb-4">
              <label className="block text-[#181918] font-[500] leading-[18px] text-[12px]">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                className="w-full p-2 border rounded-[5px] text-[12px] text-[#989898] font=[400] bg-[#F6F8FC]"
                placeholder="Enter email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            {/* Alias */}
            <div className="mb-4">
              <label className="block text-[#181918] font-[500] leading-[18px] text-[12px]">
                Alias
              </label>
              <input
                type="text"
                name="alias"
                className="w-full p-2 border rounded-[5px] text-[12px] text-[#989898] font=[400] bg-[#F6F8FC]"
                placeholder="Enter alias"
                value={formik.values.alias}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.alias && formik.errors.alias ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.alias}
                </div>
              ) : null}
            </div>

            {/* Asset Groups */}
            <div className="mb-4">
              <label className="block text-[#181918] font-[500] leading-[18px] text-[12px]">
                Assign Asset Groups
              </label>
              <select
                name="assetGroup"
                className="w-full p-2 border rounded-[5px] text-[12px] text-[#989898] font=[400] bg-[#F6F8FC]"
                value={formik.values.assetGroup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select asset group</option>
                <option value="Group1">Group 1</option>
                <option value="Group2">Group 2</option>
              </select>
              {formik.touched.assetGroup && formik.errors.assetGroup ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.assetGroup}
                </div>
              ) : null}
            </div>
          </div>

          {/* Contract Type */}
          <div className="mb-10 pb-10 border-b border-[#E5E7EB] ">
            <label className="block text-[#181918] font-[500] leading-[18px] text-[12px] mb-2">
              Contract Type
            </label>
            <div className="flex items-center gap-24 ">
              <label className="mr-4 text-[12px]">
                <input
                  type="radio"
                  className="appearance-none mr-1 w-3 h-3 border border-gray-300 rounded-full checked:bg-[#00AD51] checked:border-transparent focus:outline-2 focus:ring-2 focus:ring-offset-1 focus:ring-[#00AD51]"
                  name="contractType"
                  value="JV"
                  checked={formik.values.contractType === "JV"}
                  onChange={formik.handleChange}
                />{" "}
                JV
              </label>
              <label className="text-[12px]">
                <input
                  type="radio"
                  className="appearance-none mr-1 w-3 h-3 border border-gray-300 rounded-full checked:bg-[#00AD51] checked:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-[#00AD51]"
                  name="contractType"
                  value="PSC"
                  checked={formik.values.contractType === "PSC"}
                  onChange={formik.handleChange}
                />{" "}
                PSC
              </label>
            </div>
          </div>

          {/* Permissions Section */}
          <div className="mb-4">
            <h3 className="text-[24px] font-[600] leading-[28px] ">
              Assign Operator's Permission
            </h3>
            <p className="text-[13px] text-[#667185] mb-4">
              Mark permissions to operators
            </p>

            {permissionCategories.map((category, index) => (
              <div
                key={category}
                className={`mb-2 ${
                  index !== permissionCategories.length - 1 ? "border-b" : ""
                } pb-2`}
              >
                <div className="flex items-center justify-between cursor-pointer">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-success checkbox-sm mr-2 border border-[#D0D5DD] rounded-[4px] checked:text-[#ffffff]"
                      checked={permissions[category].checked}
                      onChange={() => handleCategoryToggle(category)}
                    />
                    <span className="text-sm font-medium">{category}</span>
                  </div>
                  <button onClick={() => handleCategoryClick(category)}>
                    {expandedCategory === category ? (
                      <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                </div>
                {expandedCategory === category && (
                  <div className="ml-6 mt-2">
                    <div className="grid grid-cols-2 gap-4 p-2 relative">
                      {subPermissions.map((subPerm, subIndex) => (
                        <div
                          key={subPerm}
                          className="flex items-center justify-between"
                        >
                          <span className="text-[13px] text-[#828282]">
                            {subPerm}
                          </span>
                          <Toggle
                            labelText=""
                            checked={
                              permissions[category].subPermissions[subPerm]
                            }
                            onToggle={() => handleCategoryToggle(category)}
                          />
                        </div>
                      ))}
                      <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-200"></div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <GreenBtn className="w-full" type="submit">
            Add Operator
          </GreenBtn>
        </form>
      </div>
    </div>
  );
};

export default AddOperatorForm;
