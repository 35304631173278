import React, { useState, createContext, useContext } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <SidebarContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children}
    </SidebarContext.Provider>
  );
};

// custom hook to use sidebar context
export const useSidebar = () => useContext(SidebarContext);

export default SidebarContext;
