import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

// Chart Component
export const FacilitiesChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      barSize={32}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#e0e0e0" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="active" name="Active" fill="#10B981" />
      <Bar dataKey="active" name="Inactive" fill="#FCD34D" />
    </BarChart>
  </ResponsiveContainer>
);

export const facilitiesData = [
  { month: "Jan", active: 550, active: 700 },
  { month: "Feb", active: 300, active: 450 },
  { month: "Mar", active: 300, active: 450 },
  { month: "Apr", active: 300, active: 450 },
  { month: "May", active: 300, active: 450 },
  { month: "Jun", active: 300, active: 450 },
  { month: "Jul", active: 300, active: 450 },
  { month: "Aug", active: 300, active: 450 },
  { month: "Sep", active: 300, active: 450 },
  { month: "Oct", active: 300, active: 450 },
  { month: "Nov", active: 300, active: 450 },
  { month: "Dec", active: 300, active: 450 },
];
