import React from "react";

const InputWithFullBoarder = ({
  id,
  type,
  value,
  onChange,
  label,
  checked,
  onClick,
  className,
  hasSuffix,
  placeholder,
  row = "3",
  formik,
  formikFeedback,
  icon,
  accept,
  error,
  errorMessage,
  isTextArea = false,
  wrapperClassName,
  ...props
}) => {
  return (
    <div className={`flex flex-col mb-4 ${wrapperClassName}`}>
      <label
        className={`block ${error ? "text-red-600" : "text-[#181918]"} font-[500] leading-[18px] text-[12px]`}
        htmlFor={id}
      >
        {error ? errorMessage : label}
      </label>
      {isTextArea ? (
        <textarea
          id={id}
          className={`border border-lightGrey bg-lightGrey/30 p-2 rounded-md ${className} outline-none focus:outline-none `}
          cols="50"
          rows={row}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
        ></textarea>
      ) : hasSuffix ? (
        <div
          className={`border border-lightGrey bg-lightGrey/30 p-2 rounded-md placeholder:text-[12px] ${className} outline-none focus:outline-none flex items-center justify-between `}
        >
          <input
            onClick={onClick}
            type={type}
            placeholder={placeholder}
            id={id}
            accept={accept}
            checked={checked}
            color="white"
            value={value}
            onChange={onChange}
            {...props}
            className={` bg-transparent outline-none focus:outline-none w-full placeholder:text-[12px] mr-4 `}
          />{" "}
          <div>{icon}</div>
        </div>
      ) : (
        <input
          onClick={onClick}
          type={type}
          id={id}
          accept={accept}
          placeholder={placeholder}
          checked={checked}
          color="white"
          value={value}
          onChange={onChange}
          {...props}
          className={
            type !== "password" &&
            `${className} w-full p-2 border rounded-[5px] text-[12px] text-blackColor font=[400] bg-[#F6F8FC]`
          }
        />
      )}
      {formik ? (
        <div className="text-red-500 text-sm">{formikFeedback}</div>
      ) : null}
    </div>
  );
};

export default InputWithFullBoarder;
