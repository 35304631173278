import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateCompanyOilProductionManager } from "../controllers/createCompanyOilProduction";
import useGetCompanyOilProductionByIdManager from "../controllers/getCompanyOilProductionByIdController";
import { UpdateCompanyOilManager } from "../controllers/updatecompanyOilController";
import useGetAllClientsManager from "../../clientManagement/controllers/getClientsController";
import { FormLabel } from "@mui/material";
import { FormSelect } from "../../omls/components/FormComponents";

const validationSchema = Yup.object({
  company: Yup.string().required("Company is required"),
  date: Yup.date().required("Date is required"),
  oil_production: Yup.string().required("Oil Production is required"),
  oil: Yup.string().required("Oil is required"),
  planned_oil: Yup.string().required("Planned Oil is required"),
  condensate_blended: Yup.string().required("Condensate Blended is required"),
  planned_condensate_blended: Yup.string().required(
    "Planned Condensate Blended is required"
  ),
  condensate_unblended: Yup.string().required(
    "Condensate Unblended is required"
  ),
  planned_condensate_unblended: Yup.string().required(
    "Planned Condensate Unblended is required"
  ),
  ag: Yup.string().required("AG is required"),
  planned_ag: Yup.string().required("Planned AG is required"),
  nag: Yup.string().required("NAG is required"),
  planned_nag: Yup.string().required("Planned NAG is required"),
  bsw: Yup.string().required("BSW is required"),
});

const AddCompanyOilProduction = ({ isOpen, onClose, id }) => {
  const { createCompanyOilProduction, isLoading } =
    CreateCompanyOilProductionManager();
const {data} = useGetCompanyOilProductionByIdManager({
  id: id,
  enabled: Boolean(id)
})
const {updateCompanyOil, isLoading:updating, isSuccess} = UpdateCompanyOilManager({
  id: id
})
  const formik = useFormik({
    initialValues: {
      company: "",
      date: "",
      oil_production: "",
      oil: "",
      planned_oil: "",
      condensate_blended: "",
      planned_condensate_blended: "",
      condensate_unblended: "",
      planned_condensate_unblended: "",
      ag: "",
      planned_ag: "",
      nag: "",
      planned_nag: "",
      bsw: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if(id){
          await updateCompanyOil(values);
        }else{
          await createCompanyOilProduction(values);
        }
       
        onClose(); // Close modal after successful creation
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (id && data?.data) {
      
      
      // Set form values
      formik.setValues({
         
        company: data?.data?.company?.id || "",
        date: data?.data?.date || "",
        oil_production: data?.data?.oil_production || "",
        oil: data?.data?.oil || "",
        planned_oil: data?.data?.planned_oil || "",
        condensate_blended: data?.data?.condensate_blended || "",
        planned_condensate_blended: data?.data?.planned_condensate_blended || "",
        condensate_unblended: data?.data?.condensate_unblended || "",
        planned_condensate_unblended: data?.data?.planned_condensate_unblended || "",
        ag: data?.data?.ag || "",
        planned_ag: data?.data?.planned_ag || "",
        nag: data?.data?.nag || "",
        planned_nag: data?.data?.planned_nag || "",
        bsw: data?.data?.bsw || "",
      });
    }
  }, [id, data]);
  const { data: clientsData, isLoading: isClientsLoading } =
  useGetAllClientsManager({
    page: 1,
    searchQuery: "",
    enabled: true,
  });
  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title={`${id ? "Edit Company Oil Production" : "Create Company Oil Production"}`}
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-1 ">
          <FormLabel>Company</FormLabel>
          <FormSelect
            name="company"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company}
            options={[
              { value: "", label: "Select Company" },
              ...(clientsData?.data?.results?.map((client) => ({
                value: client.id,
                label: client.name,
              })) || []),
            ]}
            disabled={isClientsLoading}
          />
          {formik.touched.client && formik.errors.company && (
            <div className="text-red-500 text-sm">{formik.errors.company}</div>
          )}
        </div>

            <InputWithFullBoarder
              label="Date"
              id="date"
              name="date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && formik.errors.date}
              errorMessage={formik.errors.date}
            />

            <InputWithFullBoarder
              label="Oil Production"
              id="oil_production"
              name="oil_production"
              placeholder="Enter Oil Production"
              value={formik.values.oil_production}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.oil_production && formik.errors.oil_production
              }
              errorMessage={formik.errors.oil_production}
            />

            <InputWithFullBoarder
              label="Oil"
              id="oil"
              name="oil"
              placeholder="Enter Oil"
              value={formik.values.oil}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.oil && formik.errors.oil}
              errorMessage={formik.errors.oil}
            />

            <InputWithFullBoarder
              label="Planned Oil"
              id="planned_oil"
              name="planned_oil"
              placeholder="Enter Planned Oil"
              value={formik.values.planned_oil}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.planned_oil && formik.errors.planned_oil}
              errorMessage={formik.errors.planned_oil}
            />

            <InputWithFullBoarder
              label="Condensate Blended"
              id="condensate_blended"
              name="condensate_blended"
              placeholder="Enter Condensate Blended"
              value={formik.values.condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.condensate_blended &&
                formik.errors.condensate_blended
              }
              errorMessage={formik.errors.condensate_blended}
            />

            <InputWithFullBoarder
              label="Planned Condensate Blended"
              id="planned_condensate_blended"
              name="planned_condensate_blended"
              placeholder="Enter Planned Condensate Blended"
              value={formik.values.planned_condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_condensate_blended &&
                formik.errors.planned_condensate_blended
              }
              errorMessage={formik.errors.planned_condensate_blended}
            />

            <InputWithFullBoarder
              label="Condensate Unblended"
              id="condensate_unblended"
              name="condensate_unblended"
              placeholder="Enter Condensate Unblended"
              value={formik.values.condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.condensate_unblended &&
                formik.errors.condensate_unblended
              }
              errorMessage={formik.errors.condensate_unblended}
            />

            <InputWithFullBoarder
              label="Planned Condensate Unblended"
              id="planned_condensate_unblended"
              name="planned_condensate_unblended"
              placeholder="Enter Planned Condensate Unblended"
              value={formik.values.planned_condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_condensate_unblended &&
                formik.errors.planned_condensate_unblended
              }
              errorMessage={formik.errors.planned_condensate_unblended}
            />

            <InputWithFullBoarder
              label="AG"
              id="ag"
              name="ag"
              placeholder="Enter AG"
              value={formik.values.ag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ag && formik.errors.ag}
              errorMessage={formik.errors.ag}
            />

            <InputWithFullBoarder
              label="Planned AG"
              id="planned_ag"
              name="planned_ag"
              placeholder="Enter Planned AG"
              value={formik.values.planned_ag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.planned_ag && formik.errors.planned_ag}
              errorMessage={formik.errors.planned_ag}
            />

            <InputWithFullBoarder
              label="NAG"
              id="nag"
              name="nag"
              placeholder="Enter NAG"
              value={formik.values.nag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nag && formik.errors.nag}
              errorMessage={formik.errors.nag}
            />

            <InputWithFullBoarder
              label="Planned NAG"
              id="planned_nag"
              name="planned_nag"
              placeholder="Enter Planned NAG"
              value={formik.values.planned_nag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.planned_nag && formik.errors.planned_nag}
              errorMessage={formik.errors.planned_nag}
            />

            <InputWithFullBoarder
              label="BSW"
              id="bsw"
              name="bsw"
              placeholder="Enter BSW"
              value={formik.values.bsw}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bsw && formik.errors.bsw}
              errorMessage={formik.errors.bsw}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading || updating}
            buttonText={`${id ? "Edit Company Oil Production" : "Create Company Oil Production"}`}
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddCompanyOilProduction;
