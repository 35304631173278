import React from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from "@headlessui/react";
import { X } from "lucide-react";

const ProductDetailsModal = ({ isOpen, onClose, title,productData }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center">
        <DialogPanel className="w-full max-w-2xl bg-white rounded-lg p-6 text-left overflow-y-auto shadow-xl transform transition-all">
          {/* Close Button */}
          <div className="flex justify-between items-center border-b pb-3 mb-4">
            <DialogTitle className="text-xl font-semibold text-gray-900">
              {title}
            </DialogTitle>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>

          {/* Modal Content */}
          <DialogDescription className="space-y-6">
            <div className="flex flex-col gap-[15px]">
              {/* Repeatable Item Component */}
              {[
                ["Date", productData.date],,
                ["Activity", productData.activity],
                ["Cost ($)", productData.cost],
                ["Coverage (sq.km)", productData.coverage], 
                ["Remarks", productData.remarks],
              ].map(([label, value], index) => (
                <div key={index} className={`flex justify-between items-start ${label === "Remarks" && "flex-col gap-2"}`}>
                  <p className="text-sm font-medium text-black">{label}</p>
                  <p className="text-sm font-medium text-gray-500">
                    {value || "Info"}
                  </p>
                </div>
              ))}
            </div>

          
          </DialogDescription>

          {/* Close Button */}
          {/* <div className="mt-6 flex justify-end">
            <button
              type="button"
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={onClose}
            >
              Close
            </button>
          </div> */}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ProductDetailsModal;