import useUpdateManager from "../../../constants/controller_templates/put_controller_template";

export const UpdateTerminalProdManager = ({ id }) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/terminal-productions/${id}/`,
    ["updateTerminalProd"],
    false,
    true
  );
  const updateTerminalProd = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    updateTerminalProd,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
