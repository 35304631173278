import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

// Oil Production Chart Component
export const OilProductionChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#e0e0e0" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="blendedOil" name="Blended Oil" fill="#10B981" barSize={8} />
      <Bar
        dataKey="unblendedOil"
        name="Unblended Oil"
        fill="#FCD34D"
        barSize={8}
      />
    </BarChart>
  </ResponsiveContainer>
);

// Gas Production Chart Component
export const GasProductionChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      barGap={0}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#e0e0e0" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      {/* <Legend /> */}
      <Bar dataKey="flared" name="Flared" fill="#059669" barSize={6} />
      <Bar dataKey="fuel" name="Fuel" fill="#10B981" barSize={6} />
      <Bar dataKey="lng" name="LNG" fill="#FCD34D" barSize={6} />
      <Bar dataKey="egtl" name="EGTL" fill="#FBBF24" barSize={6} />
      <Bar dataKey="nglLpg" name="NGL/LPG" fill="#F59E0B" barSize={6} />
      <Bar dataKey="domSales" name="Dom Sales" fill="#D97706" barSize={6} />
      <Bar
        dataKey="gasReinjection"
        name="Gas reinjection/gas lift"
        fill="#B45309"
      />
    </BarChart>
  </ResponsiveContainer>
);

export const oilProductionData = [
  { month: "Jan", blendedOil: 550, unblendedOil: 700 },
  { month: "Feb", blendedOil: 300, unblendedOil: 450 },
  { month: "Mar", blendedOil: 300, unblendedOil: 450 },
  { month: "Apr", blendedOil: 300, unblendedOil: 450 },
  { month: "May", blendedOil: 300, unblendedOil: 450 },
  { month: "Jun", blendedOil: 300, unblendedOil: 450 },
  { month: "Jul", blendedOil: 300, unblendedOil: 450 },
  { month: "Aug", blendedOil: 300, unblendedOil: 450 },
  { month: "Sep", blendedOil: 300, unblendedOil: 450 },
  { month: "Oct", blendedOil: 300, unblendedOil: 450 },
  { month: "Nov", blendedOil: 300, unblendedOil: 450 },
  { month: "Dec", blendedOil: 300, unblendedOil: 450 },
];

export const gasProductionData = [
  {
    month: "Jan",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Feb",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Mar",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Apr",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "May",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Jun",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Jul",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Aug",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Sep",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Oct",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Nov",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
  {
    month: "Dec",
    flared: 620,
    fuel: 800,
    lng: 750,
    egtl: 680,
    nglLpg: 600,
    domSales: 550,
    gasReinjection: 700,
  },
];
