// src/components/OML/AddOMLModal/index.jsx
import React from "react";
import { X } from "lucide-react";
import OMLForm from "../components/OMLForm";
import { CreateOmlsManager } from "../controllers/createOmlsController";
import useGetSingleOML from "../controllers/getOMLController";
import { UpdateOMLsManager } from "../controllers/updateOMLsController";

const AddOMLModal = ({ open, onClose, omlId }) => {
  const { createOmls, isLoading, isSuccess, error } = CreateOmlsManager();
  const {updateOMLs, isLoading:updating, isSuccess:updated} = UpdateOMLsManager({id: omlId})
  const {data} = useGetSingleOML({id: omlId, enabled: true}) 
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white max-h-[80%] overflow-y-auto scrollbar-hide rounded-lg shadow-lg w-full max-w-4xl p-6 relative">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">{omlId ? "Edit OML" : "Add OML"} </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <p className="text-gray-600 mb-6">
          Enter the required information below to add a field
        </p>

        <OMLForm
        omlData={data}
        omlId={omlId}
          onClose={onClose}
          updateOMLs={updateOMLs}
          createOmls={createOmls}
          isLoading={isLoading || updating}
          isSuccess={isSuccess || updated}
        />

        {isSuccess && (
          <p className="text-green-500 mt-4">OML added successfully!</p>
        )}
        {error && <p className="text-red-500 mt-4">Error: {error.message}</p>}
      </div>
    </div>
  );
};

export default AddOMLModal;
