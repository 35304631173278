import React, { useEffect, useState } from "react";
// import DashboardTable from "./dashboard/DashboardTable";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle, upload } from "../../../assets/icons";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import useGetFlowStationProductionManager from "../controllers/getFlowStationProductionController";
import SearchField from "../../../generalComponents/SearchField";
import FilterButton from "../../../generalComponents/FilterButton";
import FileUpload from "../../../generalComponents/FileUpload";
import { useWellProductionUpload } from "../../hydroCarbon/controllers/uploadController";
import AddFlowstation from "./AddFlowStation";
import ExportToCSV from "../../../utils/downloadInCSV";

const FlowStationProductionSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const wellProductionUploadHook = useWellProductionUpload();
  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleUploadSuccess = (result, file) => {
    console.log("Upload successful", result);

    // refetch();

    handleCloseUploadModal();
  };

  const handleUploadError = (error) => {
    console.error("Upload failed", error);
  };

  const { data, isLoading } = useGetFlowStationProductionManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const headers = [
    "Name",
    "Date",
    "Produced (bbls)",
    "BSW (bbls)",
    "Condensate Blended (bbls)",
    "Condensate Unblended (bbls)",
    "Action",
  ];

  const options = ["View Production", "Edit Production", "Delete Production"];

  const getFormattedValue = (el, index) => {
    return [
      el?.name,
      formatDate(el?.created_datetime),
      el?.email,
      el?.role || "N/A",
      formatDate(el?.created_datetime),
    ];
  };
  const handleAddModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-between pt-3 px-4">
            <div className="flex justify-start items-center gap-1">
              <div className="w-[70%]">
                <SearchField
                  searchValue={searchValue}
                  searchOnchange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <FilterButton />
            </div>
            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} onClick={() => ExportToCSV(data?.data?.results, "FLOWSTATION PRODUCTION")} icon={download} />
              <GreenBtn
                onClick={handleOpenUploadModal}
                icon={upload}
                text={"Upload Production"}
              />
              <GreenBtn
                onClick={handleAddModal}
                icon={plusCircle}
                text={"Add Production"}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => {
                console.log(option, index, val);

                if (index === 2) {
                  console.log(val.id);
                  // setSelectedClient(val.id);
                  // setShowAddClient(true);
                  // navigate(`?clientId=${val.id}`);
                }
              }}
              // Close popup function
            />
          </div>

          {/* Pagination */}
          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* <ClientTable /> */}
        </div>
      </>
      {/* Add Flowstation Modal */}
      <AddFlowstation isOpen={isOpen} onClose={handleCloseModal} />
      {/* Upload Modal */}

      <FileUpload
        title={"Upload FlowStation Production"}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        uploadHook={wellProductionUploadHook}
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        additionalData={
          {
            // Any additional metadata you want to send with the upload
            // For example:
            // year: new Date().getFullYear(),
            // source: 'web-upload'
          }
        }
      />
    </div>
  );
};

export default FlowStationProductionSection;
