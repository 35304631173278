import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateGasPlantsProductionManager } from "../controllers/createGasPlantsController";
import useGetGasPlantsManager from "../controllers/getGasPlantsController";
import { FormLabel } from "@mui/material";
import { FormSelect } from "../../omls/components/FormComponents";
import useGetGasPlantProductionManager from "../controllers/getGasPlantProducController";
import { UpdateGasPlantManager } from "../controllers/updateGasPlantController";

const validationSchema = Yup.object({
  gas_plant: Yup.string().required("Gas Plant is required"),
  date: Yup.date().required("Date is required"),
  flarred_gas: Yup.number().required("Flared Gas is required"),
  fuel_gas: Yup.number().required("Fuel Gas is required"),
  lng: Yup.number().required("LNG is required"),
  ag: Yup.number().required("AG is required"),
  egtl: Yup.number().required("EGTL is required"),
  nlg: Yup.number().required("NLG is required"),
  dom_sales: Yup.number().required("Domestic Sales is required"),
  nag: Yup.number().required("NAG is required"),
  gas_reinjection: Yup.number().required("Gas Reinjection is required"),
});

const AddGasPlantProduction = ({ isOpen, onClose, id }) => {
  const { createGasPlantsProduction, isLoading } =
    CreateGasPlantsProductionManager();
    const {data} = useGetGasPlantProductionManager({id: id, enabled: Boolean(id)}) 
  const { data: gasPlantData, isLoading: isFieldsLoading } =
    useGetGasPlantsManager({
      page: 1,
      searchQuery: "",
      enabled: true,
    });
  const {updateGasPlant, isLoading: updating, isSuccess} = UpdateGasPlantManager({id: id})
  const formik = useFormik({
    initialValues: {
      gas_plant: "",
      date: "",
      flarred_gas: "",
      fuel_gas: "",
      lng: "",
      ag: "",
      egtl: "",
      nlg: "",
      dom_sales: "",
      nag: "",
      gas_reinjection: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if(id){
          await updateGasPlant(values);
        }{
          await createGasPlantsProduction(values);
        }
        
        onClose(); // Close modal after successful creation
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (id && data?.data) {
      
      
      // Set form values
      formik.setValues({
         
        gas_plant: data?.data?.gas_plant || "",
        date: data?.data?.date || "",
        flarred_gas: data?.data?.flarred_gas || "",
        fuel_gas: data?.data?.fuel_gas || "",
        lng: data?.data?.lng || "",
        ag: data?.data?.ag || "",
        egtl: data?.data?.egtl || "",
        nlg: data?.data?.nlg || "",
        dom_sales: data?.data?.dom_sales || "",
        nag: data?.data?.nag || "",
        gas_reinjection: data?.data?.gas_reinjection || "",
      });
    }
  }, [id, data]);

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title={`${id ? "Edit Gas Plant Production" : "Create Gas Plant Production"}`}
      maxWidth="lg"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <FormLabel>Gas Plants</FormLabel>
              <FormSelect
                label="Gas Plants"
                id="gas_plant"
                name="gas_plant"
                placeholder="Enter Gas Plant"
                value={formik.values.gas_plant}
                options={[
                  { value: "", label: "Select field" },
                  ...(gasPlantData?.data?.results?.map((gasPlant) => ({
                    value: gasPlant.id,
                    label: gasPlant.name,
                  })) || []),
                ]}
                disabled={isFieldsLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.field && formik.errors.gas_plant}
                errorMessage={formik.errors.gas_plant}
              />
            </div>

            <InputWithFullBoarder
              label="Date"
              id="date"
              name="date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && formik.errors.date}
              errorMessage={formik.errors.date}
            />

            <InputWithFullBoarder
              label="Flared Gas"
              id="flarred_gas"
              name="flarred_gas"
              placeholder="Enter Flared Gas"
              value={formik.values.flarred_gas}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.flarred_gas && formik.errors.flarred_gas}
              errorMessage={formik.errors.flarred_gas}
            />

            <InputWithFullBoarder
              label="Fuel Gas"
              id="fuel_gas"
              name="fuel_gas"
              placeholder="Enter Fuel Gas"
              value={formik.values.fuel_gas}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fuel_gas && formik.errors.fuel_gas}
              errorMessage={formik.errors.fuel_gas}
            />

            <InputWithFullBoarder
              label="LNG"
              id="lng"
              name="lng"
              placeholder="Enter LNG"
              value={formik.values.lng}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lng && formik.errors.lng}
              errorMessage={formik.errors.lng}
            />

            <InputWithFullBoarder
              label="AG"
              id="ag"
              name="ag"
              placeholder="Enter AG"
              value={formik.values.ag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ag && formik.errors.ag}
              errorMessage={formik.errors.ag}
            />

            <InputWithFullBoarder
              label="EGTL"
              id="egtl"
              name="egtl"
              placeholder="Enter EGTL"
              value={formik.values.egtl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.egtl && formik.errors.egtl}
              errorMessage={formik.errors.egtl}
            />

            <InputWithFullBoarder
              label="NLG"
              id="nlg"
              name="nlg"
              placeholder="Enter NLG"
              value={formik.values.nlg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nlg && formik.errors.nlg}
              errorMessage={formik.errors.nlg}
            />

            <InputWithFullBoarder
              label="Domestic Sales"
              id="dom_sales"
              name="dom_sales"
              placeholder="Enter Domestic Sales"
              value={formik.values.dom_sales}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.dom_sales && formik.errors.dom_sales}
              errorMessage={formik.errors.dom_sales}
            />

            <InputWithFullBoarder
              label="NAG"
              id="nag"
              name="nag"
              placeholder="Enter NAG"
              value={formik.values.nag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nag && formik.errors.nag}
              errorMessage={formik.errors.nag}
            />

            <InputWithFullBoarder
              label="Gas Reinjection"
              id="gas_reinjection"
              name="gas_reinjection"
              placeholder="Enter Gas Reinjection"
              value={formik.values.gas_reinjection}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.gas_reinjection && formik.errors.gas_reinjection
              }
              errorMessage={formik.errors.gas_reinjection}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading || updating}
            buttonText={`${id ? "Edit Gas Plant Production" : "Create Gas Plant Production"}`}
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddGasPlantProduction;
