import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const CreateClientManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/clients",
    ["clients_create_created"],
    true
  );
  const createClient = async (details) => {
    try {
       
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createClient,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
