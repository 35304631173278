import React from "react";
import { X } from "lucide-react";

const OilFieldModal = ({ isOpen, onClose, fieldData }) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white rounded-lg w-full max-w-md mx-4 relative p-4">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-[15px] font-500">OML Details</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Title Banner */}
        {/* <div className="bg-[#00AD51] rounded-[5px] p-4 text-white text-[24px] font-600">
          {fieldData?.name || "Niger #454"}
        </div> */}

        {/* Content */}
        <div className="p-4 ">
          <div className=" space-y-1">
            {[
              { label: "Size", value: fieldData?.size || "info" },
              { label: "OML", value: fieldData?.oml || "info" },
              {
                label: "Shore Type",
                value: fieldData?.shoreType || "info",
                // badge: true,
                // color: "text-blue-600 bg-blue-50",
              },
              {
                label: "Status",
                value: fieldData?.status || "info",
                // badge: true,
                // color: "text-green-600 bg-green-50",
              },
              { label: "Location", value: fieldData?.location || "info" },
              { label: "Terrain", value: fieldData?.terrain || "info" },
              { label: "Longitude", value: fieldData?.longitude || "info" },
              { label: "Latitude", value: fieldData?.latitude || "info" },
              {
                label: "Longitude Summary",
                value: fieldData?.longitudeSummary || "Info",
              },
              {
                label: "Latitude Summary",
                value: fieldData?.latitudeSummary || "Info",
              },
              {
                label: "Date Discovered",
                value: fieldData?.dateDiscovered || "info",
              },
              {
                label: "Date Drilled",
                value: fieldData?.dateDrilled || "info",
              },
              {
                label: "Date of Exploration Completion",
                value: fieldData?.explorationDate || "info",
              },
              { label: "Area", value: fieldData?.area || "Info" },
              {
                label: "Structural Geology",
                value: fieldData?.geology || "Info",
              },
              {
                label: "Oil Ultimate Recovery",
                value: fieldData?.oilRecovery || "Info",
              },
              {
                label: "Gas Ultimate Recovery",
                value: fieldData?.gasRecovery || "Info",
              },
              {
                label: "Condensate Ultimate Recovery",
                value: fieldData?.condensateRecovery || "Info",
              },
            ].map((item, index) => (
              <div key={index} className="flex justify-between items-center">
                <span className="text-[#181918] text-[12px]">{item.label}</span>
                {item.badge ? (
                  <span
                    className={`px-3 py-1 rounded-full text-[12px] ${item.color}`}
                  >
                    {item.value}
                  </span>
                ) : (
                  <span className="text-[#828282] text-[12px]">
                    {item.value}
                  </span>
                )}
              </div>
            ))}
          </div>

          {/* Reservoir Description */}
          <div className="space-y-2">
            <h3 className="text-[#181918] text-[12px]">
              Reservoir Description
            </h3>
            <p className="text-[12px] text-[#828282]">
              {fieldData?.description ||
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem vitae morbi eros purus. Ornare diam sed odio lacus urna arcu aliquam."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OilFieldModal;
