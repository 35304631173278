import React from "react";
import { calculatePaginationRange } from "../utils/calculatePaginationRange";
import TransparentBtn from "./TransparentButton";

const NUIMSPagination = ({
  pagination,
  currentPage,
  setCurrentPage,
  type = "Clients",
}) => {
  const { startItem, endItem } = calculatePaginationRange(pagination);
  // const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="flex items-center justify-between space-x-4 py-3 border-t border-[#E4E7EC] px-[18px]">
      <span className="text-[12px] text-[#667185] leading-[17.4px]">
        Page {currentPage} of {pagination?.total_pages} {type}
      </span>

      {/* Pagination Links */}
      <div className="hidden md:flex items-center text-[14px]">
        {Array.from({ length: pagination?.total_pages }, (_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`inline-flex items-center rounded-[8px] p-[8px] text-sm font-medium ${
              currentPage === index + 1
                ? "bg-[#E7F6EC] text-[#00AD51]"
                : "text-[#344054]"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Previous and Next Buttons */}
      <div className="flex space-x-2">
        <TransparentBtn
          className="text-[12px] text-[#344054] rounded-[8px] shadow-custom-1 hover:shadow-custom-2"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          text="Previous"
        />
        <TransparentBtn
          className="text-[12px] text-[#344054] rounded-[8px] shadow-custom-1 hover:shadow-custom-2"
          onClick={() =>
            setCurrentPage((prev) =>
              Math.min(prev + 1, pagination?.total_pages)
            )
          }
          text="Next"
        />
      </div>
    </div>
  );
};

export default NUIMSPagination;
