import React from "react";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";

const StatusButton = ({ status }) => {
  let styles = {};

  switch (status.toLowerCase()) {
    case "pending":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "Pending",
      };
      break;
    case "declared":
      styles = {
        backgroundColor: "#ECFDF3",
        color: "#358619",
        text: "Declared",
      };
      break;
    case "in progress":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "In Progress",
      };
      break;
    case "active":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Active",
      };
      break;
    case "cancelled":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Cancelled",
      };
      break;
      case "inactive":
        styles = {
          backgroundColor: "#F8D7DA",
          color: "#721C24",
          text: "Inactive",
        };
        break;
        case "sold":
          styles = {
            backgroundColor: "#F8D7DA",
            color: "#721C24",
            text: "Sold",
          };
          break;
    default:
      styles = {
        backgroundColor: "#E2E3E5",
        color: "#383D41",
        text: capitalizeFirstLetter(status),
      };
      break;
  }

  return (
    <button
      className="text-12px rounded-[20px] px-5 py-1"
      style={{ backgroundColor: styles.backgroundColor, color: styles.color }}
    >
      {styles.text}
    </button>
  );
};

export default StatusButton;
