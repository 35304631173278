import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";

export const DeleteTerminalManager = ({ id }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/terminal-productions/${id}/`,
    ["delete_terminal-productions"],
    false,
    true
  );
  const deleteTerminal = async (details) => {
    console.log(`this is the licnet ${id}`);
    try {
      await deleteCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteTerminal,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
