import React from "react";

const CustomButton = ({
  buttonText,
  textColor,
  className,
  onClick,
  buttonColor,
  radius,
  isLoading,
  type,
  loader,
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        buttonColor ?? "bg-brandBackground"
      } py-[2px] md:py-[3px] px-[14px] md:px-[25px]  text-[11px] md:text-[14px] h-[38px] md:h-[50px] font-medium  hover:shadow-xl hover:scale-y-105 duration-300 ${
        !textColor ? "text-whiteColor" : textColor
      } rounded-${radius ?? "[100px]"} ${className}         ${
        disabled
          ? "opacity-60 cursor-not-allowed hover:shadow-none hover:scale-y-100"
          : ""
      }`}
      disabled={disabled}
      {...props}
    >
      {isLoading ? loader ?? "loading..." : buttonText}
    </button>
  );
};

export default CustomButton;
