import React from "react";
import AssetContainer from "./dashboard/AssetContainer";
import DashboardList from "./dashboard/DashboardList";
import DashboardTable from "./dashboard/DashboardTable";
import ProductionContainer from "./dashboard/ProductionContainer";
import ActiveStatusChart from "./dashboard/RadialChart";

const Dashboard = () => {
  return (
    <div className="bg-white rounded-[10px] p-4 gap-4 grid grid-cols-3">
      <div className="col-span-2">
        <h1 className="font-[600] text-[24px] mb-2 w-full"> Dashboard</h1>
        <DashboardList />
        <AssetContainer />
        <DashboardTable />
      </div>
      <div className="flex flex-col gap-4">
        <ActiveStatusChart />
        <ProductionContainer />
      </div>
    </div>
  );
};

export default Dashboard;
