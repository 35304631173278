import React from "react";
import { X } from "lucide-react";
import useGetSingleOML from "../controllers/getOMLController";

const OmlDetails = ({ isOpen, onClose, omlId }) => {
  const { data } = useGetSingleOML({ id: omlId, enabled: true });
  if (!isOpen) return null;

  const detailFields = [
    { id: "company", label: "Company", value: data?.data?.client?.name },
    { id: "omlNumber", label: "OML Number", value: data?.data?.number },
    { id: "area", label: "Area", value: data?.data?.area },
    { id: "basinName", label: "Basin Name", value: data?.data?.basil_name },
    { id: "opl", label: "OPL", value: data?.data?.opl },
    {
      id: "applicationDate",
      label: "Application Date",
      value: data?.data?.application_date,
    },
    {
      id: "oplAwardDate",
      label: "OPL Award Date",
      value: data?.data?.opl_award_date,
    },
    {
      id: "oplBlockSize",
      label: "OPL Block Size",
      value: data?.data?.opl_block_size,
    },
    {
      id: "onshoreSize",
      label: "Onshore Size",
      value: data?.data?.onshore_size,
    },
    { id: "shelfSize", label: "Shelf Size", value: data?.data?.shelf_size },
    {
      id: "deepWaterSize",
      label: "Deep Water Size",
      value: data?.data?.deep_water_size,
    },
    { id: "omlClass", label: "OML Class", value: data?.data?.oml_class },
    {
      id: "minWaterDepth",
      label: "Min Water Depth",
      value: data?.data?.min_water_depth,
    },
    {
      id: "maxWaterDepth",
      label: "Max Water Depth",
      value: data?.data?.max_water_depth,
    },
    {
      id: "medWaterDepth",
      label: "Med Water Depth",
      value: data?.data?.median_water_depth,
    },
    { id: "lng", label: "Lng", value: data?.data?.lng },
    { id: "lat", label: "Lat", value: data?.data?.lat },
    { id: "lngSummary", label: "Lng Summary", value: data?.data?.lng_summary },
    { id: "latSummary", label: "Lat Summary", value: data?.data?.lat_summary },
    { id: "x", label: "X", value: data?.data?.x },
    { id: "y", label: "Y", value: data?.data?.y },
    {
      id: "oplRightType",
      label: "OPL Right Type",
      value: data?.data?.opl_right_type,
    },
  ];

  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 overflow-y-auto p-4"
    >
      <div
        className="bg-white rounded-lg w-full max-w-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-[16px] font-medium text-gray-900">OML Details</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close dialog"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-hide">
          <div className="space-y-4">
            {detailFields.map((field) => (
              <div
                key={field.id}
                className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0"
              >
                <span className="text-[14px] text-gray-900 font-medium">
                  {field.label}
                </span>
                <span className="text-[14px] text-gray-500">
                  {field.value || "Info"}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OmlDetails;
