import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader2, X } from "lucide-react"; 
import { AddProductManager } from "../../modules/lifting/controllers/addProduct";
import AddComponent from "../AddComponent";

const validationSchema = Yup.object({ 
  name: Yup.string().required("Name is required"),
  density: Yup.string().required("Density is required"),
  description: Yup.string().required("Description is required"),
  consumption_per_day: Yup.string().required("Consumption Per Day is required"),
  quality_name: Yup.string().required("Quality Name is required"),
  quality_value: Yup.string().required("Quality Value is required"),
   
});

const AddProducts = ({ open, onClose }) => {
  const { addProduct, isLoading, error } = AddProductManager(); 
  
  const formik = useFormik({
    initialValues: {
      name: "",
      density: "",
      description: "",
      consumption_per_day: "",
      quality_name: "",
      quality_value: "", 

    },
    validationSchema,

    onSubmit: async (values) => {
      
      await addProduct(values);
      if (!error) {
        onClose();
        formik.resetForm();
      }
    },
  });

  return open ? (
    <div  className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6  w-full rounded-[10px] h-full overflow-y-auto">
          <div
            onClick={(e) => e.target === e.currentTarget && onClose()}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 overflow-y-auto scrollbar-hide max-h-[80%]">
              
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-xl font-bold ">Add Product</h2>
                  <h2 className="font-normal text-[13px] text-[#667185] ">Enter the required information below to add product</h2>
                </div>
              
              
                  <button
                    onClick={() => {onClose() }}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                  

               <AddComponent formik={formik} data={[
                      { name: "name", label: "Name", type: "text", span: "2",placeholder: "Enter name" },
                      { name: "density", label: "Density", type: "text", span: "1",placeholder: "Enter density" },
                      { name: "description", label: "Consumption per day", type: "text", span: "1",placeholder: "Enter consumption" }, 
                      { name: "consumption_per_day", label: "Quality name", type: "text", span: "1",placeholder: "Enter name" },
                      { name: "quality_name", label: "Quality Value", type: "text", span: "1",placeholder: "Enter value" }, 
                      { name: "quality_value", label: "Description", type: "text", span: "2",placeholder: "Enter description" }, 
                      
                    ]} />

            
                {/* Action Buttons */}
                <div className="flex justify-end space-x-4">
                  
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`px-4 py-2 w-full rounded-[40px] flex items-center justify-center ${
                      isLoading ? "bg-gray-400" : "bg-brandGreen text-white"
                    } flex items-center`}
                  >
                    {isLoading && <Loader2 className="animate-spin mr-2" />}
                    {isLoading ? "Adding..." : "Add Product"}
                  </button>
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
  ) : null;
};

export default AddProducts;
