import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const useFileUploadManager = ({
  endpoint,
  queryKey = ["uploads"],
  isAuth = true,
  transformData = (data) => data, // possible future data transformation
}) => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    endpoint,
    queryKey,
    isAuth
  );

  const uploadFile = async (file) => {
    try {
      if (!(file instanceof File)) {
        console.error("Invalid file object:", file);
        throw new Error("Invalid file object");
      }

      const formData = new FormData();
      formData.append("file", file, file.name);

      for (let [key, value] of formData.entries()) {
        console.log(`FormData - ${key}:`, value);
      }

      const result = await postCaller(formData);
      return transformData(file);
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  };

  return {
    uploadFile,
    data,
    isLoading,
    isSuccess,
    error,
  };
};

// Specific upload managers for different endpoints
export const useWellProductionUpload = (options = {}) =>
  useFileUploadManager({
    endpoint: "/well-productions/upload/",
    queryKey: ["well-productions"],
    ...options,
  });

  export const useFPSOProductionUpload = (options = {}) =>
    useFileUploadManager({
      endpoint: "/fpso-productions/upload/",
      queryKey: ["fpso-productions-upload"],
      ...options,
    });
    export const useCompanyOilProductionUpload = () =>
      useFileUploadManager({
        endpoint: "/company-oil-productions/upload/",
        queryKey: ["company-oil-productions-upload"],
      });
      export const useCompanyGasProductionUpload = () =>
        useFileUploadManager({
          endpoint: "/company-gas-productions/upload/",
          queryKey: ["company-gas-productions-upload"],
        });
        export const useGasPlantProductionUpload = () =>
          useFileUploadManager({
            endpoint: "/gas-plants-productions/upload/",
            queryKey: ["gas-plants-productions-upload"],
          });
export const useTerminalProductionUpload = () =>
  useFileUploadManager({
    endpoint: "/terminal-productions/upload/",
    queryKey: ["terminal-productions-upload"],
  });
export const useFieldProductionUpload = () =>
  useFileUploadManager({
    endpoint: "/field-productions/upload/",
    queryKey: ["field-productions-upload"],
  });

export const useTerminalProductionByCompanyUpload = () =>
  useFileUploadManager({
    endpoint: "/api/v1/terminal-productions/by-company/upload/",
    queryKey: ["terminal-productions-by-company-upload"],
  });
