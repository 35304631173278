import React from "react";
import AnalyticsChart from "./AnalyticsChart";
// import AssetBarchart from "./AssetBarchart";

const AnalyticsContainer = () => {
  return (
    <div className="p-2">
      <div className="flex justify-between w-full mb-3">
        <div className="flex flex-col">
          <h2 className="text-[24px] leading-[28.8px] font-[600]">
            Activity Status
          </h2>
        </div>
        {/* dropdown */}
        <div className="dropdown dropdown-end border border-[#D0D5DD] rounded-[6px]">
          <label
            tabIndex={0}
            className="btn btn-ghost btn-sm text-[12px] leading-4 space-x-3 font-[600] text-[#344054] "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.66675 0.833252C7.12699 0.833252 7.50008 1.20635 7.50008 1.66659V2.49992H12.5001V1.66659C12.5001 1.20635 12.8732 0.833252 13.3334 0.833252C13.7937 0.833252 14.1667 1.20635 14.1667 1.66659V2.49992H15.0001C16.841 2.49992 18.3334 3.9923 18.3334 5.83325V14.9999C18.3334 16.8409 16.841 18.3333 15.0001 18.3333H5.00008C3.15913 18.3333 1.66675 16.8409 1.66675 14.9999V5.83325C1.66675 3.9923 3.15913 2.49992 5.00008 2.49992H5.83341V1.66659C5.83341 1.20635 6.20651 0.833252 6.66675 0.833252ZM12.5001 4.16659C12.5001 4.62682 12.8732 4.99992 13.3334 4.99992C13.7937 4.99992 14.1667 4.62682 14.1667 4.16659H15.0001C15.9206 4.16659 16.6667 4.91278 16.6667 5.83325V6.24992H3.33341V5.83325C3.33341 4.91278 4.07961 4.16659 5.00008 4.16659H5.83341C5.83341 4.62682 6.20651 4.99992 6.66675 4.99992C7.12699 4.99992 7.50008 4.62682 7.50008 4.16659H12.5001ZM16.6667 7.91658H3.33341V14.9999C3.33341 15.9204 4.07961 16.6666 5.00008 16.6666H15.0001C15.9206 16.6666 16.6667 15.9204 16.6667 14.9999V7.91658Z"
                fill="#344054"
              />
            </svg>
            2024
            <svg
              width="13"
              height="7"
              viewBox="0 0 13 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.759148 1.95513L5.6433 6.55197C6.12461 7.00497 6.87539 7.00497 7.35671 6.55197L12.2409 1.95513C12.4922 1.71855 12.5042 1.32301 12.2676 1.07165C12.0311 0.820291 11.6355 0.808305 11.3841 1.04488L6.5 5.64172L1.61586 1.04488C1.3645 0.808304 0.968949 0.82029 0.732377 1.07165C0.495804 1.32301 0.50779 1.71855 0.759148 1.95513Z"
                fill="#667185"
              />
            </svg>
          </label>
        </div>
      </div>
      <div className="w-full h-[280px] bg-[#FFFFFF] rounded-[10px] border border-[#E5E7EB] p-1">
        <AnalyticsChart />
      </div>
    </div>
  );
};

export default AnalyticsContainer;
