import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader2, X } from "lucide-react";  
import { duration } from "@mui/material";
import CalendarPicker from "../CalendarPicker";
import AddComponent from "../AddComponent";

const validationSchema = Yup.object({ 
  name: Yup.string().required("Name is required"),
  operator: Yup.string().required("Operator is required"),
  field: Yup.string().required("Field is required"),
  activity: Yup.string().required("Activity is required"),
  funding: Yup.string().required("Funding is required"),
  plan: Yup.string().required("Plan is required"),
  
  budget: Yup.string().required(" Budget is required"),
  exploration_start_date: Yup.string().required("Exploration Start Date is required"),
  exploration_end_date: Yup.string().required("Exploration End Date is required"), 
   
});

const AddExploration = ({ open, onClose }) => { 
  const [isLoading, setIsLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      operator: "",
      field: "",
      activity: "",
      funding: "",
      plan: "",
      budget: "",
      exploration_start_date: "",
      exploration_end_date: "", 

    },
    validationSchema,

    onSubmit: async (values) => {
       console.log(values)
    },
  });

  return open ? (
    <div  className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6  w-full rounded-[10px] h-full overflow-y-auto">
          <div
            onClick={(e) => e.target === e.currentTarget && onClose()}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 overflow-y-auto scrollbar-hide max-h-[80%]">
              
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-xl font-bold ">Add Exploration</h2>
                  <h2 className="font-normal text-[13px] text-[#667185] ">Enter the required information below to add exploration</h2>
                </div>
              
              
                  <button
                    onClick={() => {onClose() }}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                  
 
                <AddComponent data={[
                      { name: "name", label: "Exploration Name", type: "text", span: "2", placeholder: "Enter name" },
                      { name: "operator", label: "Operator ", type: "select", span: "1", placeholder: "Enter Operator" },
                      { name: "field", label: "Field", type: "select", span: "1", placeholder: "Enter Field" },
                      { name: "activity", label: "Activity", type: "select", span: "2",placeholder: "Enter Activity" },
                      { name: "funding", label: "Funding", type: "text", span: "1", placeholder: "Enter Funding" },
                      { name: "plan", label: "Plan (sq.km)", type: "text", span: "1", placeholder: "Enter Plan" }, 
                      { name: "budget", label: "Budget ($)", type: "text", span: "1", placeholder: "Enter Budget" },
                      { name: "duration", label: "Duration", type: "time", span: "1", placeholder: "Enter value" },  
                      
                    ]} start_date={"exploration_start_date"} formik={formik} end_date={"exploration_end_date"} />
            
                {/* Action Buttons */}
                <div className="flex justify-end space-x-4">
                  
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`px-4 py-2 w-fit rounded-[40px] flex items-center justify-center ${
                      isLoading ? "bg-gray-400" : "bg-brandGreen text-white"
                    } flex items-center`}
                  >
                    
                     {isLoading && <Loader2 className="animate-spin mr-2" />}
                    {isLoading ? "Adding..." : "Add Exploration"}  
                  </button>  
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
  ) : null;
};

export default AddExploration;
