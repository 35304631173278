import React, { useState } from "react";
import {
  TabButtons,
  SearchBar,
  TicketHeader,
  TicketAttachments,
  TicketReply,
  ClientInfo,
  SharedFiles,
} from "../components/SupportComponents";
import CreateTicket from "./CreateTicket";
import useGetTicketManager from "../controllers/getTicketController";
import useGetTicketByIdManager from "../controllers/getTicketByIdController";
import ReplyModal from "./ReplyModal";
import { formatDate } from "../../../utils/formatDate";
import useDebounce from "../../../utils/UseDebounce";
const MOCK_DATA = {
  ticketData: {
    name: "Jenny Wilson",
    email: "jennywilson@gmail.com",
    phone: "+234 567 8901",
    location: "Delta",
    localTime: "06:30 am (+1 GMT)",
    company: "MOBIL",
  },
  tickets: [
    {
      id: 1,
      title: "Problem with Pipeline",
      status: "Open",
      priority: "High Priority",
      department: "Gas Plant",
      time: "2 min ago",
      preview: "Hi, I need help the process of correct...",
      content:
        "Lorem ipsum dolor sit amet consectetur. Risus lectus nulla libero mauris. Sit dolor malesuada velit at sed consequat libero.",
      customerName: "Jenny Wilson",
      customerEmail: "jennywilson@gmail.com",
      attachments: [
        {
          name: "Name of document.pdf",
          type: "PDF",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
        {
          name: "Name of document.jpg",
          type: "JPG",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
        {
          name: "Name of document.pdf",
          type: "XLS",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
      ],
    },
    {
      id: 2,
      title: "Problem with Pipelines",
      status: "New",
      priority: "Medium Priority",
      department: "Gas Plant",
      time: "2 min ago",
      preview: "Hi, I need help the process of correct...",
      content:
        "Different ticket content for the second ticket. This is a medium priority issue that needs attention.",
      customerName: "Michael Brown",
      customerEmail: "michaelbrown@gmail.com",
      attachments: [
        {
          name: "document2.pdf",
          type: "PDF",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
        {
          name: "Name of document.jpg",
          type: "JPG",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
        {
          name: "document.pdf",
          type: "XLS",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
      ],
    },
    {
      id: 3,
      title: "Problem with Pipeline",
      status: "Open",
      priority: "Low Priority",
      department: "Gas Plant",
      time: "2 min ago",
      preview: "Hi, I need help the process of correct...",
      content:
        "Content for the third ticket. This is a low priority issue that can be handled when resources are available.",
      customerName: "Sarah Davis",
      customerEmail: "sarahdavis@gmail.com",
      attachments: [
        {
          name: "document3.pdf",
          type: "PDF",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
        {
          name: "Name of document.jpg",
          type: "JPG",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
        {
          name: "Name of document.pdf",
          type: "XLS",
          size: "13MB",
          date: "11 Sep, 2023",
          time: "12:24pm",
          sharedWith: "Robert Edward",
        },
      ],
    },
  ],
};

const TicketListItem = ({ ticket, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`border rounded-lg p-4 cursor-pointer transition-colors ${
        isSelected ? "bg-green-100 border-brandGreen" : "hover:bg-gray-50"
      }`}
    >
      <div className="flex gap-4">
        <div className="w-8 h-8 flex-shrink-0 rounded-full capitalize bg-green-500 text-white flex items-center justify-center text-sm font-medium">
          {ticket.sender
            .split(" ")
            .map((n) => n[0])
            .join("")}
        </div>
        <div className="flex-1">
          <h3 className="font-medium w-full flex justify-between capitalize">{ticket.subject} <span className="text-[9px] text-gray-500">{formatDate(ticket?.created_datetime)}</span></h3>
          <p className="text-sm text-gray-500">{ticket.sender} </p>
          <p className="text-sm text-gray-500 mt-2">{ticket.preview}</p>
          <div className="mt-2">
            <div className="flex flex-wrap gap-2">
              <span
                className={`px-2 py-1 rounded-full capitalize text-[10px] font-medium ${
                  ticket.status === "pending"
                    ? "bg-[#ECF7FF] text-[#0070F9]"
                    : ticket.status === "New"
                    ? "bg-green-100 text-green-700"
                    : ""
                }`}
              >
                {ticket.status}
              </span>
              <span
                className={`px-2 py-1 rounded-full capitalize text-[10px] font-medium ${
                  ticket.priority === "high"
                    ? "bg-[#FFF3F3] text-[#E80101]"
                    : ticket.priority === "medium"
                    ? "bg-[#FFF7EA] text-[#FF9B05]"
                    : "bg-[#ECF7FF] text-[#0070F9]"
                }`}
              >
                {ticket.priority}
              </span>
              <span className="px-2 py-1 rounded-full text-[10px] capitalize font-medium bg-[#FFF7EA] text-[#FF9B05]">
                {ticket.department}
              </span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

const NoTicketSelected = () => (
  <div className="flex flex-col items-center justify-center h-full text-gray-500">
    <div className="mb-4">
      <svg
        width="64"
        height="64"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
      </svg>
    </div>
    <h3 className="text-xl font-medium mb-2">No Ticket Selected</h3>
    <p>Select a ticket from the left panel to view its details</p>
  </div>
);

const SupportPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [showClientInfo, setShowClientInfo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const debouncedSearchValue = useDebounce(`&search=${searchQuery}`, 1000)
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const { tickets } = MOCK_DATA;
 const {data,} = useGetTicketManager({
  page: currentPage, 
  searchQuery: searchQuery && debouncedSearchValue, 
  status: activeTab === 0 ? "open" : "closed" 
})
 const {data: ticketThreads} = useGetTicketByIdManager({
  enabled: selectedTicket ? true : false,
  ticketId: selectedTicket?.id  
 })
 console.log(ticketThreads, selectedTicket)
   

  return (
    <div className="flex flex-col gap-1 bg-white p-4 rounded-[10px] min-h-screen">
      <div className="flex justify-between mb-4 items-center">
        <h1 className="text-2xl font-semibold  ">Support</h1>
        <div onClick={() => {setIsTicketModalOpen(true)}} className="w-fit h-fit bg-brandBackground cursor-pointer rounded-[12px] text-white py-2 px-3">
            Create a ticket
        </div>
      </div>

      <div className="flex gap-2">
        {/* Left Panel */}
        <div className="w-1/3 bg-white p-4 border border-[#E4E7EC] rounded-[10px]">
          <TabButtons activeTab={activeTab} no={data?.data?.pagination?.count} setActiveTab={setActiveTab} />
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />

          <div className="flex flex-col gap-4 mt-4">
            {data?.data?.results?.map((ticket) => (
              <TicketListItem
                key={ticket.id}
                ticket={ticket}
                isSelected={selectedTicket?.id  === ticket.id}
                onClick={() =>{setShowClientInfo(""); setSelectedTicket(ticket)}}
              />
            ))}
          </div>
        </div>

        {/* Middle Panel */}
        <div className="flex-1 bg-white border border-[#E4E7EC] rounded-[10px] p-6">
            
          {selectedTicket ? 
          <>
           
          <TicketHeader
                setIsModalOpen={setIsModalOpen}
                setShowClientInfo={setShowClientInfo}
                showClientInfo={showClientInfo}
                ticketData={{
                  created_datetime: selectedTicket?.created_datetime,
                  name: selectedTicket?.subject,
                  sender: selectedTicket?.sender, 
                  status: selectedTicket?.status,
                  department: selectedTicket?.department,
                  priority: selectedTicket?.priority
                }}
              />
          <div >
              
              <div className="mt-6">
                
                <div className="mt-4">
                  <p className="text-gray-700">{selectedTicket?.body}</p>
                </div>
              </div>
              <TicketAttachments attachments={selectedTicket?.attachments} />
              {ticketThreads?.data?.map((el,l) => (
                <TicketReply key={l} ticketData={el} />
              ))}
              
            </div>  </>: (
            <NoTicketSelected />
          )}
        </div>

        {/* Right Panel */}
        {showClientInfo &&  <div className="w-80 bg-white">
          {selectedTicket ? (
            <>
              <ClientInfo
                clientData={{
                  name: selectedTicket.sender,
                  email: selectedTicket.sender_email,
                  phone: selectedTicket.phone || "N/A", // Add phone if available
                  location: selectedTicket.location || "N/A", // Add location if available
                  localTime: "N/A", // Replace with actual local time if available
                  company: "N/A", // Replace with actual company if available
                }}
              />
              {/* <div className="hidden"><SharedFiles files={selectedTicket.attachments} /></div> */}
              
            </>
          ) : (
            <div className="text-gray-500 text-center p-4">
              <p>Select a ticket to view client details</p>
            </div>
          )}
        </div>}
      </div>

      {/* Reply Modal */}
      <ReplyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        ticketId={selectedTicket?.id}
        recipientEmail={selectedTicket?.sender}
        recipientName={selectedTicket?.sender}
      />
      <CreateTicket isTicketOpen={isTicketModalOpen} onTicketClose={() => setIsTicketModalOpen(false)} />
    </div>
  );
};

export default SupportPage;