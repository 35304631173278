import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import FormWrapper from "../../../generalComponents/FormWrapper";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { NewPasswordManager } from "../controllers/setNewPassword";

// Validation schema
const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
});

const ResetPassWord = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { resetPassword } = NewPasswordManager();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    console.log("Login form values:", values);
    resetPassword(values);
    navigate("/login");
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormWrapper>
      <div className="bg-white p-8 rounded-[10px] border border-[#E0E0E0] w-full max-w-sm">
        <h2 className="text-[25px] flex justify-start  font-[600]">
          Reset Password
        </h2>
        <p className="text-[#667185] text-[13px] font-[400] mb-[40px]">
          Set your new password
        </p>
        <Formik
          initialValues={{ email: "", password: "", rememberMe: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isLoading }) => (
            <Form>
              <div className="mb-4 relative">
                <label
                  htmlFor="password"
                  className="block text-[13px] font-[500] text-[#101928]"
                >
                  PASSWORD
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    className="mt-1 block w-full pl-2 text-[11px] p-2 py-3 border rounded-md"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {/* Toggle eye icon */}
                    {showPassword ? (
                      <FaEyeSlash
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    ) : (
                      <FaEye
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-[#00AD51] mt-[80px] mb-4 text-[14px] font-[500] py-3 text-white  rounded-[100px] hover:bg-green-600"
              >
                Reset password
              </button>
              <div className="flex justify-center">
                <p className="text-[#98A2B3] text-[12px]">
                  Still got trouble?{" "}
                  <NavLink to="/login">
                    <span className="text-[13px] font-[500] text-[#00AD51] hover:underline">
                      Contact Support
                    </span>
                  </NavLink>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormWrapper>
  );
};

export default ResetPassWord;
