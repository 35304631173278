import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  { name: "Jan", active: 580, inactive: 428 },
  { name: "Feb", active: 300, inactive: 428 },
  { name: "Mar", active: 700, inactive: 428 },
  { name: "Apr", active: 500, inactive: 428 },
  { name: "May", active: 580, inactive: 428 },
  { name: "Jun", active: 510, inactive: 428 },
  { name: "Jul", active: 510, inactive: 428 },
  { name: "Aug", active: 810, inactive: 428 },
  { name: "Sep", active: 610, inactive: 428 },
  { name: "Oct", active: 390, inactive: 428 },
  { name: "Nov", active: 580, inactive: 428 },
  { name: "Dec", active: 580, inactive: 428 },
];

const AnalyticsChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        layout="horizontal"
        margin={{ top: 30, right: 30, left: 20, bottom: -50 }}
        barGap={0}
      >
        {/* <defs>
          <linearGradient id="customGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#00AD51" stopOpacity={1} />
            <stop offset="100%" stopColor="#006E34" stopOpacity={1} />
          </linearGradient>
        </defs> */}
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#e0e0e0"
        />
        <Tooltip />
        <Bar
          dataKey="active"
          fill="#00AD51"
          radius={[4, 4, 0, 0]}
          barSize={31}
        />
        <Bar
          dataKey="inactive"
          fill="#FFCD00"
          radius={[4, 4, 0, 0]}
          barSize={31}
        />
        <XAxis
          type="category"
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12, fill: "#667185", fontWeight: 400 }}
          interval={0}
          height={100}
          tickMargin={5}
          textAnchor="end"
          //   angle={-20}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12, fill: "#475367", font: 400 }}
        />
        {/* <Legend /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AnalyticsChart;
