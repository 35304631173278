import React from "react";

const CalendarPicker = ({ title, wrapperClass,selectedDate, onDateChange, classN,placeholder }) => {
  return (
    <div className={`flex flex-col  w-full ${wrapperClass}`}>
      <h3 className="text-[14px] bg-[#00AD511A] text-black">{title}</h3>
      <input
          type="date"
          onChange={onDateChange}
          value={selectedDate}
          placeholder={placeholder}
          className={`w-full text-left text-[14px] ${classN} text-black h-[36px] bg-[#F6F8FC] border border-[#667185]  p-2 rounded-md placeholder:text-[14px] focus:outline-none   flex justify-between items-center  outline-none`}
        />
    </div>
  );
};

export default CalendarPicker;
