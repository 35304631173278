import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronRightIcon } from "lucide-react";
import useGetPermissionsManager from "../../auth/controllers/getPermissionsController";
import { DeleteRoleManager } from "../controllers/deleteRoleController";
import CustomButton from "../../../generalComponents/Button";
import useGetSingleRoleManager from "../controllers/getRoleController";
import Loader from "../../../generalComponents/Loader";
import { formatUnderscoreString } from "../../../utils/convertWords";
import Toggle from "../../operators/component/Toggle";
import GoBackButton from "../../../generalComponents/GoBackButton";

export default function RoleDetailsPage() {
  const { roleId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading: permissionsLoading } = useGetPermissionsManager({
    enabled: true,
  });
  const { deleteRole, isLoading: isDeletingRole } = DeleteRoleManager({
    id: roleId,
  });

  const {
    data: singleRoleData,
    isSuccess: loadedRole,
    isLoading: singleRoleLoading,
  } = useGetSingleRoleManager({
    id: roleId,
    enabled: Boolean(roleId),
  });

  const [expandedCategory, setExpandedCategory] = useState(null);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (roleId && loadedRole && singleRoleData?.data?.permissions) {
      const allPermissionIds = singleRoleData.data.permissions.flatMap(
        (module) => module.permissions.map((perm) => perm.id)
      );
      setPermissions(allPermissionIds);
    }
  }, [loadedRole, roleId, singleRoleData?.data?.permissions]);

  const isCategoryFullyChecked = (category) => {
    return category.permissions.every((perm) => permissions.includes(perm.id));
  };

  const isCategoryPartiallyChecked = (category) => {
    return (
      category.permissions.some((perm) => permissions.includes(perm.id)) &&
      !isCategoryFullyChecked(category)
    );
  };

  const handleDeleteRole = async () => {
    await deleteRole();
    navigate("/staff");
  };

  if (permissionsLoading || singleRoleLoading) {
    return <Loader />;
  }
  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className="container w-[100%] px-4 py-8">
      <div className="bg-white rounded-[10px] p-6 mx-auto">
        <div className="mb-6">
          <div className="flex items-center justify-start">
            <GoBackButton onClick={onClick} />
            <h2 className="text-2xl whitespace-nowrap font-bold mb-4">
              Role Details
            </h2>
          </div>
          <p className="border-b pb-2 text-xl">
            {singleRoleData?.data?.name || "Supervisor"}
          </p>
        </div>

        {/* Role Permission Section */}
        <div className="mb-4 mt-10">
          <h3 className="text-[24px] font-[600] leading-[28px]">
            Role Permissions
          </h3>
          <p className="text-[13px] text-[#667185] mb-4">
            Assigned permissions for this role
          </p>

          {data?.data.map((category, index) => (
            <div
              key={index}
              className={`mb-2 ${
                index !== expandedCategory ? "border-b" : ""
              } pb-2`}
            >
              <div className="flex items-center justify-between cursor-pointer">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success checkbox-sm mr-2 border border-[#D0D5DD] rounded-[4px] checked:text-[#ffffff]"
                    checked={isCategoryFullyChecked(category)}
                    ref={(input) => {
                      if (input) {
                        input.indeterminate =
                          isCategoryPartiallyChecked(category);
                      }
                    }}
                    onChange={() => {
                      // Optional: Add toggle logic for category
                    }}
                  />
                  <span className="text-sm font-medium">
                    {formatUnderscoreString(category.module)}
                  </span>
                </div>
                <button
                  onClick={() =>
                    setExpandedCategory(
                      expandedCategory === index ? null : index
                    )
                  }
                >
                  {expandedCategory === index ? (
                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              </div>
              {expandedCategory === index && (
                <div className="ml-6 mt-2">
                  <div className="grid grid-cols-2 gap-4 p-2 relative">
                    {category.permissions.map((permission, subIndex) => (
                      <div
                        key={subIndex}
                        className="flex items-center justify-between"
                      >
                        <span className="text-[13px] text-[#828282]">
                          {permission?.name}
                        </span>
                        <Toggle
                          labelText=""
                          checked={permissions.includes(permission.id)}
                        />
                      </div>
                    ))}
                    <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-200"></div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex items-center w-full space-x-2 mt-6">
          <CustomButton
            buttonText="Edit Role"
            type="submit"
            onClick={() => navigate(`/staff/edit-role/${roleId}`)}
          />
          <button
            onClick={handleDeleteRole}
            type="submit"
            className="btn bg-red-100 text-red-500 font-semibold rounded-[40px] text-[13px]"
          >
            {isDeletingRole ? "Deleting..." : "Delete Role"}
          </button>
        </div>
      </div>
    </div>
  );
}
