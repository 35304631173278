import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const AddVesselManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/vessels",
    ["vessels"],
    true
  );
  const addVessel = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    addVessel,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
