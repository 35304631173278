import React, { useEffect, useRef, useState } from "react"; 
import GoBackButton from "../GoBackButton"; 
import InitialsAvatar from "../InitialsAvatar";
import useGetPermissionsManager from "../../modules/auth/controllers/getPermissionsController";
import useGetSingleClient from "../../modules/clientManagement/controllers/getClientController";
import { PermissionSection } from "../PermissionSection";
import CustomButton from "../Button";
const DeactivationModal = ({ onClose, title, actionText, onClick, body, isLoading }) => {  
  return (
    <div  className="fixed ml-[65px] mt-[70px] rounded-[10px] inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6  w-full rounded-[10px] h-full overflow-y-auto"> 
    <div
          onClick={(e) => e.target === e.currentTarget && onClose()}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
    <div  className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 overflow-y-auto scrollbar-hide max-h-[80%]">
      <div className=" flex justify-between w-full  whitespace-nowrap items-center p-1">
        {title}
         
      </div>
      <div className="w-full h-fit text-black text-[16px] my-4 text-center font-medium ">
          {body}
      </div>
      <div className="grid grid-cols-2   w-full items-center gap-3 ">
         <CustomButton isLoading={isLoading} buttonText={actionText} onClick={onClick} textColor={" text-white"} buttonColor={" bg-red-600"} />
         <CustomButton buttonText={"Cancel"} onClick={(e) => e.target === e.currentTarget && onClose()} textColor={" text-black"} buttonColor={" bg-gray-200"} />
         
      </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default DeactivationModal;
