import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const CreateGasPlantsProductionManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/gas-plants-productions",
    ["gas-plants-productions"],
    true
  );
  const createGasPlantsProduction = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createGasPlantsProduction,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
