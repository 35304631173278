import React, { useEffect, useState } from "react";
// import DashboardTable from "./dashboard/DashboardTable";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle, upload } from "../../../assets/icons";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import useGetCompanyGasProductionManager from "../controllers/getCompanyGasProductionController";
import SearchField from "../../../generalComponents/SearchField";
import FilterButton from "../../../generalComponents/FilterButton";
import FileUpload from "../../../generalComponents/FileUpload";
import { useCompanyGasProductionUpload, useWellProductionUpload } from "../../hydroCarbon/controllers/uploadController";
import AddCompanyGasProduction from "./AddCompanyGasProduction";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import CompanyGasDetails from "../../../generalComponents/hydrocarbons/companyGasDetails";
import DeactivationModal from "../../../generalComponents/client-management/DeactiveClient";
import { DeleteCompanyGasManager } from "../controllers/deleteCompanyGasController";
import ExportToCSV from "../../../utils/downloadInCSV";

const GasProductionSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  // const handleAddClient = () => {
  //   navigate("");
  //   setShowAddClient(true);
  // };
  const [isOpen, setIsOpen] = useState(false);
  const [isGasOpen, setIsGasOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);

  const CompanyGasProductionUploadHook = useCompanyGasProductionUpload();

  const [showDeactivate, setShowDeactive] = useState(false);
   const handleShowDeactivate = () => {
    setShowDeactive(!showDeactivate);
    };
  const handleAddModal = () => {
    setIsOpen(true);
  };
  const handleGasModal = () => {
    setIsGasOpen(!isGasOpen);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleUploadSuccess = (result, file) => {
    console.log("Upload successful", result);

    // refetch();

    handleCloseUploadModal();
  };

  const handleUploadError = (error) => {
    console.error("Upload failed", error);
  };

  const { data, isLoading, refetch } = useGetCompanyGasProductionManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  
const {deleteCompanyGas, isLoading:deleting, isSuccess: deleted} = DeleteCompanyGasManager({id: selectedId})

useEffect(() => {
  if(deleted){
    handleShowDeactivate()
    refetch()
  }
}, [deleted])

  const headers = [
    "Company",
    "Date",
    "Flarred Gas",
    "Fuel Gas",
    "Lng",
    "AG",
    "Action",
  ];

  const options = ["View Production", "Edit Production", "Delete Production"];

  const getFormattedValue = (el, index) => {
    return [
      <div key={index} className="flex items-center gap-5">
       { el?.company?.photo ? <img src={el?.company?.photo} alt="" className="w-10 h-10 rounded-full" /> : <InitialsAvatar name={el?.company?.name ?? "No name"} />}
        <p>{el?.company?.name ?? "No name"}</p>
      </div>,
      formatDate(el?.date),
      el?.flarred_gas,
      el?.fuel_gas,
      el?.lng || "N/A",
      el?.ag || "N/A",
    ];
  };
  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-between pt-3 px-4">
            <div className="flex justify-start items-center gap-1">
              <div className="w-[70%]">
                <SearchField
                  searchValue={searchValue}
                  searchOnchange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <FilterButton />
            </div>
            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} onClick={() => ExportToCSV(data?.data?.results, "COMPANY GAS")} icon={download} />
              <GreenBtn
                onClick={handleOpenUploadModal}
                icon={upload}
                text={"Upload Production"}
              />
              <GreenBtn
                onClick={() => {handleAddModal(); setSelectedId("")}}
                icon={plusCircle}
                text={"Add Production"}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => {
                console.log(option, index, val);
                setSelectedId(val.id)
                if(index === 0){
                  handleGasModal()
                }
                if(index === 1){
                  handleAddModal()
                }
                if (index === 2) {
                  handleShowDeactivate()
                }
              }}
              // Close popup function
            />
          </div>

          {/* Pagination */}
          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* <ClientTable /> */}
        </div>
      </>
      <AddCompanyGasProduction isOpen={isOpen} id={selectedId} onClose={handleCloseModal} />
      <CompanyGasDetails isOpen={isGasOpen} idd={selectedId} onClose={handleGasModal} />
      <FileUpload
        title={"Upload Company Gas Production"}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        uploadHook={CompanyGasProductionUploadHook}
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        additionalData={
          {
            // Any additional metadata you want to send with the upload
            // For example:
            // year: new Date().getFullYear(),
            // source: 'web-upload'
          }
        }
      />
      {showDeactivate && (
        <DeactivationModal
        onClose={handleShowDeactivate} 
        onClick={() => 
           {deleteCompanyGas()}
        }
        isLoading={deleting}
        title={"Delete Production"}
        body={"Are you sure you want to proceed with this action? "}
        actionText={"Delete"}
      />
      )}
    </div>
  );
};

export default GasProductionSection;
