import React, { useEffect, useState } from "react";
// import DashboardTable from "./dashboard/DashboardTable";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle, upload } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";

import useGetGasPlantProductionManager from "../controllers/getGasProductionController";
import AddGasPlantProduction from "./AddGasPlantProduction";
import FileUpload from "../../../generalComponents/FileUpload";
import { useGasPlantProductionUpload, useWellProductionUpload } from "../../hydroCarbon/controllers/uploadController";
import GasPlantProductionDetails from "../../../generalComponents/hydrocarbons/gasplantProductionDetails";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import DeactivationModal from "../../../generalComponents/client-management/DeactiveClient";
import { DeleteGasPlantProductionManager } from "../controllers/deleteGasPlantProductionController";
import ExportToCSV from "../../../utils/downloadInCSV";

const GasPlantProductionSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId,setSelectedId] = useState()
  const GasPlantProductionUploadHook = useGasPlantProductionUpload();

  const [showDeactivate, setShowDeactive] = useState(false);
   const handleShowDeactivate = () => {
    setShowDeactive(!showDeactivate);
    };
  const { data, isLoading, refetch } = useGetGasPlantProductionManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  
const {deleteGasPlantProd, isLoading:deleting, isSuccess: deleted} = DeleteGasPlantProductionManager({id: selectedId})

useEffect(() => {
  if(deleted){
    handleShowDeactivate()
    refetch()
  }
}, [deleted])
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };
  const handleOpenDetailModal = () => {
    setIsDetailOpen(!isDetailOpen);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleUploadSuccess = (result, file) => {
    console.log("Upload successful", result);

    // refetch();

    handleCloseUploadModal();
  };

  const handleUploadError = (error) => {
    console.error("Upload failed", error);
  };

  const headers = [
    "Gas Plants",
    "Date",
    "Flarred Gas",
    "Fuel Gas",
    "Lng",
    "Ag",
    "Action",
  ];

  const options = ["View Production", "Edit Production", "Delete Production"];

  const getFormattedValue = (el, index) => {
    return [
      <div key={index} className="flex items-center gap-5">
       { el?.gas_plant?.photo ? <img src={el?.gas_plant?.photo} alt="" className="w-10 h-10 rounded-full" /> : <InitialsAvatar name={el?.gas_plant?.name ?? "No name"} />}
        <p className="capitalize">{el?.gas_plant?.name ?? "No name"}</p>
      </div>,
      formatDate(el?.date),
      el?.flarred_gas,
      el?.fuel_gas,
      el?.lng || "N/A",
      el?.ag || "N/A",
    ];
  };

  const handleAddModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-between pt-3 px-4">
            <DropdownandSearch
              options={["Option1", "Option"]}
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} onClick={() => ExportToCSV(data?.data?.results, "GAS PLANT PRODUCTION")} icon={download} />
              <GreenBtn
                onClick={handleOpenUploadModal}
                icon={upload}
                text={"Upload Production"}
              />
              <GreenBtn
                onClick={() => {handleAddModal(); setSelectedId("")}}
                icon={plusCircle}
                text={"Add Gas Plant Production"}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => {
                console.log(option, index, val);
                setSelectedId(val.id)
                  if (index === 0){
                    handleOpenDetailModal()
                  }
                  if(index === 1){
                    handleAddModal()
                  }
                if (index === 2) {
                  handleShowDeactivate()
                }
              }}
              // Close popup function
            />
          </div>

          {/* Pagination */}
          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />

          {/* <ClientTable /> */}
        </div>
      </>
      {/* Add Gas Plant Production Modal */}
      <AddGasPlantProduction isOpen={isOpen} id={selectedId} onClose={handleCloseModal} />

      {/* Upload Modal */}

      <FileUpload
        title={"Upload Gas Plant Production"}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        uploadHook={GasPlantProductionUploadHook}
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        additionalData={
          {
            // Any additional metadata you want to send with the upload
            // For example:
            // year: new Date().getFullYear(),
            // source: 'web-upload'
          }
        }
      />
      {showDeactivate && (
        <DeactivationModal
        onClose={handleShowDeactivate} 
        onClick={() => 
           {deleteGasPlantProd()}
        }
        isLoading={deleting}
        title={"Delete Production"}
        body={"Are you sure you want to proceed with this action? "}
        actionText={"Delete"}
      />
      )}
      <GasPlantProductionDetails isOpen={isDetailOpen} onClose={handleOpenDetailModal} idd={selectedId} />
    </div>
  );
};

export default GasPlantProductionSection;
