import React, { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { X } from "lucide-react";
import useGetAllRolesManager from "../controllers/getRolesController";

export default function FilterDrawer({ isOpen, onClose, onApplyFilter }) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Fetch roles using the same controller as RoleManagement
  const { data: rolesData, isLoading } = useGetAllRolesManager({
    enabled: isOpen, // Only fetch when drawer is open
    searchQuery: "", // No search query for initial fetch
    page: 1,
  });

  const handleRoleSelect = (roleId) => {
    setSelectedRoles((prev) =>
      prev.includes(roleId)
        ? prev.filter((id) => id !== roleId)
        : [...prev, roleId]
    );
  };

  const handleResetAll = () => {
    setSelectedRoles([]);
    setStartDate("");
    setEndDate("");
  };

  const handleApplyFilter = () => {
    // Pass filter parameters to the parent component
    onApplyFilter({
      roles: selectedRoles,
      startDate,
      endDate,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[1000]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-end">
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brandGreen focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <X aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-start mt-4 justify-between">
                    <DialogTitle className="text-[20px] font-semibold text-[#4B4B4B]">
                      Role Management Filter
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={handleResetAll}
                        className="relative rounded-md bg-white text-brandGreen hover:text-brandBackground/90 focus:outline-none focus:ring-2 focus:ring-brandBackground focus:ring-offset-2"
                      >
                        Reset all
                      </button>
                    </div>
                  </div>
                </div>

                {/* Date Filter */}
                <div className="px-4 sm:px-6 mt-6">
                  <h3 className="text-textGrey">Date</h3>
                  <div className="relative grid grid-cols-2 gap-x-2 mt-2 flex-1">
                    <div>
                      <div className="mt-1 relative">
                        <input
                          id="start-date"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-offWhite placeholder:text-[#98A2B3] focus:ring-1 focus:ring-inset focus:ring-ringColor sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="mt-1 relative">
                        <input
                          id="end-date"
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-offWhite placeholder:text-[#98A2B3] focus:ring-1 focus:ring-inset focus:ring-ringColor sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Roles Filter */}
                <div className="px-4 sm:px-6 mt-6">
                  <h3 className="text-textGrey">Roles</h3>
                  <div className="mt-2 flex flex-wrap gap-2">
                    {isLoading ? (
                      <p>Loading roles...</p>
                    ) : (
                      rolesData?.data?.results?.map((role) => (
                        <button
                          key={role.id}
                          type="button"
                          onClick={() => handleRoleSelect(role.id)}
                          className={`inline-flex group items-center gap-x-1.5 rounded-3xl px-3 py-1.5 text-xs font-semibold 
                            ${
                              selectedRoles.includes(role.id)
                                ? "bg-green-700 text-white"
                                : "bg-green-50 text-textGrey hover:bg-green-700 hover:text-white"
                            }`}
                        >
                          {selectedRoles.includes(role.id) && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="size-6"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <span>{role.name}</span>
                        </button>
                      ))
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  onClick={handleApplyFilter}
                  className="text-brandBackground shadow-lg fixed py-2.5 border-t w-full text-center bottom-0"
                >
                  Apply Filter
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
