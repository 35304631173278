import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetSingleCompanyGasManager = ({ id, enabled }) => {
  return useQuery(
    ["company-gas-productions_id", id],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/company-gas-productions/${id}/`)];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled, refetchOnWindowFocus: false }
  );
};

export default useGetSingleCompanyGasManager;
