import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateFieldProductionManager } from "../controllers/createFieldProductionController";
import useGetFieldsManager from "../controllers/getFieldsController";
import { FormSelect } from "../../omls/components/FormComponents";
import { FormLabel } from "@mui/material";

const validationSchema = Yup.object({
  field: Yup.string().required("Field is required"),
  date: Yup.date().required("Date is required"),
  planned_oil_production: Yup.number().required(
    "Planned oil production is required"
  ),
  actual_oil_production: Yup.number().required(
    "Actual oil production is required"
  ),
  planned_condensate_blended: Yup.number().required(
    "Planned condensate blended is required"
  ),
  actual_condensate_blended: Yup.number().required(
    "Actual condensate blended is required"
  ),
  planned_condensate_unblended: Yup.number().required(
    "Planned condensate unblended is required"
  ),
  actual_condensate_unblended: Yup.number().nullable(),
  planned_nag: Yup.number().required("Planned NAG is required"),
  actual_nag: Yup.number().required("Actual NAG is required"),
  planned_ag: Yup.number().required("Planned AG is required"),
  actual_ag: Yup.number().required("Actual AG is required"),
  planned_bsw: Yup.number().required("Planned BSW is required"),
  actual_bsw: Yup.number().required("Actual BSW is required"),
});

const AddFieldProduction = ({ isOpen, onClose }) => {
  const { createFieldProduction, isLoading } = CreateFieldProductionManager();
  const { data: fieldsData, isLoading: isFieldsLoading } = useGetFieldsManager({
    page: 1,
    searchQuery: "",
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      field: "",
      date: "",
      planned_oil_production: "",
      actual_oil_production: "",
      planned_condensate_blended: "",
      actual_condensate_blended: "",
      planned_condensate_unblended: "",
      actual_condensate_unblended: "",
      planned_nag: "",
      actual_nag: "",
      planned_ag: "",
      actual_ag: "",
      planned_bsw: "",
      actual_bsw: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await createFieldProduction(values);
        onClose(); // Close the modal on successful submission
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title="Create Field Production"
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <FormLabel>Field</FormLabel>
              <FormSelect
                label="Flied"
                id="field"
                name="field"
                placeholder="Enter field"
                value={formik.values.field}
                options={[
                  { value: "", label: "Select field" },
                  ...(fieldsData?.data?.results?.map((field) => ({
                    value: field.id,
                    label: field.name,
                  })) || []),
                ]}
                disabled={isFieldsLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.field && formik.errors.field}
                errorMessage={formik.errors.field}
              />
            </div>
            <InputWithFullBoarder
              label="Date"
              id="date"
              name="date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && formik.errors.date}
              errorMessage={formik.errors.date}
            />

            <InputWithFullBoarder
              label="Planned Oil Production"
              id="planned_oil_production"
              name="planned_oil_production"
              placeholder="Enter planned oil production"
              value={formik.values.planned_oil_production}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_oil_production &&
                formik.errors.planned_oil_production
              }
              errorMessage={formik.errors.planned_oil_production}
            />

            <InputWithFullBoarder
              label="Actual Oil Production"
              id="actual_oil_production"
              name="actual_oil_production"
              placeholder="Enter actual oil production"
              value={formik.values.actual_oil_production}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_oil_production &&
                formik.errors.actual_oil_production
              }
              errorMessage={formik.errors.actual_oil_production}
            />

            <InputWithFullBoarder
              label="Planned Condensate Blended"
              id="planned_condensate_blended"
              name="planned_condensate_blended"
              placeholder="Enter planned condensate blended"
              value={formik.values.planned_condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_condensate_blended &&
                formik.errors.planned_condensate_blended
              }
              errorMessage={formik.errors.planned_condensate_blended}
            />

            <InputWithFullBoarder
              label="Actual Condensate Blended"
              id="actual_condensate_blended"
              name="actual_condensate_blended"
              placeholder="Enter actual condensate blended"
              value={formik.values.actual_condensate_blended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_condensate_blended &&
                formik.errors.actual_condensate_blended
              }
              errorMessage={formik.errors.actual_condensate_blended}
            />

            <InputWithFullBoarder
              label="Planned Condensate Unblended"
              id="planned_condensate_unblended"
              name="planned_condensate_unblended"
              placeholder="Enter planned condensate unblended"
              value={formik.values.planned_condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planned_condensate_unblended &&
                formik.errors.planned_condensate_unblended
              }
              errorMessage={formik.errors.planned_condensate_unblended}
            />

            <InputWithFullBoarder
              label="Actual Condensate Unblended"
              id="actual_condensate_unblended"
              name="actual_condensate_unblended"
              placeholder="Optional"
              value={formik.values.actual_condensate_unblended}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actual_condensate_unblended &&
                formik.errors.actual_condensate_unblended
              }
              errorMessage={formik.errors.actual_condensate_unblended}
            />

            <InputWithFullBoarder
              label="Planned NAG"
              id="planned_nag"
              name="planned_nag"
              placeholder="Enter planned NAG"
              value={formik.values.planned_nag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.planned_nag && formik.errors.planned_nag}
              errorMessage={formik.errors.planned_nag}
            />

            <InputWithFullBoarder
              label="Actual NAG"
              id="actual_nag"
              name="actual_nag"
              placeholder="Enter actual NAG"
              value={formik.values.actual_nag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.actual_nag && formik.errors.actual_nag}
              errorMessage={formik.errors.actual_nag}
            />

            <InputWithFullBoarder
              label="Planned AG"
              id="planned_ag"
              name="planned_ag"
              placeholder="Enter planned AG"
              value={formik.values.planned_ag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.planned_ag && formik.errors.planned_ag}
              errorMessage={formik.errors.planned_ag}
            />

            <InputWithFullBoarder
              label="Actual AG"
              id="actual_ag"
              name="actual_ag"
              placeholder="Enter actual AG"
              value={formik.values.actual_ag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.actual_ag && formik.errors.actual_ag}
              errorMessage={formik.errors.actual_ag}
            />

            <InputWithFullBoarder
              label="Planned BSW"
              id="planned_bsw"
              name="planned_bsw"
              placeholder="Enter planned BSW"
              value={formik.values.planned_bsw}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.planned_bsw && formik.errors.planned_bsw}
              errorMessage={formik.errors.planned_bsw}
            />

            <InputWithFullBoarder
              label="Actual BSW"
              id="actual_bsw"
              name="actual_bsw"
              placeholder="Enter actual BSW"
              value={formik.values.actual_bsw}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.actual_bsw && formik.errors.actual_bsw}
              errorMessage={formik.errors.actual_bsw}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading}
            buttonText="Create Field Production"
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddFieldProduction;
