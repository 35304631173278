import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetCompanyOilProductionByIdManager = ({
  id, 
  enabled = true
}) => {
  return useQuery(
    ["company_oil", id, enabled],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/company-oil-productions/${id}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { refetchOnWindowFocus: false, enabled: enabled }
  );
};

export default useGetCompanyOilProductionByIdManager;
