import React from "react";
import { X } from "lucide-react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Field name is required"),
  email: Yup.string().required("Email is required"),
});

const initialValues = {
  name: "",
  email: "",
  item: "",
};



const AddReservoir = ({ isOpen, onClose }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <dialog open={isOpen} className="modal modal-open">
      <div className="modal-box  max-h-[90vh] rounded-[10px] overflow-y-auto">
        <div className="sticky top-0 bg-base-100 pt-4 pb-2">
          <div className="flex justify-between items-center">
            <h2 className="text-[24px] font-[600]">Add a Staff</h2>
            <button
              onClick={onClose}
              className="btn btn-ghost btn-sm btn-circle"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="space-y-4 mt-4">
              <div className="gap-3 mb-12">
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Staff Name
                    </span>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Enter field name"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.name && errors.name ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Email Address
                    </span>
                    <Field
                      name="email"
                      type="text"
                      placeholder="Enter field email"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.email && errors.email ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center p-1 mt-16">
                <button
                  type="submit"
                  className="btn btn-success flex-1 bg-[#00AD51] rounded-[40px] text-[13px] font-[500] text-white"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Adding..." : "Add Oil Field"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default AddReservoir;
