import React from "react";
import { ReactComponent as MobilBg } from "../../../assets/images/mobilBg.svg";
import { ReactComponent as OilIcon } from "../../../assets/icons/OilField.svg";
import { ReactComponent as Reservior } from "../../../assets/icons/Reservior.svg";
import { ReactComponent as Wells } from "../../../assets/icons/Wells.svg";
import { ReactComponent as Storage } from "../../../assets/icons/storage.svg";
import { ReactComponent as Gas } from "../../../assets/icons/gas.svg";
import { ReactComponent as Pipeline } from "../../../assets/icons/pipeline.svg";
import { ReactComponent as PipelineNetwork } from "../../../assets/icons/pipelineNetwork.svg";
import { ReactComponent as Terminal } from "../../../assets/icons/terminal.svg";
import { ReactComponent as FPSO } from "../../../assets/icons/fpso.svg";
import { ReactComponent as LactPoint } from "../../../assets/icons/lactPoint.svg";
import { ReactComponent as AGG } from "../../../assets/icons/agg.svg";
import { ReactComponent as LoadingBouy } from "../../../assets/icons/loadingBouy.svg";
import { ReactComponent as Manifold } from "../../../assets/icons/Manifold.svg";

const AssetList = ({ selected, setSelected, operatorImage }) => {
  const menuItems = [
    { item: "Company Production", icon: LoadingBouy },
    { item: "Oil Field", icon: OilIcon },
    { item: "Reservoir", icon: Reservior },
    { item: "Wells", icon: Wells },
    { item: "Rigs", icon: Wells },
    { item: "Storage Tanks", icon: Storage },
    { item: "Gas Plant", icon: Gas },
    { item: "Pipeline", icon: Pipeline },
    { item: "Pipeline Network", icon: PipelineNetwork },
    { item: "Terminal/(FSO)", icon: Terminal },
    { item: "FPSO", icon: FPSO },
    { item: "Lact Point", icon: LactPoint },
    { item: "AGG Station", icon: AGG },
    { item: "Loading Bouy", icon: LoadingBouy },
    { item: "Manifold", icon: Manifold },
  ];

  return (
    <div className="bg-[#FFFFFF] rounded-[10px] h-screen border border-[#E4E7EC]">
      <ul className="space-y-2">
        <div>
          {operatorImage ? (
            <img src={operatorImage} alt="Operator" className="w-full rounded-[20px] h-full max-h-[132px] " />
          ) : (
            <MobilBg className="w-full" />
          )}
        </div>
        {menuItems.map((item) => (
          <li
            key={item}
            className={`cursor-pointer py-2 px-[10px] rounded-[5px] leading-3 text-[12px] ${
              selected === item.item ? "bg-[#00AD51] text-white" : ""
            }`}
            onClick={() => setSelected(item.item)}
          >
            <div className="flex items-center">
              <item.icon className="mr-2" />
              {item.item}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AssetList;
