import React, { useEffect } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const BaseModal = ({
  isOpen = false,
  onClose,
  title,
  description,
  children,
  maxWidth = "5xl",
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isOpen) {
      navigate("");
    }
  }, [isOpen]);
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[1000]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-${maxWidth} max-w-[700px] sm:px-12 sm:py-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
          >
            <div className="flex items-center justify-between mb-5">
              <div>
                <h2 className="text-2xl">{title}</h2>
                {description && <p className="text-gray-400">{description}</p>}
              </div>
              <button
                type="button"
                onClick={onClose}
                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brandGreen focus:ring-offset-2"
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <X aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
