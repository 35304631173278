import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Upload, X, FileIcon, Loader } from "lucide-react";

const FileUpload = ({
  maxSize = 800 * 400,
  acceptedFileTypes = ["text/csv", "application/vscode-csv", "application/csv"],
  onUploadSuccess,
  onUploadError,
  acceptable,
  uploadHook, // New prop to pass the specific upload hook
  additionalData = {}, // Optional additional data to send with upload
  uploadOptions = {}, // Additional configuration options
  showUploadModal,
  handleCloseUploadModal,
  title,
}) => {
  const [uploadState, setUploadState] = useState({
    progress: 0,
    fileName: null,
  });
  const [isDragging, setIsDragging] = useState(false);

  // Use the provided upload hook
  // Use the hook results directly
  const { uploadFile, isLoading, isSuccess, error } = uploadHook;

  // Formik setup for validation
  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed()
        .required("A file is required")
        .test("fileSize", "File size is too large", (value) => {
          if (!value) return true;
          return value.size <= maxSize;
        })
        .test("fileType", "Unsupported file type", (value) => {
          if (!value) return true;
          return acceptedFileTypes.includes(value.type);
        }),
    }),
    onSubmit: async (values) => {
      if (!values.file) return;
      try {
        console.log("Submitting file:", values.file); // Debug log

        setUploadState((prev) => ({
          ...prev,
          fileName: values.file.name,
        }));

        // Ensure values.file is a File object
        if (!(values.file instanceof File)) {
          throw new Error("Invalid file object");
        }
        
        // Upload file with additional data
        const result = await uploadFile(values.file, additionalData);

        if (isSuccess) {
          onUploadSuccess?.(result, values.file);
        }
      } catch (err) {
        console.error("Upload submission error:", err);
        onUploadError?.(err);
      }
    },
  });

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);

      const file = e.dataTransfer.files[0];
      if (file) {
        formik.setFieldValue("file", file);
        formik.submitForm();
      }
    },
    [formik]
  );

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleFileSelect = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      formik.setFieldValue("file", file);
      formik.submitForm();
    }
  };

  const clearUpload = () => {
    formik.setFieldValue("file", null);
    setUploadState({
      progress: 0,
      fileName: null,
    });
  };

  const uploadContainerClasses = `
    w-full border-2 border-dashed rounded-lg p-6 text-center
    bg-gray-50 cursor-pointer transition-all duration-200
    hover:border-green-500 hover:bg-green-50
    ${isDragging ? "border-green-500 bg-green-50" : "border-gray-200"}
  `;

  return (
    <>
      {showUploadModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-[10px] w-full max-w-md relative">
            <button
              onClick={handleCloseUploadModal}
              className="absolute top-2 right-2 text-3xl text-gray-600 hover:text-gray-900"
            >
              &times;
            </button>
            <h2 className="text-xl font-semibold mb-4">{title}</h2>
            <div className="w-full max-w-md mx-auto">
              <input
                type="file"
                id="fileInput"
                className="hidden"
                accept={acceptedFileTypes.join(",")}
                onChange={handleFileSelect}
              />

              <div
                className={uploadContainerClasses}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                <Upload className="w-12 h-12 mx-auto text-gray-400 mb-4" />
                <p className="text-sm text-gray-600 mb-2">
                  Click to upload or drag and drop
                </p>
                <p className="text-xs text-gray-500">
                  {acceptable  ? acceptable :"CSV, PDF, or TXT (max. 5mb)"}
                </p>
              </div>

              {uploadState.fileName && (
                <div className="mt-4 p-3 bg-gray-100 rounded-lg flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <FileIcon className="w-5 h-5 text-gray-500" />
                    <span className="text-sm text-gray-700">
                      {uploadState.fileName}
                    </span>
                  </div>
                  {isLoading ? (
                    <div className="flex items-center gap-2">
                      <Loader className="w-5 h-5 animate-spin text-gray-500" />
                      <span className="text-sm text-gray-500">
                        Uploading...
                      </span>
                    </div>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        clearUpload();
                      }}
                      className="p-1 hover:bg-gray-200 rounded-full transition-colors duration-200"
                    >
                      <X className="w-5 h-5 text-gray-500" />
                    </button>
                  )}
                </div>
              )}

              {(formik.errors.file || error) && (
                <p className="mt-2 text-sm text-red-600">
                  {formik.errors.file || error?.message}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUpload;
