import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const data = [
  { name: "Oil Field", value: 580 },
  { name: "Reservoir", value: 300 },
  { name: "Wells", value: 700 },
  { name: "Rigs", value: 500 },
  { name: "Storage Tank", value: 580 },
  { name: "Gas Plant", value: 510 },
  { name: "Pipeline", value: 510 },
  { name: "Pipeline Network", value: 810 },
  { name: "Terminal/(FSO)", value: 610 },
  { name: "FPSO", value: 390 },
  { name: "Lact point", value: 580 },
  { name: "AGG Station", value: 580 },
  { name: "Loading bouy", value: 400 },
  { name: "Manifold", value: 400 },
  { name: "Flow Station", value: 400 },
  { name: "Platform", value: 400 },
];

const AssetBarchart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        layout="horizontal"
        margin={{ top: 5, right: 30, left: 20, bottom: -45 }}
      >
        <defs>
          <linearGradient id="customGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#00AD51" stopOpacity={1} />
            <stop offset="100%" stopColor="#006E34" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#e0e0e0"
        />
        <Tooltip />
        <Bar
          dataKey="value"
          fill="url(#customGradient)"
          radius={[4, 4, 0, 0]}
          barSize={31}
        />
        <XAxis
          type="category"
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 8, fill: "#000000", fontWeight: 400 }}
          interval={0}
          height={100}
          tickMargin={5}
          textAnchor="end"
          angle={-20}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 10, fill: "#475367", font: 400 }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AssetBarchart;
