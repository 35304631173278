import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetTerminalsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  operator = "",
  pageSize = "20",
}) => {
  return useQuery(
    ["terminals", page, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/terminals?page=${page}&size=${pageSize}${searchQuery}${operator}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { refetchOnWindowFocus: false, enabled: enabled }
  );
};

export default useGetTerminalsManager;
