import React from "react";
import { filter, searchIcon } from "../assets/icons";

const DropdownandSearch = ({
  options = [],
  onChange,
  title,
  value = "",
  searchValue = "",
  placeholder,
  searchOnchange,
}) => {
  return (
    <div className="flex flex-row items-center">
      {/* Search */}
      <div className="relative input input-bordered flex items-center gap-2">
        <img src={searchIcon} alt="Search Icon" />
        <input
          type="text"
          className="grow text-[#667185] text-[12px] w-[290px] "
          placeholder="Search here..."
          value={searchValue}
          onChange={searchOnchange}
        />
      </div>
      {/* Dropdown */}
      <div className="dropdown">
        <h3 className="text-[14px] text-black">{title}</h3>
        <div className="btn m-1 font-[600] bg-white border border-[#D0D5DD] rounded-[8px] shadow-custom-1 hover:shadow-custom-2 flex items-center py-[8px] px-[12px] text-[12px] text-[#344054]">
          <select
            onChange={onChange}
            value={value}
            className="w-full text-left text-[14px] text-black h-[22.91px] flex justify-between items-center bg-transparent outline-none"
          >
            <option value="" disabled hidden>
              <img src={filter} alt="" />
              {placeholder ?? "Filter"}
            </option>
            {options.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default DropdownandSearch;
