import React from "react";
import { X } from "lucide-react"; 
import useGetGasPlantProductionManager from "../../modules/gasplant/controllers/getGasPlantProducController";

const GasPlantProductionDetails = ({ isOpen, onClose,  idd }) => {
  
  const {data} = useGetGasPlantProductionManager({id: idd, enabled: true}) 
  if (!isOpen) return null;
  console.log(idd)
  console.log(data)
  const detailFields = [
    { id: "gas_plant", label: "Gas Plant", value: data?.data?.gas_plant },
    { id: "date", label: "Date", value: data?.data?.date },
    { id: "flarred_gas", label: "Flarred Gas", value: data?.data?.flarred_gas }, 
    { id: "fuel_gas", label: "Fuel Gas", value: data?.data?.fuel_gas },
    { id: "lng", label: "Lng", value: data?.data?.lng },
    {
      id: "ag",
      label: "AG",
      value: data?.data?.ag,
    },
    {
      id: "egtl",
      label: "EGTL",
      value: data?.data?.egtl,
    },
    {
      id: "nlg",
      label: "NLG",
      value: data?.data?.nlg,
    },
    { id: "dom_sales", label: "Dom Sales", value: data?.data?.dom_sales },
    { id: "nag", label: "NAG", value: data?.data?.nag },
    {
      id: "gas_reinjection",
      label: "Gas Reinjection",
      value: data?.data?.gas_reinjection,
    }, 
  ];

  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 overflow-y-auto p-4"
    >
      <div
        className="bg-white rounded-lg w-full max-w-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-[16px] font-medium text-gray-900">Gas Plant Production Details</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close dialog"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-hide">
          <div className="space-y-4">
            {detailFields.map((field) => (
              <div
                key={field.id}
                className="flex justify-between items-center py-2 border-b border-gray-100 last:border-0"
              >
                <span className="text-[14px] text-gray-900 font-medium">
                  {field.label}
                </span>
                <span className="text-[14px] text-gray-500">
                  {field.value || "Info"}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GasPlantProductionDetails;
