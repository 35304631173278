import React, { useState } from "react";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { plusCircle } from "../../../assets/icons";
import useDebounce from "../../../utils/UseDebounce";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import FilterDrawer from "./FilterDrawer";
import SearchField from "../../../generalComponents/SearchField";
import FilterButton from "../../../generalComponents/FilterButton";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import useGetAllRolesManager from "../controllers/getRolesController";

const roleManagerTableHeaders = ["Role Name", "Date", "Action"];

const RoleManagement = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState({
    roles: [],
    startDate: "",
    endDate: "",
  });
  // Debounce search to reduce unnecessary API calls
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const searchQuery = [
    searchValue ? debouncedSearchValue : "",
    filterParams.roles.length > 0
      ? `roles=${filterParams.roles.join(",")}`
      : "",
    filterParams.startDate ? `start_date=${filterParams.startDate}` : "",
    filterParams.endDate ? `end_date=${filterParams.endDate}` : "",
  ]
    .filter(Boolean)
    .join("&");

  const handleApplyFilter = (params) => {
    setFilterParams(params);
    // Optionally reset to first page when applying new filters
    setCurrentPage(1);
  };

  // Fetch roles with pagination and search
  const { data, isLoading } = useGetAllRolesManager({
    enabled: true,
    searchQuery: searchQuery,
    page: currentPage,
  });

  // Navigation handlers
  const handleCreateRole = () => {
    navigate("/staff/create-role");
  };

  const handleRoleDetails = (roleId) => {
    navigate(`/staff/details/${roleId}`);
  };

  // Format table row values
  const getFormattedValue = (el, index) => {
    return [
      <div key={index} className="flex items-center gap-5">
        <p>{el?.name ?? ""}</p>
      </div>,
      formatDate(el?.created_datetime ?? ""),
    ];
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      {/* Header with Create Role Button */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="font-[600] text-[24px] mb-2 w-full">Role Management</h1>
        <div className="flex items-center gap-[5px]">
          <GreenBtn
            onClick={handleCreateRole}
            icon={plusCircle}
            text={"Create a Staff role"}
          />
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
        <div className="flex items-center space-x-2 justify-start pt-3 px-4">
          <SearchField
            searchOnchange={(e) => setSearchValue(e.target.value)}
            searchValue={searchValue}
          />
          <FilterButton onClick={() => setIsFilterDrawerOpen(true)} />
        </div>

        {/* Table of Roles */}
        <div className="h-[67vh] w-full relative">
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={roleManagerTableHeaders}
            showCheckBox={false}
            buttonFunction={(option) => handleRoleDetails(option.id)}
          />
        </div>

        {/* Pagination */}
        <NUIMSPagination
          pagination={data?.data?.pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      {/* Filter Drawer */}
      <FilterDrawer
        isOpen={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        onApplyFilter={handleApplyFilter}
      />
    </div>
  );
};

export default RoleManagement;
