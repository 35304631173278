import CalendarPicker from "./CalendarPicker";
import CustomInput from "./CustomInput";

export default function AddComponent ({formik, data, start_date = "", end_date = "", option}){  
    return(

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {data?.map((field, l) => (
                    field.type === "radio" ? <CustomInput key={l} value={formik.values[field.name]} onChange={(e) => formik.setFieldValue(field.name, e) } labelClassName={"!text-sm !text-[#181918] !bg-[#00AD511A] !w-full !capitalize !flex !h-fit !px-[5px]"} title={field.name}    wrapperClassName={""} isRadio={true} radioOption={option} /> :
                      field.type === "time" ? 
                     
                     <div key={l} className={`w-full col-span-${field.span} `} >
                            <label className="text-sm text-[#181918] bg-[#00AD511A] w-full flex h-fit px-[5px]">{field.label}</label>
                            <div className="w-full h-fit mt-1 grid grid-cols-2 gap-3 ">
                                <CalendarPicker 
                                classN={"!border-0"}
                                  selectedDate={formik.values[start_date]}
                                  onDateChange={(e) =>
                                    formik.setFieldValue(`${start_date}`, e.target.value)
                                  }
                                  placeholder="Select start date"
                                />
                                {formik.touched[start_date] &&
                                  formik.errors[start_date] && (
                                    <p className="text-red-500 text-xs mt-1">
                                      {formik.errors[start_date]}
                                    </p>
                                  )}

                                <CalendarPicker 
                                classN={"!border-0"}
                                  selectedDate={formik.values[end_date]}
                                  onDateChange={(e) =>
                                    formik.setFieldValue(`${end_date}`, e.target.value)
                                  }
                                  placeholder="Select end date"
                                />
                                {formik.touched[end_date] &&
                                  formik.errors[end_date] && (
                                    <p className="text-red-500 text-xs mt-1">
                                      {formik.errors[end_date]}
                                    </p>
                                  )}
                            </div>
                      </div>
                     
                     :  field.type === "select" ? 
                     <div key={l} className={`w-full col-span-${field.span}`} >
                              <label className="text-sm text-[#181918] bg-[#00AD511A] w-full flex h-fit px-[5px]">{field.label}</label>
                              <select
                                name={field.name}
                                value={formik.values[field.name]}
                                onChange={formik.handleChange}
                                className="  bg-[#F6F8FC] rounded-[5px] h-[36px] text-[#989898] text-[12px] outline-none p-2 mt-1 w-full"
                              >
                                <option className="" value="" disabled>
                                  Select {field.name}
                                </option>
                              {/*  {field.options?.map((option, idx) => (
                                  <option key={idx} value={option.value}>
                                    {option.label}
                                  </option>
                                ))} */}
                              </select>
                              {formik.touched[field.name] && formik.errors[field.name] && (
                                <p className="text-red-500 text-xs mt-1">
                                  {formik.errors[field.name]}
                                </p>
                              )}
                      </div> : 
                      <div key={l} className={`w-full flex flex-col col-span-${field.span} h-fit `}>
                            <label className="text-sm text-[#181918] bg-[#00AD511A] w-full capitalize flex h-fit px-[5px]">{field.label}</label>
                            <input onChange={(e) =>
                                    formik.setFieldValue(field.name, e.target.value)
                                  } value={formik.values[field.name]} type={field.type} placeholder={field.placeholder} className="  bg-[#F6F8FC] mt-1 h-[36px] w-full rounded-[5px] text-black placeholder:text-[#989898] placeholder:text-[12px] focus:outline-none  p-2 border-[#667185]" />
                            {formik.touched[field.name] && formik.errors[field.name] && (
                              <p className="text-red-500 text-xs mt-1">
                                {formik.errors[field.name]}
                              </p>
                            )}
                      </div>
                    ))}
                </div>
    )
}