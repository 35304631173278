export const INITIAL_VALUES = {
  number: "",
  oml_class: "unitized",
  lng: 0,
  lat: 0,
  opl_right_type: "exploration",
  block_validity: "yes",
  resource_type: "conventional",
  contract_type: "PSC",
  renewal_date: new Date().toISOString().split("T")[0],
  oml_conversion_date: new Date().toISOString().split("T")[0],
  client: "",
  area: "",
  basil_name: "",
  opl: "",
  application_date: new Date().toISOString().split("T")[0],
  opl_award_date: new Date().toISOString().split("T")[0],
  opl_block_size: 0,
  onshore_size: 0,
  shelf_size: 0,
  deep_water_size: 0,
  min_water_depth: 0,
  max_water_depth: 0,
  median_water_depth: 0,
  contract_status: "active",
  x: 0,
  y: 0,
  lng_summary: 0,
  lat_summary: 0,
};

export const OML_CLASSES = ["unitized", "productive", "non-productive", "open"];
