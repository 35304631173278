import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const ReplyTicketManager = ({ticketId}) => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/support/ticket/${ticketId}/threads`,
    ["reply-Ticket", ticketId],
    true
  );
  const replyTicket = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    replyTicket,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
