import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateFPSOProductionManager } from "../controllers/createFPSOProduction";
import useGetSingleFPSOManager from "../controllers/getFPSOController";
import { UpdateFPSOManager } from "../controllers/updateFPSOController";

const validationSchema = Yup.object({
  date: Yup.date().required("Date is required"),
  oil: Yup.number().required("Oil is required"),
  condensate_blended: Yup.number().required("Condensate Blended is required"),
  condensate_unblended: Yup.number().required(
    "Condensate Unblended is required"
  ),
  bsw: Yup.number().required("BSW is required"),
});

const AddFPSOProduction = ({ isOpen, onClose, id }) => {
  const { createFPSOProduction, isLoading } = CreateFPSOProductionManager();
  const {data} = useGetSingleFPSOManager({id: id, enabled: Boolean(id)})
  const {updateFPSO, isLoading:updating, isSuccess} = UpdateFPSOManager({
    id: id
  })
  const formik = useFormik({
    initialValues: {
      date: "",
      oil: "",
      condensate_blended: "",
      condensate_unblended: "",
      bsw: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
       if(id){
        await updateFPSO(values);
       }else{
        await createFPSOProduction(values);
       }
        onClose(); // Close modal after successful creation
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (id && data?.data) {
      
      
      // Set form values
      formik.setValues({
        date: data?.data?.date || "",
        condensate_blended: data?.data?.condensate_blended || "",
        condensate_unblended: data?.data?.condensate_unblended || "",
        oil: data?.data?.oil || "",
        bsw: data?.data?.bsw || "", 
      });
    }
  }, [id, data]);

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title={`${id ? "Edit FPSO Production" : "Add FPSO Production"}`}
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputWithFullBoarder
            label="Date"
            id="date"
            name="date"
            type="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.date && formik.errors.date}
            errorMessage={formik.errors.date}
          />

          <InputWithFullBoarder
            label="Oil (bbls)"
            id="oil"
            name="oil"
            placeholder="Enter Oil Volume"
            value={formik.values.oil}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.oil && formik.errors.oil}
            errorMessage={formik.errors.oil}
          />

          <InputWithFullBoarder
            label="Condensate Blended (bbls)"
            id="condensate_blended"
            name="condensate_blended"
            placeholder="Enter Condensate Blended Volume"
            value={formik.values.condensate_blended}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.condensate_blended &&
              formik.errors.condensate_blended
            }
            errorMessage={formik.errors.condensate_blended}
          />

          <InputWithFullBoarder
            label="Condensate Unblended (bbls)"
            id="condensate_unblended"
            name="condensate_unblended"
            placeholder="Enter Condensate Unblended Volume"
            value={formik.values.condensate_unblended}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.condensate_unblended &&
              formik.errors.condensate_unblended
            }
            errorMessage={formik.errors.condensate_unblended}
          />

          <InputWithFullBoarder
            label="BSW (%)"
            id="bsw"
            name="bsw"
            placeholder="Enter BSW Percentage"
            value={formik.values.bsw}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bsw && formik.errors.bsw}
            errorMessage={formik.errors.bsw}
          />
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading || updating}
            buttonText={`${id ? "Edit FPSO Production" : "Create FPSO Production"}`}
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddFPSOProduction;
