import React from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from "@headlessui/react";
import { X } from "lucide-react";

const LiftingDetailsModal = ({ isOpen, onClose, title,liftingData }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center">
        <DialogPanel className="w-full max-w-2xl bg-white rounded-lg p-6 text-left overflow-y-auto shadow-xl transform transition-all">
          {/* Close Button */}
          <div className="flex justify-between items-center border-b pb-3 mb-4">
            <DialogTitle className="text-xl font-semibold text-gray-900">
              {title}
            </DialogTitle>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>

          {/* Modal Content */}
          <DialogDescription className="space-y-6">
            <div className="">
              {/* Repeatable Item Component */}
              {[
                [
                  "Laycan Start and End Dates",
                  `${liftingData.laycanStartDate} - ${liftingData.laycanEndDate}`,
                ],
                ["Product", liftingData.product],
                ["Client", liftingData.client],
                ["Client Contract", liftingData.clientContract],
                ["Cargo Owner", liftingData.cargoOwner],
                ["Export Vessel", liftingData.exportVessel],
                ["Vessel Agent", liftingData.vesselAgent],
                ["Off Loading Port", liftingData.offLoadingPort],
                [
                  "Departure Vessel Draft (MT)",
                  liftingData.departureVesselDraft,
                ],
                ["Remarks", liftingData.remarks],
              ].map(([label, value], index) => (
                <div key={index} className="flex justify-between items-start">
                  <p className="text-sm font-medium text-gray-500">{label}</p>
                  <p className="text-sm font-medium text-gray-900">
                    {value || "Info"}
                  </p>
                </div>
              ))}
            </div>

            {/* Section Headers */}
            <div className="mt-4 border-t pt-4">
              <p className="text-sm font-semibold text-gray-700 mb-2">
                Bill of Lading Details
              </p>
              <div className="">
                {[
                  ["BL Quantity (MT)", liftingData.blQuantityMT],
                  ["BL Quantity (Ltrs)", liftingData.blQuantityLtrs],
                  [
                    "Expected Time of Departure (ETA)",
                    liftingData.expectedTimeDeparture,
                  ],
                  ["Laytime", liftingData.laytime],
                ].map(([label, value], index) => (
                  <div key={index} className="flex justify-between items-start">
                    <p className="text-sm font-medium text-gray-500">{label}</p>
                    <p className="text-sm font-medium text-gray-900">
                      {value || "Info"}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-4 border-t pt-4">
              <p className="text-sm font-semibold text-gray-700 mb-2">
                On Site Information
              </p>
              <div className="">
                {[
                  [
                    "Actual time of Departure (ATA)",
                    liftingData.actualTimeDeparture,
                  ],
                ].map(([label, value], index) => (
                  <div key={index} className="flex justify-between items-start">
                    <p className="text-sm font-medium text-gray-500">{label}</p>
                    <p className="text-sm font-medium text-gray-900">
                      {value || "Info"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </DialogDescription>

          {/* Close Button */}
          {/* <div className="mt-6 flex justify-end">
            <button
              type="button"
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={onClose}
            >
              Close
            </button>
          </div> */}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default LiftingDetailsModal;