import { FaSearch } from "react-icons/fa";
import { searchIcon } from "../assets/icons";

const SearchField = ({ searchValue, searchOnchange }) => {
  return (
    <div className="relative input input-bordered flex items-center gap-2">
      <img src={searchIcon} alt="Search Icon" />
      <input
        type="text"
        className="grow text-[#667185] text-[12px] w-[290px] "
        placeholder="Search here..."
        value={searchValue}
        onChange={searchOnchange}
      />
    </div>
  );
};

export default SearchField;
