import React, { useEffect, useState } from "react";
import oilIcon from "../../../assets/icons/oilIcon.svg";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, exploration, plusCircle, search } from "../../../assets/icons";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useGetProjectsManager from "../controller/getProjectsController";
import useDebounce from "../../../utils/UseDebounce";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/formatDate";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import Loader from "../../../generalComponents/Loader";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import OperatorsCard from "../../operators/component/OperatorsCard";
import AddWellOperation from "../../../generalComponents/project-management/AddWellOperation";


const cardData = [
  {
    title: "Total Number of Well Operation",
    value: "2,583",
    unit: "",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: exploration,
    bgColor: "bg-[#00AD51]",
    textColor: "text-white",
    iconBg: "bg-[#E5FFF1]",
  },
  {
    title: "Activity Status",
    value: "2,583",
    active: "2,583",
    inactive: "2,583",
    unit: "bopd",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: exploration,
    bgColor: "bg-white",
    textColor: "text-[#667185]",
    iconBg: "bg-[#E5FFF1]",
  },
];
const WellOperation = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [addWell, setAddWell ] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState("");
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    selectedMonth: "",
    roles: [],
  });

  // Convert filter state to a query string
  const searchQuery = [
    filter.startDate && `startDay=${filter.startDate}`,
    filter.endDate && `endDay=${filter.endDate}`,
    filter.selectedMonth && `month=${filter.selectedMonth}`,
    filter.roles.length > 0 && `roles=${filter.roles.join(",")}`,
  ]
    .filter(Boolean)
    .join("&");

  const { data, isLoading } = useGetProjectsManager({
    enabled: true,
    searchQuery: `${debouncedSearchValue}&${searchQuery}`,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  useEffect(() => {
    setSelectedStaff(true);
  }, [selectedStaff]);

  // Update filter criteria and refetch data
  const handleFilterUpdate = (newFilter) => {
    setFilter(newFilter);
  };

  const headers = [
    "Operation Name",
    "Operator",
    "Well",
    "OML",
    "Funding",
    "Plan (sq.km)",
    "Actual (sq.km)",
    "Percentage Coverage",
    "Status",
    "More",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <div key={index} className="flex items-center gap-5">
        <InitialsAvatar name={el?.user?.first_name ?? "No name"} />{" "}
        <p>{el?.user?.first_name ?? "No name"}</p>
      </div>,

      el?.user?.email,
      el?.user?.role ?? "N/A",
      formatDate(el?.user?.created_datetime ?? ""),
    ];
  };
  const toggleAddWell = () => {
    setAddWell(!addWell)
}

  return (
    <>
      <div className="flex flex-col gap-8 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {cardData.map((card, index) => (
            <OperatorsCard
              key={index}
              title={card.title}
              value={card.value}
              unit={card.unit}
              comparison={card.comparison}
              comparisonText={card.comparisonText}
              icon={card.icon}
              active={card.active}
              inactive={card.inactive}
              bgColor={card.bgColor}
              textColor={card.textColor}
              iconBg={card.iconBg}
            />
          ))}
        </div>
        <div>
          <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
            <div className="flex items-center justify-between px-4  pt-3">
              {/* search */}
              <div className="flex items-center justify-start">
                <div className="flex items-center">
                  <label className="input input-bordered flex items-center gap-2">
                  <img src={search} alt="" />
                    <input
                      type="text"
                      className="grow text-[#667185] text-[12px] w-[290px] "
                      placeholder="Search here..."
                    />
                  </label>
                  <div className="dropdown ">
                    <div
                      tabIndex={0}
                      role="button"
                      className="btn m-1 font-[600] bg-white border border-[#D0D5DD] rounded-[8px] shadow-custom-1 hover:shadow-custom-2 flex items-center py-[8px] px-[12px] text-[12px] text-[#344054]"
                    >
                      <Filter />
                      Filter
                    </div>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu ] bg-base-100 z-[1] w-52 p-2 shadow  "
                    >
                      <li>
                        <a>Item 1</a>
                      </li>
                      <li>
                        <a>Item 2</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* dropdown */}
              <div className="flex items-center gap-[5px]">
                <TransparentBtn text={"Export"} icon={download} />
                <GreenBtn
                  onClick={toggleAddWell}
                  icon={plusCircle}
                  text={"Add Well Operation"}
                />
              </div>
            </div>
            <div className="h-[67vh] w-full relative">
              {isLoading ? (
                <Loader />
              ) : (
                <TablesComponent
                  isLoading={isLoading}
                  data={data?.data?.results}
                  getFormattedValue={getFormattedValue}
                  headers={headers}
                  showCheckBox={false}
                  buttonFunction={(option) => {
                    // setIsViewStaffModalOpen(true);
                    // setSelectedStaffId(option.staff_id);
                  }}
                />
              )}
            </div>
            <NUIMSPagination pagination={data?.data?.pagination} />
          </div>
        </div>
      </div>
      <AddWellOperation onClose={toggleAddWell} open={addWell} />
    </>
  );
};

export default WellOperation;
