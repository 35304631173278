import { useNavigate } from "react-router-dom";
import React from "react";
import { comp2, comp3, comp4, company1 } from "../../../assets/icons";
import { ChevronRight } from "lucide-react";

const AssetCard = ({
  id,
  name,
  description,
  productionStatus,
  clients,
  productionValue,
}) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/assets/${id}`, {
      state: {
        asset: {
          id,
          name,
          description,
          productionStatus,
          productionValue,
        },
      },
    });
    //navigate(`?assetId=${id}`);
  };

  const companies = [clients?.[0]?.photo, clients?.[1]?.photo, clients?.[2]?.photo, clients?.[3]?.photo];

  return (
    <div
      onClick={handleCardClick}
      className="bg-white rounded-[10px] border border-[#F0F2F5] p-4 hover:bg-slate-50 cursor-pointer"
    >
      <div className="flex items-start h-full   justify-between">
        <div className="flex flex-col h-full">
          <div className="flex h-full mb-2">
            {/* Logos container with negative margins for stacking effect */}
            <div className="flex -space-x-4">
              {clients?.slice(0, 4).map((el, l) => (
                <div
                  key={l}
                  className="bg-white rounded-full flex items-center justify-center border-2 border-white"
                >
                  <img src={el?.photo} className="w-[40px] rounded-full h-[40px]" />
                </div>
              ))}
              {clients?.length > 4 && (
                <div className="flex items-center justify-center flex-shrink-0 bg-[#00AD51] rounded-full border-2 border-white z-[45]">
                  <span className="text-[12px] w-[40px] h-[40px] flex items-center justify-center text-white">
                    +{clients.length - 4}
                  </span>
                </div>
              )}
            </div>

          </div>

          <div className="flex items-center h-full justify-between border-b border-[#F0F2F5] pb-3 ">
            <div className="space-y-1 h-full">
              <h3 className="font-[600] text-[20px] leading-[24px] text-[#101928]">
                {name}
              </h3>
              <p className="text-[12px] text-[#667185] leading-[17px]">
                {description}
              </p>
            </div>
            <ChevronRight className="text-[#00AD51] ml-2" />
          </div>

          <div className="mt-4 flex items-center   h-fit justify-between">
            <span className="text-[12px] text-[#667185]">
              Production Status
            </span>
            <span className="text-[16px] font-[600] text-[#0F973D]">
              {productionValue}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetCard;
