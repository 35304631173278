import React, { useState } from "react";
import oilIcon from "../../../assets/icons/oilIcon.svg";
import OperatorsCard from "../../operators/component/OperatorsCard";
import {
  Card,
  CardHeader,
  StatsCard,
  YearSelector,
} from "./ProductionComponents";
import { FacilitiesChart, facilitiesData } from "./FacilitiesChart";

const chartConfigs = [
  {
    id: "fields",
    title: "Fields",
    data: facilitiesData,
  },
  {
    id: "wells",
    title: "Wells",
    data: facilitiesData,
  },
  {
    id: "reservoir",
    title: "Reservoir",
    data: facilitiesData,
  },
];

const ProductionChart = ({ title, data, selectedYear }) => (
  <div className="flex flex-col gap-4">
    <CardHeader>
      <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
    </CardHeader>
    <Card className="flex-1">
      <StatsCard title="Fields Production Volume" value={23567} />

      <div className="p-2">
        <div className="flex justify-between mb-4">
          <h1>Activity Status</h1>
          <YearSelector selected={selectedYear} />
        </div>
        <FacilitiesChart data={data} />
      </div>
    </Card>
  </div>
);

const Facilities = () => {
  const [selectedYear, setSelectedYear] = useState("2024");

  return (
    <div className="flex flex-col gap-8 w-full">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        {cardData.map((card, index) => (
          <OperatorsCard
            key={index}
            title={card.title}
            value={card.value}
            unit={card.unit}
            comparison={card.comparison}
            comparisonText={card.comparisonText}
            icon={card.icon}
            bgColor={card.bgColor}
            textColor={card.textColor}
            iconBg={card.iconBg}
          />
        ))}
      </div>

      {/* Production Charts Grid */}
      <div className="grid grid-cols-1 gap-6">
        {chartConfigs.map((chart) => (
          <ProductionChart
            key={chart.id}
            title={chart.title}
            data={chart.data}
            selectedYear={selectedYear}
          />
        ))}
      </div>
    </div>
  );
};

export default Facilities;

// Optional: You might want to move these to separate files
const cardData = [
  {
    title: "OMLS",
    value: "456",
    unit: "",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: oilIcon,
    bgColor: "bg-white",
    textColor: "text-[#667185]",
    iconBg: "bg-[#E5FFF1]",
  },
  {
    title: "Fields",
    value: "234",
    unit: "",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: oilIcon,
    bgColor: "bg-white",
    textColor: "text-[#667185]",
    iconBg: "bg-[#E5FFF1]",
  },
  {
    title: "Wells",
    value: "543",
    unit: "",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: oilIcon,
    bgColor: "bg-white",
    textColor: "text-[#667185]",
    iconBg: "bg-[#E5FFF1]",
  },
  {
    title: "Reservoirs",
    value: "299",
    unit: "bopd",
    comparison: "10%",
    comparisonText: "Compared to last month",
    icon: oilIcon,
    bgColor: "bg-white",
    textColor: "text-[#667185]",
    iconBg: "bg-[#E5FFF1]",
  },
];
