import React, { useEffect, useState } from "react";
import GreenBtn from "../../../generalComponents/GreenBtn";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import { download, plusCircle } from "../../../assets/icons";
import useDebounce from "../../../utils/UseDebounce";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import useGetOMLsManager from "../controllers/getOMLsController.js";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/formatDate";
import Loader from "../../../generalComponents/Loader";
import FilterDrawer from "./FilterDrawer";
import SearchField from "../../../generalComponents/SearchField.jsx";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination.jsx";
import FilterButton from "../../../generalComponents/FilterButton.jsx";
import AddOMLModal from "./AddOMLModal.jsx";
import OmlDetails from "./OmlDetails.jsx";
import ExportToCSV from "../../../utils/downloadInCSV.js";
import { DeleteOmlsManager } from "../controllers/deleteOMLsController.js";
import DeactivationModal from "../../../generalComponents/client-management/DeactiveClient.jsx";
import StatusButton from "../../../generalComponents/StatusButton.jsx"
const headers = [
  "OML Number",
  "Company",
  
  "State",
  "Application Date",
  "Onshore Size",
  "Basin Name",
  "More",
];

const Oml = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  // const handleClick = () => setIsModalOpen(true);
  const addOml = () => setIsOpen(true);
  
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOml, setSelectedOml] = useState(null);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    selectedMonth: "",
    roles: [],
  });

  // Convert filter state to a query string
  // const searchQuery = [
  //   filter.startDate && `startDay=${filter.startDate}`,
  //   filter.endDate && `endDay=${filter.endDate}`,
  //   filter.selectedMonth && `month=${filter.selectedMonth}`,
  //   filter.roles.length > 0 && `roles=${filter.roles.join(",")}`,
  // ]
  //   .filter(Boolean)
  //   .join("&");

  const { data, isLoading, refetch } = useGetOMLsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  const {deleteOmls, isLoading:deleting, isSuccess:deleted} = DeleteOmlsManager({id: selectedOml?.id})

  useEffect(() => {
    if(deleted){
      refetch()
    }
  }, [deleted])
  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  // Update filter criteria and refetch data
  const handleFilterUpdate = (newFilter) => {
    setFilter(newFilter);
  };
  const handleShowDeactivate = () => {
    setShowDeactivate(!showDeactivate)
 }

  const getFormattedValue = (el, index) => {
    return [
      el?.number ?? "N/A",
      <div key={index} className="flex items-center gap-5">
       { el?.client?.photo ? <img src={el?.client?.photo} alt="" className="w-10 h-10 rounded-full" /> : <InitialsAvatar name={el?.client?.name ?? "No name"} />}
        <p>{el?.client?.name ?? "No name"}</p>
      </div>,
      
      <StatusButton key={index} status={el?.status} /> ,
      formatDate(el?.application_date ?? ""),
      el?.onshore_size ?? "N/A",
      el?.basil_name ?? "N/A",
    ];
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const options = ["See Details", "Edit", "Delete"];
  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      {/* Tabs Section */}

      <>
        <div className="flex items-center justify-between mb-6">
          <h1 className="font-[600] text-[24px] mb-2 w-full">OMLs</h1>

          <div className="flex items-center gap-[5px]">
            <TransparentBtn text={"Export"} onClick={() => ExportToCSV(data?.data?.results, "OMLS")} icon={download} />
            <GreenBtn onClick={() => {addOml(); setSelectedOml("")}} icon={plusCircle} text={"Add OMLs"} />
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center space-x-2 justify-start pt-3 px-4">
            <SearchField
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
            <FilterButton onClick={() => setIsFilterDrawerOpen(true)} />
          </div>
          <div className="h-[67vh] w-full relative">
            {isLoading ? (
              <Loader />
            ) : (
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={headers}
                options={options}
                showCheckBox={false}
                popUpFunction={(option, index, val) => {
                  console.log(option, index, val);
                  setSelectedOml(val);
                  if (index === 0) {
                     
                    handleOpenModal();
                    navigate(`?clientId=${val.id}`);
                  }
                  if (index === 1) {
                    
                    addOml(); 
                  }if (index === 2) {
                     
                    handleShowDeactivate(); 
                  }
                }}
              />
            )}
          </div>
          {/* Pagination */}
          <NUIMSPagination pagination={data?.data?.pagination} />
        </div>
      </>
      <AddOMLModal open={isOpen} omlId={selectedOml?.id} onClose={() => setIsOpen(false)} />
      <OmlDetails
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        omlId={selectedOml?.id}
        
      />
      {showDeactivate && <DeactivationModal
        onClose={handleShowDeactivate} 
        onClick={() => deleteOmls()}
        isLoading={deleting}
        title={"Delete OML"}
        body={"Are you sure you want to proceed with this action? "}
        actionText={"Delete"}
      />}

      <FilterDrawer
        isOpen={isFilterDrawerOpen}
        onUpdateFilters={handleFilterUpdate}
        onClose={() => setIsFilterDrawerOpen(false)}
      />
    </div>
  );
};

export default Oml;
