import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { BaseModal } from "../../../generalComponents/BaseModal";
import { CreateFlowstationManager } from "../controllers/createFlowsationController";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  terrain: Yup.string()
    .oneOf(["land", "swamp", "offshore"])
    .required("Terrain is required"),
  specification: Yup.string().required("Specification is required"),
  date_completed: Yup.date().required("Date completed is required"),
  date_commissioned: Yup.date().required("Date commissioned is required"),
  design_capacity: Yup.string().required("Design capacity is required"),
  facility_status: Yup.string()
    .oneOf(["active", "inactive"])
    .required("Facility status is required"),
  design_gas_handling_capacity: Yup.string().required(
    "Design gas handling capacity is required"
  ),
  current_gas_handling_capacity: Yup.string().required(
    "Current gas handling capacity is required"
  ),
  design_water_handling_capacity: Yup.string().required(
    "Design water handling capacity is required"
  ),
  current_water_handling_capacity: Yup.string().required(
    "Current water handling capacity is required"
  ),
  design_type: Yup.string()
    .oneOf(["conventional", "non-conventional"])
    .required("Design type is required"),
  oml: Yup.string().required("OML is required"),
});

const AddFlowstation = ({ isOpen, onClose }) => {
  const { createFlowstation, isLoading } = CreateFlowstationManager();

  const formik = useFormik({
    initialValues: {
      name: "",
      terrain: "land",
      specification: "",
      date_completed: "",
      date_commissioned: "",
      design_capacity: "",
      facility_status: "active",
      design_gas_handling_capacity: "",
      current_gas_handling_capacity: "",
      design_water_handling_capacity: "",
      current_water_handling_capacity: "",
      design_type: "conventional",
      oml: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await createFlowstation(values);
        onClose(); // Close modal after successful creation
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      title="Create Flowstation"
      maxWidth="xl"
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4">
        <div className="gap-3 mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputWithFullBoarder
              label="Name"
              id="name"
              name="name"
              placeholder="Enter name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && formik.errors.name}
              errorMessage={formik.errors.name}
            />

            <InputWithFullBoarder
              label="Terrain"
              id="terrain"
              name="terrain"
              placeholder="Enter terrain (land, swamp, offshore)"
              value={formik.values.terrain}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.terrain && formik.errors.terrain}
              errorMessage={formik.errors.terrain}
            />

            <InputWithFullBoarder
              label="Specification"
              id="specification"
              name="specification"
              placeholder="Enter specification"
              value={formik.values.specification}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.specification && formik.errors.specification
              }
              errorMessage={formik.errors.specification}
            />

            <InputWithFullBoarder
              label="Date Completed"
              id="date_completed"
              name="date_completed"
              type="date"
              value={formik.values.date_completed}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.date_completed && formik.errors.date_completed
              }
              errorMessage={formik.errors.date_completed}
            />

            <InputWithFullBoarder
              label="Date Commissioned"
              id="date_commissioned"
              name="date_commissioned"
              type="date"
              value={formik.values.date_commissioned}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.date_commissioned &&
                formik.errors.date_commissioned
              }
              errorMessage={formik.errors.date_commissioned}
            />

            <InputWithFullBoarder
              label="Design Capacity"
              id="design_capacity"
              name="design_capacity"
              placeholder="Enter design capacity"
              value={formik.values.design_capacity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.design_capacity && formik.errors.design_capacity
              }
              errorMessage={formik.errors.design_capacity}
            />

            <InputWithFullBoarder
              label="Facility Status"
              id="facility_status"
              name="facility_status"
              placeholder="Enter facility status (active or inactive)"
              value={formik.values.facility_status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.facility_status && formik.errors.facility_status
              }
              errorMessage={formik.errors.facility_status}
            />

            <InputWithFullBoarder
              label="Design Gas Handling Capacity"
              id="design_gas_handling_capacity"
              name="design_gas_handling_capacity"
              placeholder="Enter design gas handling capacity"
              value={formik.values.design_gas_handling_capacity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.design_gas_handling_capacity &&
                formik.errors.design_gas_handling_capacity
              }
              errorMessage={formik.errors.design_gas_handling_capacity}
            />

            <InputWithFullBoarder
              label="Current Gas Handling Capacity"
              id="current_gas_handling_capacity"
              name="current_gas_handling_capacity"
              placeholder="Enter current gas handling capacity"
              value={formik.values.current_gas_handling_capacity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.current_gas_handling_capacity &&
                formik.errors.current_gas_handling_capacity
              }
              errorMessage={formik.errors.current_gas_handling_capacity}
            />

            <InputWithFullBoarder
              label="Design Water Handling Capacity"
              id="design_water_handling_capacity"
              name="design_water_handling_capacity"
              placeholder="Enter design water handling capacity"
              value={formik.values.design_water_handling_capacity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.design_water_handling_capacity &&
                formik.errors.design_water_handling_capacity
              }
              errorMessage={formik.errors.design_water_handling_capacity}
            />

            <InputWithFullBoarder
              label="Current Water Handling Capacity"
              id="current_water_handling_capacity"
              name="current_water_handling_capacity"
              placeholder="Enter current water handling capacity"
              value={formik.values.current_water_handling_capacity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.current_water_handling_capacity &&
                formik.errors.current_water_handling_capacity
              }
              errorMessage={formik.errors.current_water_handling_capacity}
            />

            <InputWithFullBoarder
              label="Design Type"
              id="design_type"
              name="design_type"
              placeholder="Enter design type (conventional or non-conventional)"
              value={formik.values.design_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.design_type && formik.errors.design_type}
              errorMessage={formik.errors.design_type}
            />

            <InputWithFullBoarder
              label="OML"
              id="oml"
              name="oml"
              placeholder="Enter OML"
              value={formik.values.oml}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.oml && formik.errors.oml}
              errorMessage={formik.errors.oml}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-1">
          <CustomButton
            type="submit"
            isLoading={formik.isSubmitting || isLoading}
            buttonText="Create Flowstation"
            className="w-full"
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AddFlowstation;
