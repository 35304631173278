import React from "react";

const PositiveIcon = () => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.18376 7.29175L7.51709 1.95841"
      stroke="#14CA74"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.51709 6.98682V1.95853H2.48881"
      stroke="#14CA74"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Negative SVG Component
const NegativeIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2109_11167)">
      <path
        d="M1.33325 1.33341L6.66659 6.66675"
        stroke="#FF5A65"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.63818 6.66675L6.66647 6.66675L6.66647 1.63846"
        stroke="#FF5A65"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2109_11167">
        <rect
          width="8"
          height="8"
          fill="white"
          transform="translate(8) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

const MoreIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00835 9.84634C8.68363 9.84634 9.23105 9.2995 9.23105 8.62495C9.23105 7.9504 8.68363 7.40356 8.00835 7.40356C7.33307 7.40356 6.78564 7.9504 6.78564 8.62495C6.78564 9.2995 7.33307 9.84634 8.00835 9.84634Z"
      fill="#00AD51"
    />
    <path
      d="M13.7857 9.84634C14.461 9.84634 15.0084 9.2995 15.0084 8.62495C15.0084 7.9504 14.461 7.40356 13.7857 7.40356C13.1104 7.40356 12.563 7.9504 12.563 8.62495C12.563 9.2995 13.1104 9.84634 13.7857 9.84634Z"
      fill="#00AD51"
    />
    <path
      d="M2.231 9.84634C2.90629 9.84634 3.45371 9.2995 3.45371 8.62495C3.45371 7.9504 2.90629 7.40356 2.231 7.40356C1.55572 7.40356 1.0083 7.9504 1.0083 8.62495C1.0083 9.2995 1.55572 9.84634 2.231 9.84634Z"
      fill="#00AD51"
    />
  </svg>
);

const DashboardCards = ({
  icon: Icon,
  title,
  value,
  percentage,
  isPositive,
}) => {
  return (
    <div className="bg-[#F6F8FC] p-3 rounded-[8px] items-center border-[0.6px] border-[#E0E0E0] mb-4">
      <div className=" flex justify-between w-full mb-3">
        <div className="flex gap-1 justify-between">
          <Icon className="w-[11px] h-[17px] mr-2" />
          <p className="text-[#828282] text-[12px]">{title}</p>
        </div>
        <MoreIcon />
      </div>
      <div className="flex gap-1 items-center">
        <div>
          <h3 className="text-[24px] font-[600]">{value}</h3>
        </div>
        <p
          className={`text-[10px] font-[500] rounded px-1 flex items-center ${
            isPositive
              ? "text-[#14CA74] bg-[#05C16833] "
              : "text-[#FF5A65] bg-[#FF5A6533]"
          }`}
        >
          {percentage} {isPositive ? <PositiveIcon /> : <NegativeIcon />}
        </p>
      </div>
    </div>
  );
};

export default DashboardCards;
