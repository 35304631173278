import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const AddPortManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/ports",
    ["ports"],
    true
  );
  const addPort = async (details) => {
    try {
      console.log("this is the data", details);
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    addPort,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
