import React from "react";
import DashboardCards from "./DashboardCards";

const FlagIcon = () => (
  <svg
    width="12"
    height="19"
    viewBox="0 0 12 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68376 1.70833C1.68376 1.2481 1.31066 0.875 0.850423 0.875C0.390186 0.875 0.0170898 1.2481 0.0170898 1.70833V17.5417C0.0170898 18.0019 0.390186 18.375 0.850423 18.375C1.31066 18.375 1.68376 18.0019 1.68376 17.5417V10.0456C5.33661 11.0199 7.74034 11.5917 11.3223 11.0782C11.9007 10.9953 12.1846 10.3431 11.8749 9.84754L10.4338 7.54167L11.1796 5.55288C11.4042 4.95377 10.8954 4.31815 10.2577 4.37128C8.34581 4.53059 4.95835 4.59658 2.93376 3.375C2.5324 3.13283 2.07049 2.77121 1.68376 2.44664V1.70833Z"
      fill="#828282"
    />
  </svg>
);

const AssetGroupIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.12453 4.55523C2.94636 4.0207 2.52691 3.60125 1.99237 3.42307C1.44836 3.24173 1.44836 2.47224 1.99237 2.2909C2.52691 2.11273 2.94636 1.69328 3.12453 1.15874C3.30587 0.61473 4.07536 0.614729 4.2567 1.15874C4.43488 1.69328 4.85433 2.11273 5.38886 2.2909C5.93287 2.47224 5.93287 3.24173 5.38886 3.42307C4.85433 3.60125 4.43488 4.0207 4.2567 4.55523C4.07536 5.09924 3.30587 5.09924 3.12453 4.55523Z"
      fill="#828282"
    />
    <path
      d="M3.24856 15.2772C2.8922 14.2081 2.0533 13.3692 0.984231 13.0128C-0.103793 12.6502 -0.103793 11.1112 0.984231 10.7485C2.0533 10.3922 2.8922 9.55326 3.24856 8.48419C3.61123 7.39616 5.15021 7.39616 5.51288 8.48419C5.86924 9.55326 6.70814 10.3922 7.77721 10.7485C8.86523 11.1112 8.86523 12.6502 7.77721 13.0128C6.70814 13.3692 5.86924 14.2081 5.51288 15.2772C5.15021 16.3652 3.61123 16.3652 3.24856 15.2772Z"
      fill="#828282"
    />
    <path
      d="M9.82163 7.38462C10.6234 7.65189 11.2526 8.28106 11.5199 9.08287C11.7919 9.89888 12.9461 9.89888 13.2181 9.08287C13.4854 8.28106 14.1146 7.65189 14.9164 7.38462C15.7324 7.11261 15.7324 5.95838 14.9164 5.68638C14.1146 5.41911 13.4854 4.78993 13.2181 3.98813C12.9461 3.17211 11.7919 3.17211 11.5199 3.98813C11.2526 4.78993 10.6234 5.41911 9.82163 5.68638C9.00562 5.95838 9.00562 7.11261 9.82163 7.38462Z"
      fill="#828282"
    />
  </svg>
);
const OmlIcon = () => (
  <svg
    width="12"
    height="17"
    viewBox="0 0 12 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.90501 11.9409C5.51547 12.5314 6.48428 12.5314 7.09473 11.9409C7.44381 11.6032 7.92921 11.4455 8.41012 11.5135C9.2511 11.6324 10.0349 11.063 10.1816 10.2264C10.2656 9.748 10.5655 9.3351 10.9946 9.10746C11.7449 8.70937 12.0443 7.78798 11.6712 7.02492C11.4579 6.58856 11.4579 6.07819 11.6712 5.64183C12.0443 4.87877 11.7449 3.95737 10.9946 3.55929C10.5655 3.33165 10.2656 2.91875 10.1816 2.44035C10.0349 1.60377 9.2511 1.03432 8.41012 1.15327C7.92921 1.22129 7.44381 1.06358 7.09473 0.725874C6.48428 0.135321 5.51547 0.135321 4.90501 0.725874C4.55593 1.06358 4.07054 1.22129 3.58962 1.15327C2.74864 1.03432 1.96486 1.60377 1.81811 2.44035C1.73419 2.91875 1.4342 3.33165 1.00515 3.55929C0.254862 3.95737 -0.0445169 4.87877 0.328493 5.64183C0.541796 6.07819 0.541796 6.58856 0.328493 7.02492C-0.0445168 7.78798 0.254861 8.70937 1.00515 9.10746C1.4342 9.3351 1.73419 9.748 1.81811 10.2264C1.96486 11.063 2.74864 11.6324 3.58962 11.5135C4.07054 11.4455 4.55593 11.6032 4.90501 11.9409ZM5.43379 4.63508C5.25561 5.16962 4.83616 5.58907 4.30163 5.76724C3.75761 5.94858 3.75761 6.71807 4.30163 6.89941C4.83616 7.07759 5.25561 7.49704 5.43379 8.03157C5.61513 8.57558 6.38462 8.57558 6.56595 8.03157C6.74413 7.49704 7.16358 7.07759 7.69812 6.89941C8.24213 6.71807 8.24213 5.94858 7.69812 5.76724C7.16358 5.58907 6.74413 5.16962 6.56595 4.63508C6.38462 4.09107 5.61513 4.09107 5.43379 4.63508Z"
      fill="#828282"
    />
    <path
      d="M7.92496 12.4541L6.74528 13.044C6.27606 13.2786 5.72377 13.2786 5.25456 13.044L4.07488 12.4541C3.86421 12.3488 3.60995 12.4636 3.54957 12.6912C3.51661 12.8155 3.49992 12.9436 3.49992 13.0722V15.8352C3.49992 16.7615 4.69142 17.1384 5.22409 16.3806C5.60178 15.8433 6.39806 15.8433 6.77575 16.3806C7.30842 17.1384 8.49992 16.7615 8.49992 15.8352V13.0722C8.49992 12.9436 8.48323 12.8155 8.45026 12.6912C8.38989 12.4636 8.13563 12.3488 7.92496 12.4541Z"
      fill="#828282"
    />
  </svg>
);

const cardData = [
  {
    icon: FlagIcon,
    title: "Operators",
    value: "12.8K",
    percentage: "28.4%",
    isPositive: true,
  },
  {
    icon: AssetGroupIcon,
    title: "Asset Group Mgt",
    value: "200",
    percentage: "12.6%",
    isPositive: false,
  },
  {
    icon: OmlIcon,
    title: "OMLs",
    value: "756",
    percentage: "3.1%",
    isPositive: true,
  },
];

const DashboardList = () => {
  return (
    <div className="grid grid-cols-3 gap-4">
      {cardData.map((data, index) => (
        <DashboardCards
          key={index}
          icon={data.icon}
          title={data.title}
          value={data.value}
          percentage={data.percentage}
          isPositive={data.isPositive}
        />
      ))}
    </div>
  );
};

export default DashboardList;
