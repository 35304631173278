import React from "react";
import { X, Upload } from "lucide-react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Field name is required"),
  size: Yup.string().required("Field size is required"),
  oml: Yup.string().required("OML is required"),
  location: Yup.string().required("Location is required"),
  terrain: Yup.string().required("Terrain is required"),
  longitude: Yup.string().required("Longitude is required"),
  latitude: Yup.string().required("Latitude is required"),
  longitudeSummary: Yup.string().required("Longitude summary is required"),
  latitudeSummary: Yup.string().required("Latitude summary is required"),
  dateDiscovered: Yup.date().required("Discovery date is required"),
  dateDrilled: Yup.date().required("Drilling date is required"),
  dateExploration: Yup.date().required(
    "Exploration completion date is required"
  ),
  area: Yup.string().required("Area is required"),
  structuralGeology: Yup.string().required("Structural geology is required"),
  reservoirDescription: Yup.string().required(
    "Reservoir description is required"
  ),
  oilRecovery: Yup.number()
    .min(0)
    .max(100)
    .required("Oil recovery is required"),
  gasRecovery: Yup.number()
    .min(0)
    .max(100)
    .required("Gas recovery is required"),
  condensateRecovery: Yup.number()
    .min(0)
    .max(100)
    .required("Condensate recovery is required"),
});

const initialValues = {
  name: "",
  size: "",
  oml: "",
  shoreType: "onshore",
  status: "producing",
  location: "",
  terrain: "",
  longitude: "",
  latitude: "",
  longitudeSummary: "",
  latitudeSummary: "",
  dateDiscovered: "",
  dateDrilled: "",
  dateExploration: "",
  area: "",
  structuralGeology: "",
  reservoirDescription: "",
  oilRecovery: "",
  gasRecovery: "",
  condensateRecovery: "",
};

const AddOilFieldModal = ({ isOpen, onClose }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
    // Add your submission logic here
  };

  if (!isOpen) return null;

  return (
    <dialog open={isOpen} className="modal modal-open">
      <div className="modal-box w-11/12 max-w-4xl max-h-[90vh] rounded-[10px] overflow-y-auto">
        <div className="sticky top-0 bg-base-100 pt-4 pb-2">
          <div className="flex justify-between items-center">
            <h2 className="text-[24px] font-[600]">Add Oil Field</h2>
            <button
              onClick={onClose}
              className="btn btn-ghost btn-sm btn-circle"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
          <p className="text-[13px] leading-[18px] text-[#667185] text-base-content/60">
            Enter the required information below to add a field
          </p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="space-y-4 mt-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Name
                    </span>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Enter field name"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.name && errors.name ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Size
                    </span>
                    <Field
                      name="size"
                      type="text"
                      placeholder="Enter field size"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.size && errors.size ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="size"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      OML
                    </span>
                    <Field
                      name="oml"
                      type="text"
                      placeholder="Enter OML"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.oml && errors.oml ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="oml"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="mb-2 font-medium">Shore Type</p>
                  <div className="flex gap-4">
                    <label className="label cursor-pointer">
                      <Field
                        type="radio"
                        name="shoreType"
                        value="onshore"
                        className="radio radio-success"
                      />
                      <span className="label-text ml-2">Onshore</span>
                    </label>
                    <label className="label cursor-pointer">
                      <Field
                        type="radio"
                        name="shoreType"
                        value="offshore"
                        className="radio radio-success "
                      />
                      <span className="label-text ml-2">Offshore</span>
                    </label>
                  </div>
                </div>

                <div>
                  <p className="mb-2 font-medium">Status</p>
                  <div className="flex gap-4">
                    <label className="label cursor-pointer">
                      <Field
                        type="radio"
                        name="status"
                        value="producing"
                        className="radio radio-success"
                      />
                      <span className="label-text ml-2">Producing</span>
                    </label>
                    <label className="label cursor-pointer">
                      <Field
                        type="radio"
                        name="status"
                        value="non-producing"
                        className="radio radio-success"
                      />
                      <span className="label-text ml-2">Non-Producing</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Location
                    </span>
                    <Field
                      name="location"
                      type="text"
                      placeholder="Enter Location"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.location && errors.location ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="location"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Terrain
                    </span>
                    <Field
                      name="terrain"
                      type="text"
                      placeholder="Enter terrain"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.terrain && errors.terrain ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="terrain"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {[
                  "longitude",
                  "latitude",
                  "longitudeSummary",
                  "latitudeSummary",
                ].map((field) => (
                  <div key={field}>
                    <label className="label flex flex-col items-start">
                      <span className="text-[12px] text-[#181918] font-[500]">
                        {field.replace(/([A-Z])/g, " $1").toLowerCase()}
                      </span>
                      <Field
                        name={field}
                        type="text"
                        placeholder={`Enter ${field
                          .replace(/([A-Z])/g, " $1")
                          .toLowerCase()}`}
                        className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                          touched[field] && errors[field] ? "input-error" : ""
                        }`}
                      />
                    </label>
                    <ErrorMessage
                      name={field}
                      component="div"
                      className="text-error text-sm mt-1"
                    />
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {[
                  { name: "dateDiscovered", label: "Date Discovered" },
                  { name: "dateDrilled", label: "Date Drilled" },
                  {
                    name: "dateExploration",
                    label: "Date of Exploration Completion",
                  },
                ].map((field) => (
                  <div key={field.name}>
                    <label className="label flex flex-col items-start">
                      <span className="text-[12px] text-[#181918] font-[500]">
                        {field.label}
                      </span>
                      <Field
                        name={field.name}
                        type="date"
                        className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px]  ${
                          touched[field.name] && errors[field.name]
                            ? "input-error"
                            : ""
                        }`}
                      />
                    </label>
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      className="text-error text-sm mt-1"
                    />
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Area
                    </span>
                    <Field
                      name="area"
                      type="text"
                      placeholder="Enter area"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.area && errors.area ? "input-error" : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="area"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
                <div>
                  <label className="label flex flex-col items-start">
                    <span className="text-[12px] text-[#181918] font-[500]">
                      Structural Geology
                    </span>
                    <Field
                      name="structuralGeology"
                      type="text"
                      placeholder="Enter structural geology"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched.structuralGeology && errors.structuralGeology
                          ? "input-error"
                          : ""
                      }`}
                    />
                  </label>
                  <ErrorMessage
                    name="structuralGeology"
                    component="div"
                    className="text-error text-sm mt-1"
                  />
                </div>
              </div>

              <div>
                <label className="label flex flex-col items-start">
                  <span className="text-[12px] text-[#181918] font-[500]">
                    Reservior
                  </span>
                  <Field
                    as="textarea"
                    name="reservoirDescription"
                    placeholder="Enter reservoir description"
                    className={`textarea textarea-bordered w-full h-24 bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] ${
                      touched.reservoirDescription &&
                      errors.reservoirDescription
                        ? "textarea-error"
                        : ""
                    }`}
                  />
                </label>
                <ErrorMessage
                  name="reservoirDescription"
                  component="div"
                  className="text-error text-sm mt-1"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {[
                  { item: "oilRecovery", label: "Oil Ulitimate Recovery" },
                  { item: "gasRecovery", label: "Gas Ulitimate Recovery" },
                  {
                    item: "condensateRecovery",
                    label: "Condensate Ultimate Recovery",
                  },
                ].map((field) => (
                  <div key={field.item}>
                    <label className="label flex flex-col items-start">
                      <span className="text-[12px] text-[#181918] font-[500]">
                        {field.label}
                      </span>
                    </label>
                    <Field
                      name={field.item}
                      type="number"
                      placeholder="Enter recovery"
                      className={`input input-bordered input-sm w-full bg-[#F6F8FC] placeholder:text-[12px] placeholder:text-[#989898] rounded-[5px] ${
                        touched[field] && errors[field] ? "input-error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name={field}
                      component="div"
                      className="text-error text-sm mt-1"
                    />
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-between w-[50%] p-4">
                <div>
                  <div className="flex items-center gap-2 text-sm text-base-content/60">
                    <div className="bg-[#F0F2F5] w-[48px] h-[48px] rounded-full flex items-center justify-center">
                      <Upload className="h-[22px] w-[22px] text-[#475367]" />
                    </div>
                    <div>
                      <span className="text-[14px] font-[600]">
                        Upload your document
                      </span>
                      <p className="text-xs text-base-content/40 mt-1">
                        PDF format • Max: 5MB
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-outline btn-sm mt-2 rounded-[20px] bg-[#00AD51] text-white text-[13px] font-[600]"
                >
                  Upload
                </button>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="btn btn-success bg-[#00AD51] rounded-[40px] text-[13px] font-[500] text-white"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Adding..." : "Add Oil Field"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default AddOilFieldModal;
