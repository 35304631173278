// ListOfManifolds.jsx
import React, { useEffect, useState } from "react";
import TransparentBtn from "../../../generalComponents/TransparentButton";
import GreenBtn from "../../../generalComponents/GreenBtn";
import { download, plusCircle, upload } from "../../../assets/icons";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import useGetManifoldsManager from "../controllers/getManifoldsController";

const ListOfManifolds = () => {
  const [showAddManifold, setShowAddManifold] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedManifold, setSelectedManifold] = useState("");
  const navigate = useNavigate();

  const handleAddManifold = () => {
    navigate("");
    setShowAddManifold(true);
  };

  const { data, isLoading } = useGetManifoldsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const headers = [
    "Name",
    "Status",
    "Type",
    "Input Lines",
    "Output Lines",
    "Operating Pressure",
    "Flow Rate",
    "Location",
    "Connected Wells",
    "Last Inspection",
    "More",
  ];

  useEffect(() => {
    setSelectedManifold(true);
  }, [selectedManifold]);

  const options = [
    "View Manifold",
    "Edit Manifold",
    "Flow Configuration",
    "Pressure Readings",
    "Maintenance Log",
    "Delete Manifold",
  ];

  const getFormattedValue = (el, index) => {
    return [
      el?.name,
      formatDate(el?.created_datetime),
      el?.email,
      el?.role || "N/A",
      formatDate(el?.created_datetime),
    ];
  };

  return (
    <div className="bg-white rounded-[10px] min-h-screen p-4 w-full">
      <>
        <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
          <div className="flex items-center justify-between pt-3 px-4">
            <DropdownandSearch
              options={["Option1", "Option"]}
              searchValue={searchValue}
              searchOnchange={(e) => setSearchValue(e.target.value)}
            />
            <div className="flex items-center gap-[5px]">
              <TransparentBtn text={"Export"} icon={download} />
              <GreenBtn
                onClick={handleAddManifold}
                icon={upload}
                text={"Upload Manifold"}
              />
              <GreenBtn
                onClick={handleAddManifold}
                icon={plusCircle}
                text={"Add Manifold"}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              options={options}
              showCheckBox={false}
              popUpFunction={(option, index, val) => {
                if (index === 2) {
                  setSelectedManifold(val.id);
                  setShowAddManifold(true);
                }
              }}
            />
          </div>

          <NUIMSPagination
            pagination={data?.data?.pagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </>
    </div>
  );
};

export default ListOfManifolds;
