import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";

const data = [
  { month: 1, oil: 180, gas: 220 },
  { month: 2, oil: 220, gas: 270 },
  { month: 3, oil: 250, gas: 230 },
  { month: 4, oil: 350, gas: 300 },
  { month: 5, oil: 170, gas: 190 },
  { month: 6, oil: 220, gas: 280 },
  { month: 7, oil: 380, gas: 450 },
  { month: 8, oil: 370, gas: 400 },
  { month: 9, oil: 330, gas: 350 },
  { month: 10, oil: 470, gas: 410 },
  { month: 11, oil: 100, gas: 200 },
  { month: 12, oil: 900, gas: 950 },
];

const ProductionLineChart = () => {
  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <XAxis
          dataKey="month"
          tickLine={false}
          padding={{ left: 20 }}
          textAnchor="middle"
          tick={{
            transform: "translate(0, 5)",
            fontSize: 8,
            fill: "#000000",
            fontWeight: 400,
          }}

          // ="text-[#7A7A7A] font-[400]"
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          padding={{ bottom: 20 }}
          tick={{ fontSize: 10, fill: "#475367", font: 400 }}
          // ticks={[0, 100, 200, 300, 400, 500]}
        />
        <Legend
          verticalAlign="top"
          align="right"
          iconType="circle"
          iconSize={8}
        />
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke="#e0e0e0"
        />
        <Line
          dataKey="oil"
          type="monotone"
          stroke="#F56630"
          strokeWidth={2}
          dot={false}
        />
        <Line
          dataKey="gas"
          type="monotone"
          stroke="#00AD51"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ProductionLineChart;
