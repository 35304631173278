import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

import FormWrapper from "../../../generalComponents/FormWrapper";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../generalComponents/Button";
import { LoginManager } from "../controllers/login";
import { emailIcon } from "../../../assets/icons";

// Validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { login, isLoading, isSuccess, data } = LoginManager();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/otp?email=${email}`);
    }
  });

  const handleSubmit = async (values) => {
    const res = await login(values);
    setEmail(values.email);
    localStorage.setItem("token", res.token);
    localStorage.setItem("user", res.user);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormWrapper>
      <div className="bg-white p-8 rounded-[10px] border border-[#E0E0E0] w-full max-w-sm">
        <h2 className="text-[25px] flex justify-start font-[600] mb-1 ">
          Log in
        </h2>
        <p className="text-[#667185] text-[13px] font-[400] mb-[40px]">
          Enter your credentials to access your account
        </p>
        <Formik
          initialValues={{ email: "", password: "", rememberMe: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            {/* Email field */}
            <div className="mb-4 relative">
              <label
                htmlFor="email"
                className="block text-[13px] font-[500] text-[#101928]"
              >
                EMAIL ADDRESS
              </label>
              <div className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center">
                <img
                  src={emailIcon}
                  alt="Email Icon"
                  className="h-[15PX] w-[18px]"
                />
              </div>
              <Field
                type="email"
                name="email"
                placeholder="Enter Email"
                className="mt-1 block w-full pl-2 text-[11px] p-2 py-3 border rounded-md"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            {/* Password field */}
            <div className="mb-4 relative">
              <label
                htmlFor="password"
                className="block text-[13px] font-[500] text-[#101928]"
              >
                PASSWORD
              </label>
              <div className="relative">
                <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter Password"
                  className="mt-1 block w-full pl-2 text-[11px] p-2 py-3 border rounded-md"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  {/* Toggle eye icon */}
                  {showPassword ? (
                    <FaEyeSlash
                      onClick={togglePasswordVisibility}
                      className="cursor-pointer text-[#667185]"
                    />
                  ) : (
                    <FaEye
                      onClick={togglePasswordVisibility}
                      className="cursor-pointer text-[#667185]"
                    />
                  )}
                </div>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <label className="flex items-center">
                <Field
                  type="checkbox"
                  name="rememberMe"
                  className="h-4 w-4 text-green-600 border-[#D0D5DD] rounded"
                />
                <span className="ml-2 text-[13px] font-[500]">
                  Remember me for 30 days
                </span>
              </label>
              <a
                href="#"
                className="text-[13px] font-[500] text-[#00AD51] hover:underline"
              >
                Forgot Password?
              </a>
            </div>
            <CustomButton
              type={"submit"}
              disabled={isLoading}
              isLoading={isLoading}
              buttonText={"Log into Account"}
              className={"w-full"}
            />
          </Form>
        </Formik>
      </div>
    </FormWrapper>
  );
};

export default LoginForm;
