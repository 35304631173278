import React from "react";
import nnpc from "../assets/images/nnpcLogo.svg";
import backgroundImage from "../assets/images/backgroundImage.svg";

const FormWrapper = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col justify-center bg-[#F7FFFB] relative">
      <div className="flex justify-center mb-6">
        <img src={nnpc} alt="Logo" className="w-[153px] h-[86.06px]" />
      </div>

      <div className="flex justify-center">{children}</div>

      <div className="absolute bottom-0 right-0 left-[1184px]">
        <img
          src={backgroundImage}
          alt="Background Decoration"
          className="w-[316px] h-[352.94px]"
        />
      </div>
    </div>
  );
};

export default FormWrapper;
