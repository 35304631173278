import React, { useEffect, useState } from "react";
import TablesComponent from "../../../generalComponents/TablesComponent";
import NUIMSPagination from "../../../generalComponents/NUIMSPagination";
import FilterButton from "../../../generalComponents/FilterButton";
import Loader from "../../../generalComponents/Loader";
import SearchField from "../../../generalComponents/SearchField";
import useDebounce from "../../../utils/UseDebounce";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";
import { formatDate } from "../../../utils/formatDate";
import FilterDrawer from "./FilterDrawer";
import useGetAllClientsManager from "../../clientManagement/controllers/getClientsController";

const ListOfOperators = ({ id }) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    status: [],
    permissions: [],
  });
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  // const searchQuery = [
  //   debouncedSearchValue,
  //   filters.startDate && `startDay=${filters.startDate}`,
  //   filters.endDate && `endDay=${filters.endDate}`,
  //   filters.status.length > 0 && `status=${filters.status.join(",")}`,
  //   filters.permissions.length > 0 &&
  //     `permissions=${filters.permissions.join(",")}`,
  // ]
  //   .filter(Boolean)
  //   .join("&");

  const { data, isLoading } = useGetAllClientsManager({
    assetGroup: `&asset_group=${id}`,
    enabled: Boolean(id),
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  // Update filter criteria and refetch data
  const handleFilterUpdate = (newFilters) => {
    setFilters(newFilters);
  };

  console.log(data);
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const operators = data?.data?.results ?? [];
  const totalOperators = operators.length;

  const getFormattedValue = (el, index) => {
    return [
      <div key={`name-${index}`} className="flex items-center gap-5">
        {el?.photo ? <img src={el?.photo} alt="" className="w-10 h-10 rounded-full" /> :<InitialsAvatar name={`${el?.name ?? "N/A"}`} />}
        <p>{`${el?.name ?? "N/A"} `}</p>
      </div>,
      el?.email ?? "N/A",
      el?.status ?? "N/A",
      formatDate(el?.created_datetime ?? ""),
      formatDate(el?.updated_datetime ?? ""),
    ];
  };
  const headers = [
    "Name",
    "Email Address",
    "Status",
    "Created Date",
    "Updated Date",
    "Action",
  ];

  return (
    <div className="flex flex-col gap-4 bg-[#ffffff] rounded-[10px] border border-[#E4E7EC] shadow-custom-3">
      <div className="flex items-center space-x-2 justify-between pt-3 px-4">
        <p>{totalOperators} operators in total</p>
        <div className="flex items-center gap-2">
          <SearchField
            placeholder={"Search here..."}
            searchValue={searchValue}
            searchOnchange={handleSearch}
          />
          <FilterButton onClick={() => setIsFilterDrawerOpen(true)} />
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {isLoading ? (
          <Loader />
        ) : (
          <TablesComponent
            isLoading={isLoading}
            data={operators}
            getFormattedValue={getFormattedValue}
            headers={headers}
            showCheckBox={false}
            buttonFunction={console.log("object")}
          />
        )}
      </div>

      {/* Pagination */}

      <NUIMSPagination
        pagination={data?.data?.pagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <FilterDrawer
        isOpen={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        onUpdateFilters={handleFilterUpdate}
        initialFilters={filters}
      />
    </div>
  );
};

export default ListOfOperators;
