import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../../generalComponents/Loader";
import { company1 } from "../../../assets/icons";
import SearchField from "../../../generalComponents/SearchField";
import GlobalVariables from "../../../utils/GlobalVariables";
import InitialsAvatar from "../../../generalComponents/InitialsAvatar";

const OperatorSidebar = ({
  isLoading,
  data,
  setSearchValue,
  onToggle,
  currentIndex,
  setCurrentIndex,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [searchValue, setLocalSearchValue] = useState("");

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (onToggle) {
      onToggle(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (onToggle) {
      onToggle(false);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearchValue(value);
    if (setSearchValue) {
      setSearchValue(value);
    }
  };

  return (
    <div
      className={`overflow-y-scroll scrollbar-hide
        ${isHovered ? "max-w-[180px]" : "max-w-[80px]"} 
        rounded-[10px] 
        bg-white 
        h-[calc(100vh-5rem)] 
        p-2 
        transition-all 
        duration-300 
        ease-in-out
      `}
    >
      {/* Search Area */}
      <div className="flex flex-col items-center gap-1">
        <div
          onClick={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="border-b border-[#BDBDBD] w-full flex items-center justify-center"
        >
          {!isHovered ? (
            <button className="text-[30px] text-[#ffffff] bg-[#00AD511A] whitespace-nowrap p-3 rounded-[10px] mb-2 flex justify-center items-center">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9.80541"
                  cy="9.80589"
                  r="7.49047"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.0151 15.4043L17.9518 18.3334"
                  stroke="#828282"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ) : (
            <div className="w-full py-2">
              <SearchField
                searchValue={searchValue}
                searchOnchange={handleSearchChange}
              />
            </div>
          )}
        </div>
      </div>

      {/* Navigation Items */}
      <nav className="flex flex-col items-center gap-4 mt-2">
        {isLoading ? (
          <Loader />
        ) : (
          data?.data?.results.map((el, i) => (
            <NavLink
              key={el?.id}
              to={`?id=${el?.id}`}
              onClick={() => setCurrentIndex(i)}
            >
              {el?.photo ? (
                <img src={el?.photo} alt="" className="mx-auto rounded-full w-10 h-10 object-cover" />
              ) : (
                <InitialsAvatar name={el?.name} />
              )}
            </NavLink>
          ))
        )}
      </nav>
    </div>
  );
};

export default OperatorSidebar;
