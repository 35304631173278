import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { X } from "lucide-react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import useGetAllRolesManager from "../../roleManagement/controllers/getRolesController";

const ROLES = ["Supervisor", "Manager", "Support", "Clack"];

export default function FilterDrawer({ isOpen, onClose, onUpdateFilters }) {
  const [value, setValue] = useState(dayjs(new Date()));
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [roles, setRoles] = useState([]);
  const [dateError, setDateError] = useState("");

  // Reset all filters to initial state
  const handleResetFilters = () => {
    setValue(dayjs(new Date()));
    setStartDate("");
    setEndDate("");
    setRoles([]);
    setDateError("");
  };
  const { data: rolesData, isLoading: isRolesLoading } = useGetAllRolesManager({
    enabled: isOpen, // Only fetch when drawer is open
  });

  // Validate date range
  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (start > end) {
        setDateError("Start date must be before end date");
      } else {
        setDateError("");
      }
    } else {
      setDateError("");
    }
  }, [startDate, endDate]);

  // Toggle role selection
  const toggleRole = (role) => {
    setRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role]
    );
  };

  // Apply filters with validation
  const handleApplyFilters = () => {
    // Check if there's a date error before applying
    if (dateError) {
      return;
    }

    onUpdateFilters({
      startDate,
      endDate,
      selectedMonth: value.$M,
      roles,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[1000]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-end">
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brandGreen focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <X aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-start mt-4 justify-between">
                    <DialogTitle className="text-[20px] font-semibold text-[#4B4B4B]">
                      Staffs Filter
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={handleResetFilters}
                        className="relative rounded-md bg-white text-brandGreen hover:text-brandBackground/90 focus:outline-none focus:ring-2 focus:ring-brandBackground focus:ring-offset-2"
                      >
                        Reset all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="px-4 sm:px-6 mt-6">
                  <h3 className="text-textGrey">Date</h3>
                  <div className="relative grid grid-cols-2 gap-x-2 mt-2 flex-1">
                    <div>
                      <div className="mt-1 relative">
                        <input
                          id="start-date"
                          type="date"
                          aria-describedby="start-date-description"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          name="start-date"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-offWhite placeholder:text-[#98A2B3] focus:ring-1 focus:ring-inset focus:ring-ringColor sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="mt-1 relative">
                        <input
                          id="end-date"
                          type="date"
                          aria-describedby="end-date-description"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          name="end-date"
                          className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-offWhite placeholder:text-[#98A2B3] focus:ring-1 focus:ring-inset focus:ring-ringColor sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  {dateError && (
                    <p className="text-red-500 text-sm mt-1">{dateError}</p>
                  )}
                </div>

                <div className="border border-offWhite rounded-xl mx-6 mt-6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      value={value}
                      onChange={(newValue) => setValue(newValue)}
                    />
                  </LocalizationProvider>
                </div>

                <div className="px-4 sm:px-6 mt-6">
                  <h3 className="text-textGrey">Roles</h3>
                  {isRolesLoading ? (
                    <p className="text-gray-500">Loading roles...</p>
                  ) : (
                    <div className="mt-2 space-x-4 space-y-3">
                      {rolesData?.data?.results?.map((role) => (
                        <button
                          key={role.id}
                          type="button"
                          onClick={() => toggleRole(role.name)}
                          className={`inline-flex group items-center gap-x-1.5 rounded-3xl px-3 py-2 text-xs font-semibold hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBackground/90 ${
                            roles.includes(role.name)
                              ? "bg-green-50"
                              : "bg-gray-100"
                          } ring-inset hover:bg-gray-50`}
                        >
                          {roles.includes(role.name) && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#358619"
                              className="size-6"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          <span className="text-textGrey group-hover:text-white">
                            {role.name}
                          </span>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={handleApplyFilters}
                  disabled={!!dateError}
                  className={`text-brandBackground shadow-lg fixed py-2.5 border-t w-full text-center bottom-0 ${
                    dateError
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-green-50"
                  }`}
                >
                  Done
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
