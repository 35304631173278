import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { X } from "lucide-react";

const states = ["Lagos", "Abuja", "Port Harcourt", "Kano"]; // Add more states as needed

export default function FilterDrawer({ isOpen, onClose, onUpdateFilters }) {
  const [selectedState, setSelectedState] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minSize, setMinSize] = useState(0);
  const [maxSize, setMaxSize] = useState(3200);
  const [sliderValue, setSliderValue] = useState(0);

  // const handleApplyFilters = () => {
  //   onUpdateFilters({
  //     state: selectedState,
  //     startDate,
  //     endDate,
  //     minSize,
  //     maxSize,
  //   });
  //   onClose();
  // };

  const handleReset = () => {
    setSelectedState("");
    setStartDate("");
    setEndDate("");
    setMinSize(0);
    setMaxSize(3200);
    setSliderValue(0);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[1000]">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out">
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-xl font-semibold text-gray-900">
                      OMLs Filter
                    </DialogTitle>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={handleReset}
                        className="text-green-600 hover:text-green-700 font-medium"
                      >
                        Reset all
                      </button>
                      <button
                        onClick={onClose}
                        className="rounded-md text-gray-400 hover:text-gray-500"
                      >
                        <X className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="px-4 sm:px-6 mt-6 space-y-6">
                  {/* State Selection */}
                  <div>
                    <label className="block text-sm font-medium text-gray-500 mb-2">
                      State
                    </label>
                    <select
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      className="w-full rounded-lg border border-gray-200 p-3 text-gray-700 focus:ring-2 focus:ring-green-500"
                    >
                      <option value="">Select state</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Application Date */}
                  <div>
                    <label className="block text-sm font-medium text-gray-500 mb-2">
                      Application Date
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="from"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="w-full rounded-lg border border-gray-200 p-3 pe-10 text-gray-700 focus:ring-2 focus:ring-green-500"
                        />
                        <span className="absolute inset-y-0 end-0 flex items-center pe-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="to"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          className="w-full rounded-lg border border-gray-200 p-3 pe-10 text-gray-700 focus:ring-2 focus:ring-green-500"
                        />
                        <span className="absolute inset-y-0 end-0 flex items-center pe-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Onshore Size */}
                  <div>
                    <label className="block text-sm font-medium text-gray-500 mb-2">
                      Onshore Size
                    </label>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <input
                        type="number"
                        placeholder="Min"
                        value={minSize}
                        onChange={(e) => setMinSize(Number(e.target.value))}
                        className="w-full rounded-lg border border-gray-200 p-3 text-gray-700 focus:ring-2 focus:ring-green-500"
                      />
                      <input
                        type="number"
                        placeholder="Max"
                        value={maxSize}
                        onChange={(e) => setMaxSize(Number(e.target.value))}
                        className="w-full rounded-lg border border-gray-200 p-3 text-gray-700 focus:ring-2 focus:ring-green-500"
                      />
                    </div>
                    <div className="relative pt-4">
                      <input
                        type="range"
                        min="0"
                        max="3200"
                        value={sliderValue}
                        onChange={(e) => setSliderValue(Number(e.target.value))}
                        className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-green-500"
                      />
                      <div className="flex justify-between text-xs text-gray-500 mt-2">
                        <span>0</span>
                        <span>3200</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-auto px-4 sm:px-6 py-4 border-t">
                  {/* <button
                    onClick={handleApplyFilters}
                    className="w-full bg-green-600 text-white py-2.5 rounded-lg hover:bg-green-700 transition-colors"
                  >
                    Apply Filters
                  </button> */}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
