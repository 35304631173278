import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import FormWrapper from "../../../generalComponents/FormWrapper";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordManager } from "../controllers/forgotPassword";
import { emailIcon } from "../../../assets/icons";

// Validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { forgotPassword, isLoading } = ForgotPasswordManager();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    // console.log("Login form values:", values);
    forgotPassword(values);
    navigate("reset-password");
  };

  // toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormWrapper>
      <div className="bg-white p-8 rounded-[10px] border border-[#E0E0E0] w-full max-w-sm">
        <h2 className="text-[25px] flex justify-start font-[600]">
          Forgot Password?
        </h2>
        <p className="text-[#667185] text-[13px] font-[400] mb-[40px]">
          Enter your credentials to access your account
        </p>
        <Formik
          initialValues={{ email: "", password: "", rememberMe: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isLoading }) => (
            <Form>
              {/* Email field */}
              <div className="mb-4 relative">
                <label
                  htmlFor="email"
                  className="block text-[13px] font-[500] text-[#101928]"
                >
                  EMAIL ADDRESS
                </label>
                <div className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center">
                  <img
                    src={emailIcon}
                    alt="Email Icon"
                    className="h-[15PX] w-[18px]"
                  />
                </div>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className="mt-1 block w-full pl-2 text-[11px] p-2 py-3 border rounded-md"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-[#00AD51] mt-[80px] mb-4 text-[14px] font-[500] py-3 text-white  rounded-[100px] hover:bg-green-600"
              >
                Send Password Reset Link
              </button>
              <div className="flex justify-center">
                <p className="text-[#98A2B3] text-[12px]">
                  Having a bit of trouble?{" "}
                  <NavLink to="/login">
                    <span className="text-[13px] font-[500] text-[#00AD51] hover:underline">
                      Contact Support
                    </span>
                  </NavLink>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormWrapper>
  );
};

export default ForgotPassword;
