import React, { useState } from "react";

// general Tab Component
const Tabs = ({ tabs, selectedTab, onTabClick, className }) => {
  return (
    <div className="flex items-center  lg:gap-[5px] bg-[#F6F8FC] rounded-[5px] overflow-x-auto mt-4 p-1">
      {tabs.map((tab, index) => (
        <div
          key={tab.label}
          onClick={() => onTabClick(index)}
          className={`${className} ${
            selectedTab === index
              ? "text-white bg-[#00AD51]"
              : "text-[#828282] hover:text-gray-700 bg-[#FFFFFF]"
          }`}
        >
          {tab.label}
          {selectedTab === index && (
            <div className="absolute bottom-0 left-0 h-[1px] w-[100%] bg-[#00AD51] mx-auto" />
          )}
        </div>
      ))}
    </div>
  );
};

const OilTabsComponent = ({ tabs, className }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const ActiveComponent = tabs[selectedTab].component;

  return (
    <div className="container mx-auto ">
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabClick={setSelectedTab}
        className={className}
      />
      <div className="py-6 overflow-x-auto shrink">
        <ActiveComponent />
      </div>
    </div>
  );
};
export default OilTabsComponent;
