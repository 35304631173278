import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import nnpcLogo from "../assets/images/nnpcLogo.svg"; // Logo
import FormWrapper from "../generalComponents/FormWrapper";
import { useNavigate } from "react-router-dom";
import { NewPasswordManager } from "../modules/auth/controllers/setNewPassword.js";

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");

// Validation schema
const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[0-9]/, "Password must contain at least one number")

    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const SetNewPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { resetPassword } = NewPasswordManager();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    // console.log("Form Submitted:", values);
    await resetPassword({ ...values, token, email: user.email });
    navigate("/otp");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <FormWrapper>
      <div className="bg-white p-8 rounded-[10px]  border border-[#E0E0E0] w-full max-w-sm">
        <h2 className="text-[25px] flex justify-start font-bold  text-center">
          Set New Password
        </h2>
        <p className="text-[12px] text-[#0F0A0A] mb-[20px]">
          Create new password
        </p>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isLoading, values }) => (
            <Form>
              {/* New Password field */}
              <div className="mb-4 relative">
                <label
                  htmlFor="password"
                  className="block text-[13px] font-medium text-[#101928]"
                >
                  New Password
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Create new password"
                    className="mt-1 block w-full px-3 py-2 text-[11px] border rounded-md"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {showPassword ? (
                      <FaEyeSlash
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    ) : (
                      <FaEye
                        onClick={togglePasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Confirm Password field */}
              <div className="mb-2 relative">
                <label
                  htmlFor="confirmPassword"
                  className="block text-[13px] font-medium text-[#101928]"
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm password"
                    className="mt-1 block w-full px-3 py-2 text-[11px] border rounded-md"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {showConfirmPassword ? (
                      <FaEyeSlash
                        onClick={toggleConfirmPasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    ) : (
                      <FaEye
                        onClick={toggleConfirmPasswordVisibility}
                        className="cursor-pointer text-[#667185]"
                      />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Password Requirements */}
              <div className="mb-[36px]">
                <p className="text-[12px] font-[500] text-[#00AD51] mb-1">
                  Password must contain:
                </p>
                <ul className="text-[10px] text-[#9B9B9B] list-disc ml-3 mb-4">
                  <li
                    className={
                      values.password.length >= 8 ? "text-[#00AD51]" : ""
                    }
                  >
                    Should contain 8 character
                  </li>
                  <li
                    className={
                      /[@$!%*?&#]/.test(values.password) ? "text-[#00AD51]" : ""
                    }
                  >
                    1 special character
                  </li>
                  <li
                    className={
                      /[A-Z]/.test(values.password) ? "text-[#00AD51]" : ""
                    }
                  >
                    Atleast 1 capital letter
                  </li>
                  <li
                    className={
                      /[0-9]/.test(values.password) ? "text-[#00AD51]" : ""
                    }
                  >
                    At least 1 number
                  </li>
                </ul>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full bg-[#00AD51] text-[14px] text-white py-3 rounded-[100px] hover:bg-green-700 transition-colors"
              >
                Update Password
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </FormWrapper>
  );
};

export default SetNewPasswordForm;
